// @font-face {
//     font-family: "Proxima Nova Cn Rg";
//     src: url("../fonts/ProximaNovaCond-BoldIt.ttf") format("truetype");
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

@font-face {
    font-family: "Proxima Nova Rg";
    src: url("../fonts/ProximaNova-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Rg";
    src: url("../fonts/ProximaNova-BoldIt.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

// @font-face {
//     font-family: "Proxima Nova Cn Rg";
//     src: url("../fonts/ProximaNovaCond-Regular.ttf") format("truetype");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Lt";
//     src: url("../fonts/ProximaNovaCond-Light.ttf") format("truetype");
//     font-weight: 300;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Rg";
//     src: url("../fonts/ProximaNovaCond-RegularIt.ttf") format("truetype");
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Rg";
//     src: url("../fonts/ProximaNovaCond-Bold.ttf") format("truetype");
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Lt";
//     src: url("../fonts/ProximaNovaCond-LightIt.ttf") format("truetype");
//     font-weight: 300;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Lt";
//     src: url("../fonts/ProximaNovaCond-Semibold.ttf") format("truetype");
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Th";
//     src: url("../fonts/ProximaNovaCond-Thin.ttf") format("truetype");
//     font-weight: 100;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: "Proxima Nova ExCn Th";
    src: url("../fonts/ProximaNovaExCn-ExtrabldIt.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

// @font-face {
//     font-family: "Proxima Nova ExCn Rg";
//     src: url("../fonts/ProximaNovaExCn-BoldIt.ttf") format("truetype");
//     font-weight: bold;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Th";
//     src: url("../fonts/ProximaNovaCond-ThinIt.ttf") format("truetype");
//     font-weight: 100;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova ExCn Rg";
//     src: url("../fonts/ProximaNovaExCn-Bold.ttf") format("truetype");
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova Cn Lt";
//     src: url("../fonts/ProximaNovaCond-SemiboldIt.ttf") format("truetype");
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova ExCn Th";
//     src: url("../fonts/ProximaNovaExCn-Extrabld.ttf") format("truetype");
//     font-weight: bold;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: "Proxima Nova Th";
    src: url("../fonts/ProximaNova-Extrabld.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Th";
    src: url("../fonts/ProximaNova-ExtrabldIt.ttf") format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

// @font-face {
//     font-family: "Proxima Nova ExCn Lt";
//     src: url("../fonts/ProximaNovaExCn-SemiboldIt.ttf") format("truetype");
//     font-weight: 600;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova ExCn Lt";
//     src: url("../fonts/ProximaNovaExCn-Semibold.ttf") format("truetype");
//     font-weight: 600;
//     font-style: normal;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova ExCn Rg";
//     src: url("../fonts/ProximaNovaExCn-RegularIt.ttf") format("truetype");
//     font-weight: normal;
//     font-style: italic;
//     font-display: swap;
// }

// @font-face {
//     font-family: "Proxima Nova ExCn Rg";
//     src: url("../fonts/ProximaNovaExCn-Regular.ttf") format("truetype");
//     font-weight: normal;
//     font-style: normal;
//     font-display: swap;
// }

@font-face {
    font-family: "Proxima Nova Rg";
    src: url("../fonts/ProximaNova-RegularIt.ttf") format("truetype");
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Rg";
    src: url("../fonts/ProximaNova-Regular.ttf") format("truetype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Lt";
    src: url("../fonts/ProximaNova-SemiboldIt.ttf") format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Proxima Nova Lt";
    src: url("../fonts/ProximaNova-Semibold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

body {
    font-family: "Proxima Nova Rg", sans-serif;
    font-weight: normal;
    font-style: normal;
    font-size: 14px;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: "Proxima Nova Rg", sans-serif;
    font-weight: bold;
    color: $greyDarkColor;
}
.dataTableWrapper{
    font-family: "Proxima Nova Rg", sans-serif;
    .rdt_TableHead{
        font-weight: bold;
    }
}
.pageTitleText {
    font-size: 20px;
    margin: 0;
    @include flexbox(row, flex-start, center);
    gap: 0px;
    img {
        width: 22px;
        height: 22px;
    }
    small{
        font-size: 14px;
        font-weight: normal;
        padding-left: 10px;
        margin-left: 10px;
        border-left: 1px solid $greyDarkColor;
    }
    @include respond-to(smedium) {
        font-size: 16px;
        img {
            display: none;
        }
        small{
            display: none; 
        }
    }
}
