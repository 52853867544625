@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/ProximaNova-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/ProximaNova-BoldIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova ExCn Th";
  src: url("../fonts/ProximaNovaExCn-ExtrabldIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Th";
  src: url("../fonts/ProximaNova-Extrabld.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Th";
  src: url("../fonts/ProximaNova-ExtrabldIt.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/ProximaNova-RegularIt.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Rg";
  src: url("../fonts/ProximaNova-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../fonts/ProximaNova-SemiboldIt.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}
@font-face {
  font-family: "Proxima Nova Lt";
  src: url("../fonts/ProximaNova-Semibold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
body {
  font-family: "Proxima Nova Rg", sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 14px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Proxima Nova Rg", sans-serif;
  font-weight: bold;
  color: #333;
}

.dataTableWrapper {
  font-family: "Proxima Nova Rg", sans-serif;
}
.dataTableWrapper .rdt_TableHead {
  font-weight: bold;
}

.pageTitleText {
  font-size: 20px;
  margin: 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0px;
}
.pageTitleText img {
  width: 22px;
  height: 22px;
}
.pageTitleText small {
  font-size: 14px;
  font-weight: normal;
  padding-left: 10px;
  margin-left: 10px;
  border-left: 1px solid #333;
}
@media (max-width: 575px) {
  .pageTitleText {
    font-size: 16px;
  }
  .pageTitleText img {
    display: none;
  }
  .pageTitleText small {
    display: none;
  }
}

select,
input,
textarea,
button {
  font-size: 12px;
  color: #666;
}
select:focus, select:focus-visible,
input:focus,
input:focus-visible,
textarea:focus,
textarea:focus-visible,
button:focus,
button:focus-visible {
  outline: 1px solid rgba(102, 102, 102, 0.8);
}

input[type=checkbox]:focus, input[type=checkbox]:focus-visible,
input[type=radio]:focus,
input[type=radio]:focus-visible {
  outline: none;
}

.btn .btn-sm {
  padding-left: 15px;
  padding-right: 15px;
  font-size: 13px !important;
}
.btn.btn-primary.btn-sm {
  background: #278dec;
  border-color: #278dec;
}
.btn.btn-primary.btn-sm:hover {
  background: #006bcf;
  border-color: #006bcf;
}
.btn.btn-danger.btn-sm {
  background: #da0b0b;
  border-color: #da0b0b;
}
.btn.btn-danger.btn-sm:hover {
  background: #d24949;
}
.btn.btn-outline-primary.btn-sm {
  border-color: #278dec;
  color: #278dec;
}
.btn.btn-outline-primary.btn-sm:hover {
  background: #006bcf;
  border-color: #006bcf;
  color: #ffffff;
}

.dropdown .btn.btn-sm.btn-outline-primary {
  background: transparent;
}
.dropdown .btn.btn-sm.btn-outline-primary:hover {
  background: #006bcf;
  border-color: #006bcf;
  color: #ffffff;
}

.searchContainer .searchTextBox input,
.searchContainer .searchTextBox select {
  width: 100%;
  box-sizing: border-box;
  padding: 6px 10px 6px 30px;
  border: 1px solid rgba(102, 102, 102, 0.3);
  border-radius: 5px;
  font-size: 12px;
  background: url("../images/icons/searchIcon.svg") no-repeat 5px center #ffffff;
  background-size: 18px;
  min-width: 300px;
}
.searchContainer .searchTextBox select {
  background-image: none;
  padding: 6px 10px;
}
@media (max-width: 768px) {
  .searchContainer .searchTextBox input,
  .searchContainer .searchTextBox select {
    min-width: 100%;
  }
}

.rosterTabsContent .searchContainer .searchTextBox input {
  min-width: 100%;
}

.currentStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin: 0;
}
.currentStatus span {
  display: block;
  background: #03b93f;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}

.headerSelect {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgba(102, 102, 102, 0.3);
}

/* The container */
.radioButtonContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
}
.radioButtonContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}
.radioButtonContainer .checkmark {
  position: absolute;
  top: 2px;
  left: 0;
  height: 16px;
  width: 16px;
  background-color: #ffffff;
  border-radius: 50%;
  border: 1px solid #278dec;
}
.radioButtonContainer:hover input ~ .checkmark {
  background-color: #ccc;
}
.radioButtonContainer input:checked ~ .checkmark {
  background-color: #2196f3;
}
.radioButtonContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.radioButtonContainer input:checked ~ .checkmark:after {
  display: block;
}
.radioButtonContainer .checkmark:after {
  top: 3px;
  left: 3px;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: white;
}
.radioButtonContainer .statusCircle {
  margin-left: 10px;
}

.form-group {
  position: relative;
  margin-bottom: 20px;
}
.form-group .text-danger {
  font-size: 12px;
  margin: 5px 0;
}
.form-group label.floatingLabel {
  display: block;
  color: #777d80;
  position: absolute;
  top: -9px;
  left: 5px;
  pointer-events: none;
  transition: 0.2s ease-out;
  background: #ffffff;
  padding: 0 5px;
  font-size: 12px;
}
.form-group input,
.form-group textarea,
.form-group select {
  width: 100%;
  padding: 10px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #777d80;
  font-size: 13px;
  backdrop-filter: saturate(0.5);
}
.form-group input:active, .form-group input:focus,
.form-group textarea:active,
.form-group textarea:focus,
.form-group select:active,
.form-group select:focus {
  border-color: #007bff;
  outline: none;
  border: 1px solid #ccc;
}
.form-group input::placeholder,
.form-group textarea::placeholder,
.form-group select::placeholder {
  color: rgba(102, 102, 102, 0.3);
}
.form-group input:disabled,
.form-group textarea:disabled,
.form-group select:disabled {
  background: hsl(0, 0%, 95%) !important;
}
.form-group select {
  padding: 12px 10px !important;
}
.form-group select.form-select {
  padding: 10px !important;
}
.form-group input[type=date]:valid + label, .form-group input[type=date]:focus + label {
  width: auto !important;
}
.form-group input:valid + label, .form-group input:focus + label, .form-group input:read-only + label {
  top: -9px;
  left: 5px;
  font-size: 12px;
  padding: 0 5px;
}
.form-group textarea:focus + label, .form-group textarea:valid + label {
  top: -9px;
  left: 5px;
  font-size: 12px;
  padding: 0 5px;
}
.form-group select:valid + label, .form-group select:focus + label {
  top: -9px;
  left: 5px !important;
  font-size: 12px;
  padding: 0 5px;
}
.form-group input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
  -webkit-text-fill-color: #3c4549; /* Set the text color to your desired color */
}
.form-group input[type=checkbox] {
  width: auto;
  position: relative;
  top: 2px;
}
.css-1xc3v61-indicatorContainer {
  color: #333 !important;
}

.form-group input:disabled + label,
.form-group textarea:disabled + label,
.form-group select:disabled + label,
.disabled label,
label.disabled,
.customSelectBox.disabled label.floatingLabel,
.disabledLabel {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,777d80+50,777d80+100 */
  background: linear-gradient(to bottom, #ffffff 0%, #ffffff 50%, #f2f2f2 50%, #f2f2f2 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}

.formBlockContent {
  padding: 20px 0;
}
.formBlockContent .form-group {
  margin-bottom: 30px;
}

.multipleFieldsBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.multipleFieldsBox .div70 {
  width: 70%;
}
.multipleFieldsBox .div30 {
  width: 30%;
}

.formActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: 20px;
}

.editableTextArea {
  width: 100%;
}
.editableTextArea:read-only {
  opacity: 0.5;
  background: rgba(102, 102, 102, 0.3);
}

.trueFalseCont {
  display: flex;
  flex-direction: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}

.phoneField {
  display: flex;
  flex-direction: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
}
.phoneField .form-group.selectBoxGroup {
  width: 35%;
}
.phoneField .form-group {
  width: 65%;
}

input[type=file] {
  width: 100%;
  position: relative;
  padding: 7.5px 10px;
}

input::file-selector-button {
  position: relative;
  right: -25px;
  border-radius: 0;
  background: #278dec;
  float: right;
  color: #fff !important;
}

input[type=file]:after {
  content: "Choose File";
  display: block;
  width: 130px;
  height: 100%;
  background: #278dec;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  padding: 12px 0 0 0;
  color: #fff;
}

input[type=file].uploadProfileImage:after {
  content: "Upload Profile Image";
}

input[type=file].uploadDocument:after {
  content: "Upload Document";
}

input[type=file].replaceDocument:after {
  content: "Replace Document";
}

input[type=file].fileUploading:after {
  content: "Please wait....";
}

.select-box {
  border-radius: 20px;
}
.select-box select {
  background-color: transparent;
  border: 0;
  font-size: 12px;
}
.select-box.claimedSelect {
  border-radius: 10px;
}
.select-box.claimedSelect .form-select:disabled {
  border-radius: 10px;
}
.select-box.claimedSelect.background-success, .select-box.claimedSelect.background-danger {
  opacity: 0.5;
}
.select-box.claimedSelect.background-success .form-select:disabled, .select-box.claimedSelect.background-danger .form-select:disabled {
  background-color: inherit;
}

.status-select-box select {
  width: 120px !important;
  padding: 7px 0 7px 29px;
}

.validationError span.error {
  color: #da0b0b !important;
}
.validationError .text-danger {
  color: #da0b0b !important;
  font-size: 12px;
}

.customFileUpload label {
  width: 100%;
  max-width: 100%;
  background: rgba(193, 195, 200, 0.1);
  border: 1px solid rgba(193, 195, 200, 0.4);
}

.errorClass {
  margin-bottom: 20px;
  font-size: 12px;
}
.errorClass .form-group {
  margin-bottom: 0;
}

.assignBox {
  border-radius: 5px;
  min-width: 100px;
}
.assignBox .css-t3ipsp-control {
  box-shadow: none;
}
.assignBox .css-1nmdiq5-menu {
  padding: 0;
}
.assignBox .css-1xc3v61-indicatorContainer {
  padding-top: 0;
  padding-bottom: 0;
}
.assignBox .css-13cymwt-control {
  background: transparent;
  border: 0;
  min-height: unset !important;
}
.assignBox .css-13cymwt-control .css-1fdsijx-ValueContainer {
  padding: 0 10px;
}
.assignBox .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
  color: #ffffff;
}
.assignBox .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1dimb5e-singleValue {
  color: #ffffff;
}
.assignBox .css-13cymwt-control svg {
  color: #ffffff;
  filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
}
.assignBox.selectValueClass {
  background: #278dec;
}
.assignBox.selectedClass {
  background: #03b93f;
}

.css-1nmdiq5-menu {
  min-width: 120px !important;
  font-size: 12px;
  right: 0;
}

.modal-body .css-1nmdiq5-menu {
  max-height: 180px;
  overflow-y: auto;
}
.modal-body .css-1nmdiq5-menu * {
  height: 100%;
  overflow: unset;
}

.form-check-input[type=checkbox],
.form-check-input[type=radio] {
  width: 17px;
  height: 17px;
  border: 1px solid #278dec;
}

.form-check label {
  margin-left: 5px;
  font-size: 14px;
  color: #666;
}

label.general {
  font-size: 14px;
  color: #666;
}

.jodit-wysiwyg {
  min-height: 300px !important;
  height: 300px !important;
}

.jodit-placeholder {
  z-index: 0;
}

.emojiRadioButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  /* ANIMATION */
}
.emojiRadioButtons .col img {
  height: 20px;
  width: 20px;
  cursor: pointer;
  transition: transform 1s;
  object-fit: cover;
}
.emojiRadioButtons .col label {
  overflow: hidden;
  position: relative;
}
.emojiRadioButtons .imgbgchk:checked + label > img {
  background: #da0b0b;
  border-radius: 50%;
}
.emojiRadioButtons .imgbgchk:checked + label > img.Inadequate, .emojiRadioButtons .imgbgchk:checked + label > img.Angry, .emojiRadioButtons .imgbgchk:checked + label > img.Worried {
  background: #e81224;
}
.emojiRadioButtons .imgbgchk:checked + label > img.RequiresImprovement, .emojiRadioButtons .imgbgchk:checked + label > img.Excited, .emojiRadioButtons .imgbgchk:checked + label > img.Scared {
  background: #f7630c;
}
.emojiRadioButtons .imgbgchk:checked + label > img.Good, .emojiRadioButtons .imgbgchk:checked + label > img.Happy, .emojiRadioButtons .imgbgchk:checked + label > img.Proud, .emojiRadioButtons .imgbgchk:checked + label > img.Hopeful, .emojiRadioButtons .imgbgchk:checked + label > img.Confident {
  background: #fff100;
}
.emojiRadioButtons .imgbgchk:checked + label > img.Outstanding, .emojiRadioButtons .imgbgchk:checked + label > img.Nervous {
  background: #16c60c;
}
.emojiRadioButtons .imgbgchk:checked + label > img.Bored, .emojiRadioButtons .imgbgchk:checked + label > img.Sad, .emojiRadioButtons .imgbgchk:checked + label > img.Relaxed, .emojiRadioButtons .imgbgchk:checked + label > img.Calm, .emojiRadioButtons .imgbgchk:checked + label > img.Tired {
  background: #55bbd1;
}
.emojiRadioButtons.moodRatingButtons {
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0;
}
.emojiRadioButtons.moodRatingButtons .col {
  width: 14.28%;
  flex: none;
  margin: 20px 0;
  text-align: center;
}
.emojiRadioButtons.moodRatingButtons .col label {
  text-align: center;
}
.emojiRadioButtons.moodRatingButtons .col label img {
  width: 50px;
  height: 50px;
}
.emojiRadioButtons.moodRatingButtons .col label span {
  display: block;
  font-size: 12px;
  font-weight: bold;
  margin-top: 10px;
}

.jodit-add-new-line.jodit-add-new-line_after {
  display: none;
}

.archiveTheUserButton button.btn {
  font-size: 12px;
}

.weekSelector {
  max-width: 150px;
}

.select__control--is-focused {
  box-shadow: none !important;
  padding-top: 2px;
  padding-bottom: 2px;
  outline: 0 !important;
}

.permissionDenied a.btn-outline-danger {
  color: #da0b0b !important;
  border-color: #da0b0b;
}
.permissionDenied a.btn-outline-danger:hover, .permissionDenied a.btn-outline-danger:focus {
  background: #da0b0b !important;
  color: #ffffff !important;
}

.form-group .datePicker {
  background: url("../images/icons/caldendarDatePicker.svg") no-repeat calc(100% - 10px) center transparent;
  background-size: 12px;
  cursor: pointer;
}
.form-group .datePicker:disabled {
  background: url("../images/icons/caldendarDatePicker.svg") no-repeat calc(100% - 10px) center hsl(0, 0%, 95%) !important;
  background-size: 12px !important;
  cursor: not-allowed;
  pointer-events: all !important;
  width: 130px;
}

.react-datepicker-popper {
  z-index: 9;
  line-height: 0;
}

.addRowSpan img.addRow {
  margin: 0;
}

.questionBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  position: relative;
  margin-bottom: 20px;
}
.questionBox .form-group {
  width: 100%;
}
.questionBox .form-group input {
  padding-right: 40px;
}
.questionBox .removeQuestionButton {
  position: absolute;
  right: 10px;
}

.choiceBox {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  position: relative;
  margin-bottom: 20px;
}
.choiceBox .isRightAnswer {
  width: 20px;
  height: 20px;
  display: block;
}
.choiceBox .form-group {
  width: 100%;
}
.choiceBox .form-group input {
  padding: 5px;
}
.choiceBox .removeButton {
  position: absolute;
  top: -10px;
  right: 0;
}
.choiceBox .removeButton img {
  width: 15px;
  height: 15px;
}

.isPrnAlert {
  font-size: 12px;
  margin: 5px auto 10px 0;
  padding: 0 25px;
  border-radius: 0;
}

.form-group .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-popper {
  transform: translate(-29%, 16%) !important; /* Moves the calendar to the left */
}

.FTCAuthenticationWrapper a {
  color: #3c4549;
  text-decoration: none;
}
.FTCAuthenticationWrapper h3 {
  font-size: 22px;
  margin-top: 20px;
}
.FTCAuthenticationWrapper .authenticationContainer {
  display: flex;
  flex-direction: row;
  height: 100vh;
}
.FTCAuthenticationWrapper .column {
  flex: 1;
  padding: 20px;
}
.FTCAuthenticationWrapper .left-column {
  background: url("../images/authentication/authenticationBg.jpg") no-repeat center left;
  background-size: cover;
  padding-top: 50px;
}
.FTCAuthenticationWrapper .left-column h2 {
  font-size: 30px;
  margin-bottom: 10px;
}
.FTCAuthenticationWrapper .left-column p {
  font-size: 18;
}
.FTCAuthenticationWrapper .right-column {
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.FTCAuthenticationWrapper .right-column form {
  margin-top: 10px;
}
.FTCAuthenticationWrapper .right-column .login-form {
  max-width: 400px;
  padding: 40px;
  border-radius: 5px;
  width: 100%;
}
.FTCAuthenticationWrapper .text-right {
  text-align: right;
}
.FTCAuthenticationWrapper .text-center {
  text-align: center;
}
.FTCAuthenticationWrapper .logo {
  margin-bottom: 20px;
  max-width: 100%;
}
.FTCAuthenticationWrapper .form-group {
  position: relative;
  margin-bottom: 20px;
}
.FTCAuthenticationWrapper .form-group input {
  width: 100%;
  padding: 12px 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  color: #3c4549;
}
.FTCAuthenticationWrapper .form-group input:active, .FTCAuthenticationWrapper .form-group input:focus {
  border-color: #007bff;
  outline: none;
  border: 1px solid #ccc;
}
.FTCAuthenticationWrapper .form-group input:valid + label, .FTCAuthenticationWrapper .form-group input:focus + label {
  top: -8px;
  left: 5px;
  font-size: 12px;
  padding: 0 5px;
}
.FTCAuthenticationWrapper .form-group input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
  -webkit-text-fill-color: #3c4549; /* Set the text color to your desired color */
}
.FTCAuthenticationWrapper .form-group input[type=checkbox] {
  width: auto;
  position: relative;
  top: 2px;
}
.FTCAuthenticationWrapper .form-group .button {
  width: 100%;
  padding: 12px 10px;
  background-color: #278dec;
  color: #fff !important;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 16px;
  display: block;
}
.FTCAuthenticationWrapper .form-group a.button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.FTCAuthenticationWrapper .form-group a.button img {
  margin-right: 5px;
}
.FTCAuthenticationWrapper .form-group label.floatingLabel {
  display: block;
  color: #777d80;
  position: absolute;
  top: 12px;
  left: 5px;
  pointer-events: none;
  transition: 0.2s ease-out;
  background: #ffffff;
  padding: 0 10px;
}
.FTCAuthenticationWrapper .remember-authentication {
  display: flex;
  flex-direction: row;
  margin: 17px 0 !important;
}
.FTCAuthenticationWrapper .remember-authentication div {
  width: 50%;
}
.FTCAuthenticationWrapper .remember-authentication label {
  position: relative;
  top: 0;
  left: 0;
}
.FTCAuthenticationWrapper .successIcon img {
  width: 120px;
  margin: 20px auto;
  display: block;
}
.FTCAuthenticationWrapper .content {
  color: #828282;
}
.FTCAuthenticationWrapper .password-toggle {
  position: relative;
  display: inline-block;
  width: 100%;
}
.FTCAuthenticationWrapper .password-toggle-icon {
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  cursor: pointer;
}
@media (max-width: 993px) {
  .FTCAuthenticationWrapper .authenticationContainer {
    flex-direction: column;
  }
  .FTCAuthenticationWrapper .column {
    padding: 10px;
  }
  .FTCAuthenticationWrapper .left-column {
    display: none;
  }
  .FTCAuthenticationWrapper .right-column {
    justify-content: center;
  }
  .FTCAuthenticationWrapper .login-form {
    border: 1px solid #ccc;
  }
}

.otpContainer .form-group {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.otpContainer .form-group input {
  text-align: center;
}

.sideNavWrapper .logoBox {
  background: #ffffff;
  border-right: 1px solid #278dec;
  min-height: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sideNavWrapper .logoBox img {
  display: block;
  margin: 0 auto;
  max-height: 55px;
}
.sideNavWrapper ul {
  padding: 20px;
  background: #278dec;
  color: #ffffff;
}
.sideNavWrapper ul * {
  font-size: 14px;
}

.MuiDrawer-paper {
  background: #278dec !important;
  border: 0;
}

.myWrappers .MuiPaper-root {
  background: #278dec;
  border: 0;
}
.myWrappers .sideNavUl {
  background: transparent;
  color: #ffffff;
}
.myWrappers .sideNavUl .MuiListItemButton-root {
  border-radius: 5px;
  margin: 15px 0;
  padding: 5px 20px;
}
.myWrappers .sideNavUl .MuiListItemButton-root:hover {
  background: #63b4ff;
}
.myWrappers .sideNavUl .Mui-selected {
  background: #006bcf;
}
.myWrappers .sideNavUl .Mui-selected:hover {
  background: #006bcf;
  color: #ffffff;
}
.myWrappers .sideNavUl span {
  font-size: 14px;
}

.table > :not(caption) > * > * {
  color: #333;
}

.dataTableWrapper .rdt_TableHeader {
  width: 40%;
  float: right;
  min-height: auto;
  padding: 5px 10px 20px;
}
.dataTableWrapper.exportDataTable .rdt_TableHeader {
  overflow: unset;
}
.dataTableWrapper.exportDataTable .rdt_TableHeader .sc-eDPEul {
  display: none !important;
}
.dataTableWrapper.exportDataTable .rdt_TableHeader .hGcwUo {
  display: none;
}
.dataTableWrapper header {
  float: left;
  width: 50%;
  padding: 10px 10px 20px;
  min-height: auto;
}
.dataTableWrapper .minWidthTable {
  width: 100%;
  overflow-x: auto;
}
.dataTableWrapper .minWidthTable div[role=table] {
  min-width: 1000px;
}
.dataTableWrapper .maxWidthTable {
  min-height: 100px;
}
.dataTableWrapper .maxWidthTable .rdt_TableHeadRow {
  min-height: auto;
}
.dataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol {
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-bottom: 0;
  border-left: 0;
  font-size: 14px;
  height: auto;
  padding: 15px 10px;
}
.dataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  border-left: 1px solid rgba(51, 51, 51, 0.1) !important;
}
.dataTableWrapper .maxWidthTable div[role=rowgroup] {
  cursor: pointer;
}
.dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] {
  border: 0;
}
.dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row]:nth-child(even) {
  background: #f6f6f6;
}
.dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] {
  border: 1px solid rgba(51, 51, 51, 0.1);
  border-bottom: 0;
  border-left: 0;
  padding: 0;
  padding-left: 10px;
  padding-right: 10px;
}
.dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] .rdt_TableCell:nth-child(1) {
  border-left: 1px solid rgba(51, 51, 51, 0.1) !important;
}
@media (max-width: 768px) {
  .dataTableWrapper header,
  .dataTableWrapper .rdt_TableHeader {
    width: 100%;
    float: none;
    display: block;
  }
  .dataTableWrapper header {
    padding: 10px 0;
  }
  .dataTableWrapper header .searchContainer .searchTextBox input {
    min-width: 100%;
  }
  .dataTableWrapper .rdt_TableHeader {
    padding: 10px 0 0;
  }
  .dataTableWrapper .rdt_TableHeader div {
    justify-content: flex-start;
  }
  .dataTableWrapper .rdt_TableHeader div button {
    margin: 0 5px 0 0;
  }
}

.limitedCarePlansNotes .minWidthTable div[role=table] {
  min-width: 800px;
}

.sicknessScore .dataTableWrapper header {
  width: 100%;
  float: none;
  padding: 0;
  border-radius: 5px 5px 0 0;
}
.sicknessScore .dataTableWrapper header .searchContainer {
  padding: 10px;
}
.sicknessScore .dataTableWrapper header .parentTableHeader {
  border-top: 10px solid #f6f6f6;
  width: 100%;
  padding: 0 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.sicknessScore .dataTableWrapper header .parentTableHeader .thresholdScore {
  border-left: 1px solid rgba(51, 51, 51, 0.1);
  padding: 10px;
}
.sicknessScore .dataTableWrapper header .parentTableHeader .thresholdScore p {
  text-align: center;
  margin: 0;
}
.sicknessScore .dataTableWrapper header .parentTableHeader .thresholdScore p span {
  display: block;
  color: #006bcf;
}
.sicknessScore .dataTableWrapper .maxWidthTable {
  padding: 0 10px 10px;
}
.sicknessScore .dataTableWrapper .maxWidthTable div[role=rowgroup] {
  cursor: unset;
}
.sicknessScore .dataTableWrapper .maxWidthTable div[role=rowgroup] .rdt_TableRow .rdt_TableCell {
  border-top: 1px solid rgba(51, 51, 51, 0.1);
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
@media (max-width: 575px) {
  .sicknessScore .dataTableWrapper header .parentTableHeader {
    display: block;
    padding: 10px;
  }
  .sicknessScore .dataTableWrapper header .parentTableHeader h3 {
    padding: 10px 10px 0;
  }
  .sicknessScore .dataTableWrapper header .parentTableHeader .thresholdScore {
    border: 0;
  }
  .sicknessScore .dataTableWrapper header .parentTableHeader .thresholdScore p {
    text-align: left;
  }
}

.parentTableHeader h3 {
  font-size: 18px;
  margin: 0;
}

.documentdDetail .maxWidthTable {
  min-height: 100px;
}
.documentdDetail .maxWidthTable .rdt_TableHeadRow {
  min-height: auto;
}
.documentdDetail .maxWidthTable .rdt_TableHeadRow .rdt_TableCol {
  border: 0 !important;
}
.documentdDetail .maxWidthTable .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  border-left: 0 !important;
}

.rowClickUnset .dataTableWrapper .maxWidthTable div[role=rowgroup] {
  cursor: unset;
}

.columnHeading small {
  font-size: 11px;
}

.candidatesBlocks .mainContent {
  padding: 15px !important;
}
.candidatesBlocks .mainContent .rdt_TableHead {
  display: none;
}
.candidatesBlocks .mainContent h4 {
  font-size: 16px;
}
.candidatesBlocks .mainContent .searchContainer {
  padding: 5px 0 15px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 0;
}
.candidatesBlocks .mainContent .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] {
  cursor: default;
  padding: 10px 0 0;
}
.candidatesBlocks .mainContent .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row]:nth-child(even) {
  background: inherit;
}
.candidatesBlocks .mainContent .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] .rdt_TableCell {
  border: 0;
  padding: 0;
}
.candidatesBlocks .mainContent .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] .rdt_TableCell:nth-child(1) {
  border-left: 0 !important;
}
.candidatesBlocks .mainContent .usersNameInfo img {
  width: 35px;
  height: 35px;
}
.candidatesBlocks .mainContent .usersNameInfo .userInfo .nameBlock {
  font-weight: bold;
  font-size: 15px;
  margin-bottom: 5px;
}
.candidatesBlocks .mainContent .usersNameInfo .userInfo .infoBlock {
  font-weight: normal;
}
.candidatesBlocks .mainContent .iconList {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  min-width: 130px;
}
.candidatesBlocks .mainContent .iconList a img {
  width: 30px;
  height: 30px;
}
@media (max-width: 768px) {
  .candidatesBlocks {
    margin-bottom: 15px;
  }
}

.tableTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.tableTitle h3 {
  font-size: 18px;
  margin-top: 10px;
}

.mainContent.messagingSystem .rdt_TableHead {
  display: none;
}

.staffOverview .usersNameInfo .userInfo .nameBlock {
  font-weight: bold;
  font-size: 14px;
}
.staffOverview .usersNameInfo .userInfo .infoBlock {
  font-weight: normal;
}
.staffOverview .messagingSystem h4 {
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.staffOverview .messagingSystem .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] {
  cursor: default;
  padding: 10px 0 0;
}
.staffOverview .messagingSystem .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row]:nth-child(even) {
  background: inherit;
}
.staffOverview .messagingSystem .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] .rdt_TableCell {
  border: 0;
  padding: 0;
}
.staffOverview .messagingSystem .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] .rdt_TableCell:nth-child(1) {
  border-left: 0 !important;
}
.staffOverview .messagingSystem .usersNameInfo {
  align-items: flex-start;
}
.staffOverview .messagingSystem .usersNameInfo .userImage {
  flex: 0 0 12%;
  min-width: 34px;
}
.staffOverview .messagingSystem .usersNameInfo .userImage img {
  width: 35px;
  height: 35px;
}
.staffOverview .messagingSystem .usersNameInfo .userInfo {
  flex: 0 0 88%;
  padding-left: 10px;
}
.staffOverview .messagingSystem .usersNameInfo .userInfo .infoBlock {
  word-wrap: break-word;
  font-size: 13px;
}
.staffOverview .Roster ::-webkit-scrollbar {
  width: 3px;
}
.staffOverview .Roster ::-webkit-scrollbar:horizontal {
  height: 3px;
}
.staffOverview .Roster .contentcell {
  max-width: 120px;
  margin-right: 5px;
}
.staffOverview .Roster .shiftCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
}
.staffOverview .Roster .shiftCell.markLeave {
  cursor: pointer;
}
.staffOverview .Roster .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol * {
  margin: 0 auto;
}
.staffOverview .Roster div[role=rowgroup] div[role=row] div[role=cell] {
  padding: 5px 5px;
}
.staffOverview .Roster div[role=rowgroup] div[role=row] div[role=cell] .shiftTimingDetail {
  height: 85px;
  overflow-y: auto;
}
.staffOverview .Roster div[role=rowgroup] div[role=row] div[role=cell] > div {
  width: 100%;
}
.eventAppointments .fc .fc-scroller-harness {
  overflow: visible;
}
.eventAppointments .fc .fc-scroller-harness .fc-scroller-liquid-absolute {
  overflow: visible !important;
}

.riskAssessments .dataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol:nth-child(4), .riskAssessments .dataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol:nth-child(6), .riskAssessments .dataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol:nth-child(7) {
  padding: 0;
}
.riskAssessments .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(4), .riskAssessments .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(6), .riskAssessments .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(7) {
  padding: 0;
}
.riskAssessments .dataTableWrapper .multipleColumnRowsTable {
  width: 100%;
}
.riskAssessments .dataTableWrapper .multipleColumnRowsTable tr td {
  width: 33.3%;
  text-align: center;
  padding: 3px;
}
.riskAssessments .dataTableWrapper .multipleColumnRowsTable tr:nth-child(1) {
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
}
.riskAssessments .dataTableWrapper .multipleColumnRowsTable tr:nth-child(2) td {
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
.riskAssessments .dataTableWrapper .multipleColumnRowsTable tr:nth-child(2) td:last-child {
  border: 0;
}
.riskAssessments .dataTableWrapper .cellColumnRowsTable {
  width: 100%;
  height: 100%;
}
.riskAssessments .dataTableWrapper .cellColumnRowsTable tr td {
  width: 33.3%;
  text-align: center;
  padding: 3px;
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
.riskAssessments .dataTableWrapper .cellColumnRowsTable tr td:last-child {
  border: 0;
}
.riskAssessments .dataTableWrapper .cellColumnRowsTable tr td.low {
  border-left: 4px solid #03b93f;
}
.riskAssessments .dataTableWrapper .cellColumnRowsTable tr td.medium {
  border-left: 4px solid #faa308;
}
.riskAssessments .dataTableWrapper .cellColumnRowsTable tr td.high {
  border-left: 4px solid #da0b0b;
}

.questions table {
  min-width: 500px;
}
.questions table tr th:nth-child(1),
.questions table tr td:nth-child(1) {
  width: 90%;
}
.questions table tr th:nth-child(2),
.questions table tr td:nth-child(2) {
  width: 10%;
  text-align: center;
}

.forOfficeUseOnly table tr td,
.forOfficeUseOnly table tr th {
  width: 33.3%;
  text-align: left;
}
.forOfficeUseOnly table tr td .form-group,
.forOfficeUseOnly table tr th .form-group {
  margin: 20px 0 10px;
}

.qualitySurveyUpdate .questions table {
  min-width: 600px;
}
.qualitySurveyUpdate .questions table tr th:nth-child(1),
.qualitySurveyUpdate .questions table tr td:nth-child(1) {
  width: 85%;
}
.qualitySurveyUpdate .questions table tr th:nth-child(2),
.qualitySurveyUpdate .questions table tr td:nth-child(2) {
  width: 15%;
  text-align: center;
}
.qualitySurveyUpdate .questions table tr th .feedbackTable table,
.qualitySurveyUpdate .questions table tr td .feedbackTable table {
  min-width: unset;
  width: 100%;
}
.qualitySurveyUpdate .questions table tr th .feedbackTable table tr td,
.qualitySurveyUpdate .questions table tr td .feedbackTable table tr td {
  width: 25%;
}
.qualitySurveyUpdate .questions table tr th .feedbackTable table tr td .feedBackIcons,
.qualitySurveyUpdate .questions table tr td .feedbackTable table tr td .feedBackIcons {
  width: 20px;
  height: 20px;
}

.exportDataList span {
  display: block;
  padding: 5px 10px;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.exportDataList span:hover, .exportDataList span:focus, .exportDataList span:active {
  background: rgba(51, 51, 51, 0.05);
  color: #333;
}
.exportDataList span span {
  padding: 0;
  color: #333;
  cursor: pointer;
  border-bottom: 0;
}
.exportDataList span span:hover, .exportDataList span span:focus, .exportDataList span span:active {
  background: transparent;
}
.exportDataList span a {
  padding: 0 !important;
  color: #333;
  background: transparent;
}
.exportDataList span a:hover, .exportDataList span a:focus, .exportDataList span a:active {
  background: transparent !important;
  color: #333;
}

.rdt_TableHeader div {
  background: transparent !important;
}

.dailyDiaryContent table {
  width: 100%;
}
.dailyDiaryContent .dailyDiaryContentTable tr th,
.dailyDiaryContent .dailyDiaryContentTable tr td {
  width: 25%;
  vertical-align: center;
}
.dailyDiaryContent .dailyDiaryContentTable tr th .form-group,
.dailyDiaryContent .dailyDiaryContentTable tr td .form-group {
  margin: 10px 0 5px;
}
.dailyDiaryContent .dailyDiaryContentTable tr th .form-group input,
.dailyDiaryContent .dailyDiaryContentTable tr td .form-group input {
  padding: 5px 10px;
}
.dailyDiaryContent .dailyDiaryContentTable .moodRatingTable {
  width: 100%;
  margin: -1px;
}
.dailyDiaryContent .dailyDiaryContentTable .moodRatingTable tr th,
.dailyDiaryContent .dailyDiaryContentTable .moodRatingTable tr td {
  width: auto;
  text-align: center;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px;
}
.dailyDiaryContent .dailyDiaryContentTable .moodRatingTable.nightChecks tr th:nth-child(1) {
  text-align: left;
}
.dailyDiaryContent .feedBackIcons {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  margin: 10px auto;
  padding: 0;
}
.dailyDiaryContent .feedBackIcons img {
  width: 100%;
}
.dailyDiaryContent .feedBackIcons.Inadequate, .dailyDiaryContent .feedBackIcons.Angry, .dailyDiaryContent .feedBackIcons.Worried {
  background: #e81224;
}
.dailyDiaryContent .feedBackIcons.RequiresImprovement, .dailyDiaryContent .feedBackIcons.Excited, .dailyDiaryContent .feedBackIcons.Scared {
  background: #f7630c;
}
.dailyDiaryContent .feedBackIcons.Good, .dailyDiaryContent .feedBackIcons.Happy, .dailyDiaryContent .feedBackIcons.Proud, .dailyDiaryContent .feedBackIcons.Hopeful, .dailyDiaryContent .feedBackIcons.Confident {
  background: #fff100;
}
.dailyDiaryContent .feedBackIcons.Outstanding, .dailyDiaryContent .feedBackIcons.Nervous {
  background: #16c60c;
}
.dailyDiaryContent .feedBackIcons.Bored, .dailyDiaryContent .feedBackIcons.Sad, .dailyDiaryContent .feedBackIcons.Relaxed, .dailyDiaryContent .feedBackIcons.Calm, .dailyDiaryContent .feedBackIcons.Tired {
  background: #55bbd1;
}

.theading td,
.theading th {
  background: rgba(0, 0, 0, 0.3);
}

.myContactsViewCarePlan {
  margin: 30px 0 0;
}
.myContactsViewCarePlan tr td,
.myContactsViewCarePlan tr th {
  vertical-align: middle;
}
.myContactsViewCarePlan tr td:nth-child(1),
.myContactsViewCarePlan tr th:nth-child(1) {
  text-align: center;
}
.myContactsViewCarePlan tr td p,
.myContactsViewCarePlan tr th p {
  margin: 10px 0;
}
.myContactsViewCarePlan tr td p strong,
.myContactsViewCarePlan tr th p strong {
  display: block;
}

.rosterTableContent .rotaRosterDragDrop tr td .droppableArea {
  min-height: 25px;
}
.rosterTableContent .rotaRosterDragDrop tr td div:focus .actionItem, .rosterTableContent .rotaRosterDragDrop tr td div:active .actionItem {
  opacity: 0;
}
.rosterTableContent .rotaRosterDragDrop tr td .contentcell {
  margin-bottom: 0;
  padding-bottom: 15px;
}
.rosterTableContent .rotaRosterDragDrop tr td .shiftActionButtons {
  margin-bottom: 0;
  position: relative;
  top: -10px;
}
.rosterTableContent .rotaRosterDragDrop tr td .shiftActionButtons .viewLogs {
  cursor: pointer;
}

.previousDate {
  margin-bottom: 15px !important;
}

.payrolDataTableWrapper .rdt_TableHeader .hcAczL {
  font-size: 12px !important;
}
.payrolDataTableWrapper div[role=rowgroup] div[role=row] div[role=cell]:nth-child(2) > div {
  width: 100%;
}
.payrolDataTableWrapper .infoBlock {
  margin-left: auto;
}
.payrolDataTableWrapper .infoBlock img {
  width: 18px;
  height: 18px;
  background: #ffffff;
}
.payrolDataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol:nth-child(1) {
  border-top: 1px solid rgba(51, 51, 51, 0.1) !important;
  padding: 10px;
}
.payrolDataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] .rdt_TableCell:nth-child(1) {
  border-top: 1px solid rgba(51, 51, 51, 0.1) !important;
  border-right: 1px solid rgba(51, 51, 51, 0.1) !important;
  padding: 10px;
}

.finalPayrolDataTableWrapper div[role=rowgroup] div[role=row] div[role=cell]:nth-child(1) > div {
  width: 100%;
}
.finalPayrolDataTableWrapper .infoBlock {
  margin-left: auto;
}
.finalPayrolDataTableWrapper .infoBlock img {
  width: 18px;
  height: 18px;
  background: #ffffff;
}

.exportPdfPayroll .infoBlock,
.exportPdfPayroll .userImage {
  display: none;
}

span.contractedHour {
  display: block;
  text-align: center;
}

.exportPdfContainer {
  height: 0;
  overflow: hidden;
}

.borderBottomTable > div,
.borderBottomTable > .gmlwXk {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}

::-webkit-scrollbar {
  width: 6px;
  background: #f6f6f6;
}

::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: #c1c3c8;
}

html {
  background: #f6f6f6;
}

body {
  scrollbar-width: thin;
}

* {
  transition: all 0.5s;
}

div {
  transition: font-size 0s;
}

body::-webkit-scrollbar-thumb {
  background-color: #c1c3c8;
  color: #333;
}

video {
  max-width: 100%;
}

p {
  font-size: 14px;
  color: #666;
}

strong {
  color: #333;
}

a {
  color: #278dec;
}

li {
  font-size: 14px;
  white-space: normal;
  color: #666;
  margin-bottom: 5px;
}

.text-center {
  text-align: center;
}

.visibilityHidden {
  visibility: hidden;
}

.displayNone {
  display: none;
}

.text-right {
  text-align: right;
}

.textTransformCapitalize {
  text-transform: capitalize;
}

.loadingText {
  font-size: 12px !important;
  color: #666 !important;
}

textarea {
  resize: none;
}

.curSorPointer {
  cursor: pointer;
}

span {
  font-size: 14px;
}

a {
  text-decoration: none;
}
a:hover {
  text-decoration: none;
}

table tr td,
table tr th {
  font-size: 14px;
}

img.loadingButtonIcon {
  width: 20px;
  height: 20px;
  margin-top: -3px;
}

.toolTip {
  font-size: 12px;
}

.pr-0 {
  padding-right: 0 !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.clearfix::after {
  content: "";
  display: table;
  clear: both;
}

img.removeRow,
img.addRow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

img.addRow {
  margin-top: 20px;
}

.minHeight300 {
  min-height: 300px;
}

.minHeight250 {
  min-height: 250px;
}

.MuiBox-root .fullWidthHeader {
  background: #ffffff;
  padding: 0 20px;
}
.MuiBox-root .fullWidthHeader .logoBox img {
  width: 120px;
}
.MuiBox-root .headerToolbar {
  background: #ffffff;
  color: #333;
  padding: 0 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.MuiBox-root .headerToolbar .headerToolbarMenuProfile {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;
}
.MuiBox-root .headerToolbar .contentHeader {
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.MuiBox-root .headerToolbar .profileDropdown button {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  background: none;
  border: 0;
  color: #333;
}
.MuiBox-root .headerToolbar .profileDropdown button img {
  border-radius: 50%;
  width: 32px;
  height: 32px;
}
.MuiBox-root .headerToolbar .profileDropdown button p {
  font-size: 14px;
  margin: 0 0 0 5px;
  text-align: left;
  color: #333;
  font-weight: 600;
}
.MuiBox-root .headerToolbar .profileDropdown button p small {
  font-size: 12px;
  display: block;
  color: #666;
  font-weight: normal;
}
.MuiBox-root .headerToolbar .headerMenu {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  gap: 20px;
}
.MuiBox-root .headerToolbar .headerMenu li {
  list-style: none;
  font-size: 14px;
}
.MuiBox-root .headerToolbar .headerMenu li a {
  color: #666;
}
.MuiBox-root .headerToolbar .secondaryMenuToggleIcon {
  display: none;
}
.MuiBox-root .mainWrapper {
  background: #f6f6f6;
  padding: 15px;
  overflow-x: hidden;
  position: relative;
}
.MuiBox-root .mainWrapper .mainContent {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
}
.MuiBox-root .mainWrapper .mainContent p {
  font-size: 14px;
  white-space: normal;
}
.MuiBox-root .mainWrapper .mainContent p small {
  display: block;
  margin-top: 10px;
}
.MuiBox-root .mainWrapper .mainContent h3 {
  font-size: 18px;
}
.MuiBox-root .mainWrapper .mainContent h3 small {
  font-weight: normal;
}
.MuiBox-root .mainWrapper .mainContent h3.viewCarePlanTitle {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  width: 100%;
}
.MuiBox-root .mainWrapper .mainContent.headingContent h3 {
  padding: 10px;
  margin: 0;
}
@media (max-width: 768px) {
  .MuiBox-root .headerToolbar {
    padding: 0 20px;
  }
  .MuiBox-root .headerToolbar .headerToolbarMenuProfile {
    position: relative;
    gap: 10px;
  }
  .MuiBox-root .headerToolbar .headerToolbarMenuProfile .secondaryMenuToggleIcon {
    display: block;
  }
  .MuiBox-root .headerToolbar .headerToolbarMenuProfile .secondaryMenuToggleIcon img {
    width: 25px;
  }
  .MuiBox-root .headerToolbar .headerMenu {
    display: none;
  }
  .MuiBox-root .headerToolbar .headerMenu.mobileSecondaryMenu {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;
    position: absolute;
    top: 100%;
    right: 0;
    left: 0;
    margin: 0;
    padding: 0;
    gap: 0;
    width: 200px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
  }
  .MuiBox-root .headerToolbar .headerMenu.mobileSecondaryMenu li {
    padding: 5px 20px;
    border-bottom: 1px solid #f6f6f6;
    text-align: left;
    background: #ffffff;
    width: 100%;
  }
  .MuiBox-root .headerToolbar .headerMenu.mobileSecondaryMenu li a {
    display: block;
    text-align: left;
  }
}

table.tableStriped tbody tr:nth-child(odd) td {
  background: #f8f9fb !important;
}

.tableText {
  padding-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  flex-wrap: nowrap;
  gap: 10px;
}
.tableText p {
  margin: 20px 0;
  width: 95%;
}
.tableText.warning {
  border-left: 4px solid #faa308;
}
.tableText.success {
  border-left: 4px solid #03b93f;
}
.tableText.danger {
  border-left: 4px solid #da0b0b;
}
.tableText .editIcon {
  margin: 20px 0;
}
.tableText a {
  color: #333;
}

.edit,
.close,
.delete,
.restore {
  cursor: pointer;
}

.restore img {
  width: 25px;
  height: 25px;
}

.btn-success {
  background: #03b93f;
  border: 1px solid #03b93f;
}

body.modal-open .mainWrapper.MuiBox-root {
  position: relative;
  z-index: 9;
}

.modal.show .modal-dialog {
  max-height: calc(100% - 56px);
  margin: 0 auto;
  width: 96%;
  top: 50%;
  transform: translateY(-45%);
}
.modal.show .modal-dialog .modal-header .btn-close {
  filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(301deg) brightness(102%) contrast(101%);
  opacity: 1;
}
.modal.show .modal-dialog .modal-header .btn-close:hover, .modal.show .modal-dialog .modal-header .btn-close:active, .modal.show .modal-dialog .modal-header .btn-close:focus {
  outline: none;
  box-shadow: none;
}
.modal.show .modal-dialog .modal-content .modal-body {
  max-height: 500px;
  overflow-y: auto;
}

.headerToggleTitle {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0;
}
.headerToggleTitle button {
  margin-right: 0;
}

.maxWidthTable .preloader-container {
  margin-top: 30px;
}

.pbsPlan .dataTableWrapper .rdt_TableHeader {
  overflow: visible;
}
.pbsPlan .dataTableWrapper .rdt_TableHeader .OWguJ {
  background: transparent;
}
.pbsPlan .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] > div {
  width: 100%;
}
.pbsPlan .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] .usersNameInfo {
  min-width: 500px;
}
.pbsPlan.supervisionBlocks .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(2), .pbsPlan.supervisionBlocks .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(3), .pbsPlan.supervisionBlocks .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(4) {
  padding-left: 0;
}
.pbsPlan.supervisionDetailTable .rdt_TableHeader {
  width: 100%;
  float: none;
}
.pbsPlan.supervisionDetailTable .rdt_TableHeader div {
  font-weight: bold;
  font-size: 16px;
}
.pbsPlan.supervisionDetailTable .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(1) {
  padding-left: 0;
}
.pbsPlan.pbsPlanUpdated .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] > div {
  padding-top: 15px;
  padding-bottom: 15px;
}
.pbsPlan.pbsPlanUpdated .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] > div .contentBox p {
  font-size: 14px;
  font-weight: normal;
  color: #333;
  margin: 10px 5px;
}

.pbsFieldWrapper {
  border-bottom: 2px solid rgba(193, 195, 200, 0.5);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.pbsFieldWrapper .alert {
  margin: 0;
  border-radius: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.pbsFieldWrapper .alert h3 {
  padding: 0 !important;
  margin: 0;
  border: 0 !important;
}
.pbsFieldWrapper .alert span {
  font-size: 12px;
}
.pbsFieldWrapper .alert.mobpBg {
  background-color: #006400;
}
.pbsFieldWrapper .alert.mobpBg h3 {
  color: #ffffff !important;
}
.pbsFieldWrapper .alert.spBg {
  background-color: rgb(241, 194, 50);
}
.pbsFieldWrapper .alert.spBg h3 {
  color: #000000 !important;
}
.pbsFieldWrapper .alert.rsBg {
  background-color: rgb(204, 0, 0);
}
.pbsFieldWrapper .alert.rsBg h3 {
  color: #000000 !important;
}
.pbsFieldWrapper .alert.pisrBg {
  background-color: rgb(60, 120, 216);
}
.pbsFieldWrapper .alert.pisrBg h3 {
  color: #000000 !important;
}
.pbsFieldWrapper .jodit-react-container .jodit-container {
  border-radius: 0;
}
.pbsFieldWrapper .jodit-react-container .jodit-container.mobp .jodit-workplace {
  background: rgba(182, 215, 168, 0.5);
}
.pbsFieldWrapper .jodit-react-container .jodit-container.secondaryPrevention .jodit-workplace {
  background: rgba(255, 229, 153, 0.5);
}
.pbsFieldWrapper .jodit-react-container .jodit-container.reactiveStartegies .jodit-workplace {
  background: rgba(255, 242, 242, 0.3);
}
.pbsFieldWrapper .jodit-react-container .jodit-container.pisr .jodit-workplace {
  background: rgba(39, 141, 236, 0.2);
}
.pbsFieldWrapper .pbsField {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-bottom: 10px;
  margin-bottom: 10px;
}
.pbsFieldWrapper .pbsField button {
  font-size: 12px;
}

.mobpContentBg {
  background: rgba(182, 215, 168, 0.5);
}

.spContentBg {
  background: rgba(255, 229, 153, 0.5);
}

.rsContentBg {
  background: rgba(255, 242, 242, 0.3);
}

.pisrContentBg {
  background: rgba(39, 141, 236, 0.2);
}

.viewEditorWrapper {
  position: relative;
}
.viewEditorWrapper .usersNameInfo {
  align-items: flex-start;
}
.viewEditorWrapper .viewEditorContent {
  padding: 20px;
}
.viewEditorWrapper .viewEditorContent header.createAndModifyStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5) !important;
  padding: 0 0 20px;
  margin-bottom: 20px;
}
.viewEditorWrapper .viewEditorContent header.createAndModifyStatus .enteredBy {
  margin-bottom: 0;
}
.viewEditorWrapper .viewEditorContent.qualitySurveyEditorContent .mainContentHeader {
  padding: 0 0 10px;
}
.viewEditorWrapper .viewEditorContent.qualitySurveyEditorContent .personalDetailContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 20px;
  padding: 20px 0 0;
}

.usersNameInfo {
  display: block;
  font-weight: bold;
  display: flex;
  align-items: center;
}
.usersNameInfo img {
  margin: 5px 5px 7px 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(193, 195, 200, 0.5);
}
.usersNameInfo img.isDriver {
  background: transparent;
  width: 19px;
  height: 18px;
  margin: -20px 0 0 -17px;
  border: 2px solid #fff;
}
.usersNameInfo .iconImage img {
  width: 20px;
  height: 20px;
  border-radius: 0;
  background: transparent;
}
.usersNameInfo .userInfo {
  display: flex;
  flex-direction: column;
}
.usersNameInfo .userInfo .nameBlock {
  font-weight: normal;
}
.usersNameInfo .userInfo .dayTime {
  position: absolute;
  top: 5px;
  right: 5px;
  font-weight: normal;
  font-size: 10px;
}
.usersNameInfo.shiftLogs {
  border-bottom: 0 !important;
}
.usersNameInfo.shiftLogs img {
  width: 25px !important;
  height: 25px !important;
}
.usersNameInfo.shiftLogs span {
  font-size: 12px !important;
}
.usersNameInfo.shiftLogs .dateTime {
  font-weight: normal;
}

.userInfoWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.userInfoWrapper .btn-primary {
  background: transparent;
  padding: 0;
  margin: 0;
  border: 0;
  text-align: right;
}
.userInfoWrapper .btn-primary:active, .userInfoWrapper .btn-primary:focus, .userInfoWrapper .btn-primary:hover {
  background: none;
}
.userInfoWrapper .btn-primary img {
  margin: 0;
  background: none;
  width: 15px;
  height: 15px;
  display: block;
}

.toolTipInfo {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  color: #333;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  width: 200px;
  opacity: 1 !important;
  border: 0 !important;
}

.toolTipInfo .tooltip-inner {
  background-color: #ffffff;
  color: #333;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  opacity: 1 !important;
  border: 0 !important;
}

.toolTipInfo .tooltip-arrow::before {
  border-right-color: #ffffff !important;
}

.careNoteToolTip.show.tooltip {
  z-index: 9999;
}

.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  position: absolute;
  top: 50%;
  z-index: 9999;
  transform: translateY(-50%);
  max-width: 100%;
  left: 50%;
}
.preloader-container .preloader-spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: #333;
  animation: spin 1s linear infinite;
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.editIcon {
  background: url(../images/icons/editBlock.svg) no-repeat center center transparent;
  background-size: 20px;
  display: block;
  float: right;
  height: 20px;
  width: 20px;
  cursor: pointer;
}

.btn.btn-sm.btn-outline-primary.editButtonGrid {
  border: 0;
}
.btn.btn-sm.btn-outline-primary.editButtonGrid .editIconGrid {
  display: block;
  float: right;
  height: 18px;
  width: 18px;
}
.btn.btn-sm.btn-outline-primary.editButtonGrid .editIconGrid img {
  margin-top: -4px;
  filter: invert(45%) sepia(96%) saturate(1781%) hue-rotate(188deg) brightness(94%) contrast(96%);
}
.btn.btn-sm.btn-outline-primary.editButtonGrid:hover {
  background: transparent;
}
.btn.btn-sm.btn-outline-primary.editButtonGrid:hover .editIconGrid img {
  filter: invert(32%) sepia(81%) saturate(7491%) hue-rotate(199deg) brightness(98%) contrast(101%);
}

.scheduleBlocks .cards {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px 10px 20px 10px;
  margin: 8px 0 20px 0;
}
.scheduleBlocks .cards .cardContent {
  text-align: center;
}
.scheduleBlocks .cards .cardContent .contentIcon img {
  width: 40px;
  height: 40px;
}
.scheduleBlocks .cards .cardContent h3 {
  font-size: 22px;
}
.scheduleBlocks .cards .cardContent h3 small {
  display: block;
  font-size: 14px;
  color: #666;
  margin: 10px 0;
  font-weight: normal;
}
@media (max-width: 993px) {
  .scheduleBlocks .cards .cardContent h3 {
    font-size: 18px;
  }
}
@media (max-width: 768px) {
  .scheduleBlocks {
    margin-top: 10px;
  }
  .scheduleBlocks .cards {
    margin: 0 auto 20px;
    max-width: 250px;
  }
  .scheduleBlocks p.currentStatus {
    max-width: 250px;
    margin: 0 auto 20px;
  }
}
.scheduleBlocks.trainingBlocks .clearfix {
  height: 0;
}

.dashboardBlocksWrapper {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  width: 100%;
  max-width: 1000px;
  margin: 50px auto;
  padding: 60px;
  border-radius: 10px;
}
.dashboardBlocksWrapper h2 {
  margin-bottom: 60px;
  font-size: 30px;
}
.dashboardBlocksWrapper .dashboardBlock {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  text-align: center;
  height: 165px;
  border: 1px solid rgba(193, 195, 200, 0.5);
  border-top: 0;
  border-left: 0;
}
.dashboardBlocksWrapper .dashboardBlock:hover {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.dashboardBlocksWrapper .dashboardBlock img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}
.dashboardBlocksWrapper .dashboardBlock h3 {
  font-weight: normal;
  font-size: 16px;
  font-family: "Proxima Nova Lt";
}
.dashboardBlocksWrapper .col-md-3.col-sm-6 {
  padding: 0;
}
.dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(5) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(6) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(7) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(8) .dashboardBlock {
  border-bottom: 0;
}
.dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(4n) .dashboardBlock {
  border-right: 0;
}
@media (max-width: 993px) {
  .dashboardBlocksWrapper .dashboardBlock h3 {
    font-size: 16px;
  }
}
@media (max-width: 993px) {
  .dashboardBlocksWrapper .dashboardBlock h3 {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .dashboardBlocksWrapper .col-md-3.col-sm-6 {
    padding: 0;
  }
  .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(5) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(6) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(7) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(8) .dashboardBlock {
    border: 1px solid rgba(193, 195, 200, 0.5);
  }
  .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(4n) .dashboardBlock {
    border: 1px solid rgba(193, 195, 200, 0.5);
    border-left: 0;
  }
  .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(5) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(6) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(7) .dashboardBlock, .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(8) .dashboardBlock {
    border-left: 0;
  }
  .dashboardBlocksWrapper .col-md-3.col-sm-6:nth-child(2n) .dashboardBlock {
    border-right: 0;
  }
}
@media (max-width: 575px) {
  .dashboardBlocksWrapper .dashboardBlock {
    max-width: 250px;
    margin: 0 auto;
    padding: 30px 0;
    height: auto;
    border: 0 !important;
    border-bottom: 1px solid rgba(193, 195, 200, 0.5) !important;
  }
}

.profileDetailWrapper .profileDetailBlock {
  background: #ffffff;
  padding: 20px;
  border-radius: 5px;
  position: relative;
}
.profileDetailWrapper .profileDetailBlock .userAvatar {
  text-align: center;
  padding-bottom: 30px;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.profileDetailWrapper .profileDetailBlock .userAvatar img {
  border-radius: 50%;
  width: 150px;
  height: 150px;
  max-width: 100%;
  max-height: 100%;
  background: rgba(193, 195, 200, 0.5);
}
.profileDetailWrapper .profileDetailBlock .userAvatar .avatarAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  position: relative;
  top: -20px;
}
.profileDetailWrapper .profileDetailBlock .userAvatar .avatarAction img {
  border: 1px solid #ffffff;
  width: 30px;
  height: 30px;
}
.profileDetailWrapper .profileDetailBlock .userAvatar .avatarAction span {
  cursor: pointer;
}
.profileDetailWrapper .profileDetailBlock .userAvatar h3 {
  font-size: 20px;
}
.profileDetailWrapper .profileDetailBlock .userDetail {
  font-size: 14px;
  color: #666;
  padding: 20px;
}
.profileDetailWrapper .profileDetailBlock .userDetail p {
  margin-bottom: 20px;
}
.profileDetailWrapper .profileDetailBlock .userDetail p small {
  display: block;
}
.profileDetailWrapper .userPayrollDetail .scheduleBlocks .row {
  align-items: end;
}
.profileDetailWrapper .userPayrollDetail .scheduleBlocks .cards {
  margin-top: 0;
}
.profileDetailWrapper .userPayrollDetail .scheduleBlocks p.currentStatus {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px 15px;
  font-weight: bold;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  font-size: 14px;
}
.profileDetailWrapper .userPayrollDetail .scheduleBlocks p.currentStatus span {
  background: #03b93f;
  display: block;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  margin-left: 5px;
}
@media (max-width: 1400px) {
  .profileDetailWrapper .userPayrollDetail .scheduleBlocks p.currentStatus {
    font-size: 12px;
  }
}
@media (max-width: 768px) {
  .profileDetailWrapper .profileDetailBlock {
    max-width: 300px;
    margin: 20px auto;
  }
}

.headerContainerSearchAction {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  flex-direction: row-reverse;
  margin-bottom: 15px;
  padding: 15px 15px;
}
.headerContainerSearchAction .actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.headerContainerSearchAction .img-btn img {
  width: 20px;
  height: 20px;
}
.headerContainerSearchAction .img-btn:hover img {
  filter: brightness(0) invert(1);
}
@media (max-width: 768px) {
  .headerContainerSearchAction {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    flex-wrap: nowrap;
    gap: 10px;
  }
  .headerContainerSearchAction .searchContainer {
    width: 100%;
  }
}

.headerContainerUserWithStatus {
  flex-direction: row;
  position: relative;
  z-index: 2;
}
.headerContainerUserWithStatus .usersNameInfo .userInfo .nameBlock {
  font-weight: 600;
}
.headerContainerUserWithStatus .usersNameInfo .userInfo .infoBlock {
  font-weight: normal;
}
.headerContainerUserWithStatus .usersNameInfo .userInfo .infoBlock .statusCircle {
  float: right;
  margin: 5px 0 0 10px;
  width: 8px;
  height: 8px;
}
@media (max-width: 768px) {
  .headerContainerUserWithStatus {
    display: block;
  }
  .headerContainerUserWithStatus .usersNameInfo {
    margin-bottom: 10px;
  }
}
@media (max-width: 575px) {
  .headerContainerUserWithStatus .actionButtons {
    display: block;
  }
  .headerContainerUserWithStatus .actionButtons .dropdown {
    margin-top: 10px;
  }
}

.contentOverview {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.contentOverview h3 {
  font-size: 18px;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding: 20px;
  margin: 0;
}

.payrollOverview .calendarOverview {
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;
}
.payrollOverview .calendarOverview .headerRow .dayCell {
  font-weight: bold;
  font-size: 14px;
  padding: 15px 0 !important;
  text-align: center !important;
}
.payrollOverview .calendarOverview .dayRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.payrollOverview .calendarOverview .dayRow:nth-child(even) {
  background: #f6f6f6;
}
.payrollOverview .calendarOverview .dayRow .dayCell {
  border: 1px solid rgba(193, 195, 200, 0.5);
  border-top: 0;
  border-right: 0;
  width: 100%;
  text-align: left;
  padding: 0 5px;
  min-width: 130px;
  min-height: 56px;
}
.payrollOverview .calendarOverview .dayRow .dayCell:last-child {
  border-right: 1px solid rgba(193, 195, 200, 0.5);
}
.payrollOverview .calendarOverview .dayRow .dayCell.holidayCell {
  background: transparent;
}
.payrollOverview .calendarOverview .dayRow .dayCell.holidayCell .contentcell {
  background: transparent;
  box-shadow: none;
}
.payrollOverview .calendarOverview .dayRow .dayCell.sleepCell {
  background: transparent;
}
.payrollOverview .calendarOverview .dayRow .dayCell.sleepCell .contentcell {
  background: transparent;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  box-shadow: none;
}

.contentcell {
  border-radius: 5px;
  padding: 5px 5px;
  margin: 5px 0;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(193, 195, 200, 0.5);
}
.contentcell .statusBall {
  display: block;
  float: right;
  height: 6px;
  width: 6px;
  background: #666;
  border-radius: 50%;
}
.contentcell.greenCell {
  border-left: 3px solid #03b93f;
}
.contentcell.greenCell .statusBall {
  background: #03b93f;
}
.contentcell.redCell {
  border-left: 3px solid #da0b0b;
}
.contentcell.redCell .statusBall {
  background: #da0b0b;
}
.contentcell.greyCell {
  border-left: 3px solid #666;
}
.contentcell.publishedCell {
  border-left: 3px solid #278dec;
}
.contentcell.publishedCell .statusBall {
  background: #278dec;
}
.contentcell.savedCell {
  border-left: 3px solid #ffb000;
}
.contentcell.savedCell .statusBall {
  background: #ffb000;
}
.contentcell.futureCell {
  border-left: 3px solid #c8ffd9;
}
.contentcell.holidayCell {
  background: none;
}
.contentcell span {
  display: block;
}
.contentcell .time {
  font-size: 12px;
  font-weight: bold;
}
.contentcell .type {
  font-size: 10px;
}
.contentcell .request {
  font-size: 12px;
  font-weight: bold;
}
.contentcell .requestStatus {
  font-size: 12px;
}

.statusButton {
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 13px;
  text-transform: capitalize;
}
.statusButton:hover, .statusButton:focus {
  outline: 0;
}
.statusButton.btn-danger, .statusButton.Inactive {
  background: #fff2f2;
  color: #da0b0b;
  border-color: #fff2f2;
}
.statusButton.btn-success, .statusButton.future {
  background: #c8ffd9;
  color: #46a37a;
  border-color: #c8ffd9;
}
.statusButton.btn-warning, .statusButton.saved {
  background: #ffebb7;
  color: #faa308;
  border-color: #ffebb7;
}
.statusButton.active {
  background: #d3eaff !important;
  color: #278dec !important;
  border-color: #d3eaff !important;
}
.statusButton .statusText {
  font-size: 13px;
}

select.statusButton {
  padding: 5px 10px;
}
select.statusButton:disabled {
  background: rgba(102, 102, 102, 0.3);
  color: #000000;
  border-color: rgba(102, 102, 102, 0.3);
  opacity: 0.3;
}

.actionColumns {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.actionColumns span {
  cursor: pointer;
}
.actionColumns .duplicate img,
.actionColumns .assignRole img,
.actionColumns .view img {
  width: 25px;
  height: 25px;
}

@media (max-width: 993px) {
  .dataTableWrapper .actionColumns {
    flex-wrap: wrap;
    display: block;
  }
  .dataTableWrapper .actionColumns img {
    width: 20px;
    height: 20px;
    margin: 3px;
  }
}

.viewIcons {
  background: #278dec;
  border-radius: 50%;
}
.viewIcons img {
  filter: brightness(0) invert(1);
  width: 23px;
  height: 23px;
  padding: 2px;
}

.incidents .headerContainerSearchAction {
  box-shadow: none;
  padding: 10px;
}
.incidents .headerContainerSearchAction .searchContainer {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  width: 75%;
}
.incidents .mainContentText {
  padding: 20px;
  min-height: calc(100vh - 200px);
}
.incidents .mainContentText h3 {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding-bottom: 20px;
}
.incidents.consentCapacityAddNew .formBlockContent {
  padding-bottom: 0;
}
.incidents.consentCapacityAddNew .formActionButtons {
  margin-top: 0;
  margin-bottom: 20px;
}
@media (max-width: 768px) {
  .incidents .headerContainerSearchAction .searchContainer {
    display: block;
    width: 100%;
  }
  .incidents .headerContainerSearchAction .searchContainer .searchTextBox {
    margin-bottom: 10px;
  }
  .incidents .headerContainerSearchAction .searchContainer .searchTextBox input,
  .incidents .headerContainerSearchAction .searchContainer .searchTextBox select {
    min-width: 100%;
  }
  .incidents .mainContentText {
    padding: 10px;
  }
}
.incidents.mainContentHeightAuto .mainContentText {
  min-height: auto;
}

.dropdown .headerDropDownToggle {
  background: #278dec !important;
  border: 1px solid #278dec !important;
}
.dropdown .headerDropDownToggle:hover {
  background: #006bcf !important;
}
.dropdown.show .dropdown-menu.show {
  padding: 0;
  font-size: 12px;
  overflow: hidden;
  width: 100%;
  max-width: 200px;
  border-radius: 0;
  border: 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background: #fff !important;
  z-index: 9;
}
.dropdown.show .dropdown-menu.show a {
  padding: 10px 10px;
}
.dropdown.show .dropdown-menu.show a:hover {
  background: rgba(99, 180, 255, 0.2);
}
.dropdown.show .dropdown-menu.show a:active {
  color: #333;
}

.chainBlocks {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: 14px;
}
.chainBlocks .imageChainBlock {
  margin: 5px 0 5px -14px;
  width: 32px;
  height: 32px;
  position: relative;
}
.chainBlocks .imageChainBlock img {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #d8d9db;
  border: 2px solid #ffffff;
}
.chainBlocks .moreUsersCounts {
  margin-left: 15px;
}

.training .addTrainingText h4 {
  font-size: 17px;
}

.addNewCandidate {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  text-align: center;
}
.addNewCandidate a {
  font-size: 16px;
  color: #278dec;
  font-family: "Proxima Nova Lt";
}
.addNewCandidate a img {
  width: 40px;
  height: 40px;
}
.addNewCandidate a span {
  display: block;
  margin-top: 10px;
}
@media (max-width: 768px) {
  .addNewCandidate {
    height: auto !important;
    margin-bottom: 15px;
    padding: 20px;
  }
}

.color-black {
  color: #000000;
}

.archievedCandidates {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
  margin-top: 20px;
  font-size: 14px;
}
.archievedCandidates .tabsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  flex-direction: row-reverse;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.archievedCandidates .nav.nav-tabs {
  border-bottom: 0;
}
.archievedCandidates .nav.nav-tabs .nav-link {
  border: 0;
  color: #333;
}
.archievedCandidates .nav.nav-tabs .nav-link.active {
  color: #278dec;
  border-bottom: 3px solid #278dec;
}
.archievedCandidates .archievedCandidatesContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 0 10px;
  padding: 20px 0 0;
  max-height: 330px;
  overflow-y: auto;
  position: relative;
}
.archievedCandidates .archievedCandidatesContent .ProgressPadding {
  padding: 40px 0 20px;
  position: relative;
  top: 20px;
  width: 100%;
}
.archievedCandidates .archievedCandidatesContent .blocks {
  width: 19%;
  border-right: 1px solid rgba(193, 195, 200, 0.5);
  padding: 10px;
}
.archievedCandidates .archievedCandidatesContent .blocks:nth-child(5n) {
  border-right: 0;
}
.archievedCandidates .archievedCandidatesContent .blocks .nameBlock {
  font-weight: bold;
}
.archievedCandidates .archievedCandidatesContent .blocks .infoBlock {
  font-weight: normal;
}
@media (max-width: 993px) {
  .archievedCandidates .archievedCandidatesContent .blocks {
    width: 23.5%;
  }
  .archievedCandidates .archievedCandidatesContent .blocks:nth-child(4n) {
    border-right: 0;
  }
  .archievedCandidates .archievedCandidatesContent .blocks:nth-child(5n) {
    border-right: 1px solid rgba(193, 195, 200, 0.5);
  }
}
@media (max-width: 768px) {
  .archievedCandidates {
    margin-top: 0;
  }
  .archievedCandidates .archievedCandidatesContent .blocks {
    width: 31.5%;
  }
  .archievedCandidates .archievedCandidatesContent .blocks:nth-child(4n) {
    border-right: 1px solid rgba(193, 195, 200, 0.5);
  }
  .archievedCandidates .archievedCandidatesContent .blocks:nth-child(3n) {
    border-right: 0;
  }
}
@media (max-width: 575px) {
  .archievedCandidates .tabsHeader {
    display: block;
  }
  .archievedCandidates .tabsHeader .actionButtons {
    margin-bottom: 20px;
  }
  .archievedCandidates .archievedCandidatesContent .blocks {
    width: 48%;
  }
  .archievedCandidates .archievedCandidatesContent .blocks:nth-child(3n) {
    border-right: 1px solid rgba(193, 195, 200, 0.5);
  }
  .archievedCandidates .archievedCandidatesContent .blocks:nth-child(2n) {
    border-right: 0;
  }
}
@media (max-width: 480px) {
  .archievedCandidates .archievedCandidatesContent .blocks {
    width: 100%;
    max-width: 170px;
    margin: 0 auto;
    border-right: 0 !important;
    border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  }
}

.applicationProcessWrapper .mainContent {
  background: transparent !important;
  box-shadow: none !important;
}
.applicationProcessWrapper .mainContent div[role=table] {
  background: transparent;
}
.applicationProcessWrapper .mainContent div[role=table] div[role=row] {
  background: transparent;
}
.applicationProcessWrapper .mainContent .dataTableWrapper .rdt_TableHeader {
  margin-bottom: 15px;
  width: 50%;
  padding: 10px 15px 14px;
  border-radius: 0 10px 0 0;
}
.applicationProcessWrapper .mainContent .dataTableWrapper header {
  padding: 10px 15px !important;
  width: 50%;
  border-radius: 10px 0 0 0;
}
.applicationProcessWrapper .mainContent .dataTableWrapper .maxWidthTable .rdt_Table .rdt_TableHead {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
}
.applicationProcessWrapper .mainContent .dataTableWrapper .maxWidthTable .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol {
  background: #ffffff;
  padding: 10px 10px;
}
.applicationProcessWrapper .mainContent .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] {
  border: 0;
  padding: 0;
}
.applicationProcessWrapper .mainContent .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(1) {
  border: 0 !important;
}
.applicationProcessWrapper .mainContent .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell].dahnUA div:first-child {
  overflow: unset;
}
.applicationProcessWrapper .applicantBlock img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(193, 195, 200, 0.5);
  margin-bottom: 10px;
}
.applicationProcessWrapper .applicantBlock .userInfo span {
  display: block;
  margin: 5px 0 0;
  font-size: 12px;
}
.applicationProcessWrapper .applicantBlock .userInfo .nameBlock {
  font-weight: bold;
  font-size: 14px;
}
@media (max-width: 768px) {
  .applicationProcessWrapper .mainContent .dataTableWrapper .rdt_TableHeader {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
  .applicationProcessWrapper .mainContent .dataTableWrapper header {
    width: 100%;
    border-radius: 10px 10px 0 0;
  }
}

.staffOverview .mainContent {
  margin-bottom: 20px;
}
.staffOverview .mainContent h4 {
  font-size: 16px;
  padding: 5px 0 10px;
  margin-bottom: 0;
}
.staffOverview .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(3) {
  padding-left: 0;
}
.staffOverview .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] .tableText {
  padding: 16px 15px;
}
.staffOverview .h-100 {
  height: calc(100% - 20px) !important;
}

.secondColumnPaddingZero div[role=rowgroup] div[role=row] div[role=cell]:nth-child(2) {
  padding-left: 0 !important;
}

.shiftInfo {
  text-align: center;
  padding: 0 10px;
}
.shiftInfo span {
  display: block;
}
.shiftInfo .shiftType {
  font-size: 14px;
  font-weight: bold;
}
.shiftInfo .shiftTitle {
  font-size: 13px;
}

.statusCircle {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
}
.statusCircle.green {
  background: #03b93f;
}
.statusCircle.red {
  background: #da0b0b;
}

.modal-backdrop.show {
  z-index: 9999;
}

.ftcModalPopup {
  z-index: 9999;
}
.ftcModalPopup .modal-dialog .modal-content {
  border-radius: 8px;
  border: 0;
  overflow: hidden;
}
.ftcModalPopup .modal-dialog .modal-content .modal-header {
  background: #278dec;
  color: #ffffff;
  padding: 10px 20px;
}
.ftcModalPopup .modal-dialog .modal-content .modal-header .modal-title {
  font-size: 16px;
  font-weight: normal;
}
.ftcModalPopup .modal-dialog .modal-content .modal-body {
  padding: 5px 20px;
}
.ftcModalPopup .modal-dialog .modal-content .modal-body .modalBodyContent {
  padding: 10px 0;
}
.ftcModalPopup .modal-dialog .modal-content .modal-body .modalBodyContent p {
  margin: 0;
}
.ftcModalPopup .modal-dialog .modal-content .modal-body .leaveStatusApproval {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.ftcModalPopup .modal-dialog .modal-content .usersNameInfo {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding-bottom: 5px;
}
.ftcModalPopup .modal-dialog .modal-content .usersNameInfo img {
  width: 60px;
  height: 60px;
  margin-right: 10px;
}
.ftcModalPopup .modal-dialog .modal-content .usersNameInfo .nameBlock {
  font-weight: bold;
}
.ftcModalPopup .modal-dialog .modal-content .usersNameInfo .infoBlock {
  font-weight: normal;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat .cards {
  width: 50%;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat .cards .usersNameInfo .userImage img {
  width: 50px;
  height: 50px;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat .cards .usersNameInfo .infoBlock {
  font-size: 12px;
  word-break: break-all;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat.editGroupInfo .usersNameInfo {
  padding: 10px 0;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat.editGroupInfo .usersNameInfo .userImage img {
  width: 40px;
  height: 40px;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat.editGroupInfo .usersNameInfo .crossImageContainer {
  margin-left: auto;
  margin-bottom: auto;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat.editGroupInfo .usersNameInfo .crossImageContainer .closeButton {
  cursor: pointer;
}
.ftcModalPopup .modal-dialog .modal-content .userListAddChat.editGroupInfo .usersNameInfo .crossImageContainer .closeButton img {
  background: #fbe2e2;
  width: 15px !important;
  height: 15px !important;
  border-radius: 50%;
}
.ftcModalPopup .modal-dialog .modal-content .modal-footer {
  background: rgba(193, 195, 200, 0.1);
  border: 0;
}
.ftcModalPopup.carePlanSectionsModal .modal-dialog {
  max-width: 400px;
  width: 100%;
}
.ftcModalPopup.carePlanSectionsModal .rosterPopup {
  height: auto;
  overflow-y: auto;
  max-height: 600px;
}
.ftcModalPopup.carePlanSectionsModal .carePlanSections {
  text-align: center;
}
.ftcModalPopup.carePlanSectionsModal .carePlanSections .buttonLink {
  display: block;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#278dec+0,00519d+100 */
  background: linear-gradient(45deg, #278dec 0%, #00519d 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  color: #ffffff;
  max-width: 300px;
  margin: 20px auto;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.ftcModalPopup.carePlanSectionsModal .carePlanSections .buttonLink img {
  width: 30px;
  height: 30px;
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content {
  max-width: 600px;
  width: 96%;
  transform: translateX(-50%);
  left: 50%;
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content .modal-header {
  padding: 5px;
  margin-right: 0;
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content .modal-header span img {
  filter: brightness(0) invert(1);
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content .modal-header .btn-close {
  background: url(../images/icons/actionButtonDel.svg) center center no-repeat;
  background-size: 20px;
  opacity: 1;
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content .editBlockContent img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgba(193, 195, 200, 0.5);
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content .editBlockContent .closeButton {
  text-align: right;
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content .editBlockContent .closeButton span {
  cursor: pointer;
}
.ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content .editBlockContent .closeButton span img {
  width: 25px;
  height: 25px;
  background: none;
}
.ftcModalPopup.suProfileEdit .modal-dialog {
  max-width: 800px;
}
.ftcModalPopup.suProfileEdit .modal-dialog .modal-content {
  max-width: 800px;
  width: 96%;
}
@media (max-width: 993px) {
  .ftcModalPopup.suProfileEdit .modal-dialog .modal-content {
    max-width: 800px;
    width: 96%;
  }
}
.ftcModalPopup.patientVideoPopup img {
  max-width: 100%;
}
.ftcModalPopup.patientVideoPopup .text-center {
  padding: 10px 0 0;
}
.ftcModalPopup.patientVideoPopup .text-center p {
  padding: 10px 0;
  margin-bottom: 0;
}

.stafOverviewBlocksContent {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px 30px;
  border-radius: 10px;
  border-left: 5px solid #03b93f;
  margin: 5px 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.stafOverviewBlocksContent h4 {
  font-size: 28px;
  font-weight: normal;
  margin: 0;
}
.stafOverviewBlocksContent h4 small {
  display: block;
  font-size: 50%;
  color: rgba(51, 51, 51, 0.8);
}
.stafOverviewBlocksContent .contentNumber {
  font-size: 50px;
}
.stafOverviewBlocksContent.warningContent {
  border-left: 5px solid #faa308;
}
.stafOverviewBlocksContent.dangerContent {
  border-left: 5px solid #da0b0b;
}
@media (max-width: 1024px) {
  .stafOverviewBlocksContent h4 {
    font-size: 26px;
  }
  .stafOverviewBlocksContent .contentNumber {
    font-size: 40px;
  }
}
@media (max-width: 993px) {
  .stafOverviewBlocksContent h4 {
    font-size: 22px;
  }
  .stafOverviewBlocksContent .contentNumber {
    font-size: 40px;
  }
}
@media (max-width: 768px) {
  .stafOverviewBlocksContent {
    padding: 20px 10px;
  }
  .stafOverviewBlocksContent h4 {
    font-size: 20px;
  }
  .stafOverviewBlocksContent h4 small {
    font-size: 12px;
  }
  .stafOverviewBlocksContent .contentNumber {
    font-size: 30px;
  }
}
@media (max-width: 575px) {
  .stafOverviewBlocksContent {
    max-width: 300px;
    margin: 10px auto;
  }
  .stafOverviewBlocksContent.greenContent {
    margin-top: 15px;
  }
  .stafOverviewBlocksContent.dangerContent {
    margin-bottom: 20px;
  }
}

.taskDetail {
  height: calc(100% - 20px);
}

.holidaysWrapper .tabsWrapper .tabsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-end;
  flex-wrap: nowrap;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  flex-direction: row-reverse;
  padding: 10px 15px 0 0;
  border-radius: 10px 10px 0 0;
  margin-bottom: 10px;
  font-size: 14px;
}
.holidaysWrapper .tabsWrapper .tabsHeader .nav.nav-tabs {
  border-bottom: 0;
}
.holidaysWrapper .tabsWrapper .tabsHeader .nav.nav-tabs .nav-link {
  border: 0;
  color: #666;
}
.holidaysWrapper .tabsWrapper .tabsHeader .nav.nav-tabs .nav-link.active {
  color: #278dec;
  border-bottom: 3px solid #278dec;
}
.holidaysWrapper .tabsWrapper .tabsHeader .holidayHeaderStuff {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 8px;
}
.holidaysWrapper .tabsWrapper .tabsHeader .holidayHeaderStuff .actionButtons {
  margin-left: 10px;
}
.holidaysWrapper .stafOverviewBlocksContent {
  margin-bottom: 15px;
}
.holidaysWrapper .rdt_Table .rdt_TableHead .rdt_TableHeadRow .rdt_TableCol * {
  white-space: normal;
}
@media (max-width: 768px) {
  .holidaysWrapper .tabsWrapper .tabsHeader {
    display: block;
    padding-left: 15px;
  }
}

.absenceLeave h3 {
  margin: 10px 0 20px;
}
.absenceLeave div[role=rowgroup] div[role=row] div[role=cell]:nth-child(3) {
  padding-left: 0;
}
.absenceLeave div[role=rowgroup] div[role=row] div[role=cell] .tableText {
  padding: 16px 15px;
}

.staffRecordsGroup div[role=rowgroup] div[role=row] div[role=cell]:nth-child(3) {
  padding-left: 0;
}
.staffRecordsGroup div[role=rowgroup] div[role=row] div[role=cell] .tableText {
  padding: 16px 15px;
}

.staffRecordsBlocks .clearfix {
  height: 0;
}

.staffRecordDetailWrapper .headerContainerSearchAction {
  margin-bottom: 0;
  border-radius: 10px 10px 0 0;
}

.customTabsWrapper .tabsWrapper ::-webkit-scrollbar:horizontal {
  background: #ffffff;
}
.customTabsWrapper .tabsWrapper .tabsHeader {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  overflow-x: auto;
  border-radius: 0 !important;
  border-top: 1px solid rgba(193, 195, 200, 0.5);
  margin-bottom: 15px;
}
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs {
  min-width: 985px;
  border-bottom: 0;
  padding: 8px;
}
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs .nav-link,
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs a {
  font-size: 14px;
  padding: 6px 15px;
  color: #666;
  border: 0;
  border-radius: 5px;
  display: block;
}
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs .nav-link.active,
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs a.active {
  background: rgba(99, 180, 255, 0.3);
  color: #278dec;
}
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs .nav-link:hover,
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs a:hover {
  border: 0;
  color: #278dec;
}

.complianceDocuemnts .contentBlock {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
  margin: 0 auto 25px;
  max-width: 300px;
}
.complianceDocuemnts .contentBlock .contentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.complianceDocuemnts .contentBlock .contentHeader .dropdown {
  text-align: right;
}
.complianceDocuemnts .contentBlock .contentHeader .dropdown .btn.btn-primary {
  background: url(../images/icons/menuToggle.svg) no-repeat center center transparent;
  transform: rotate(90deg);
  border: 0;
  height: 16px;
}
.complianceDocuemnts .contentBlock .contentHeader .dropdown .btn.btn-primary:after {
  display: none;
}
.complianceDocuemnts .contentBlock .contentHeader .dropdown.show .dropdown-menu.show a {
  padding: 8px 10px;
}
.complianceDocuemnts .contentBlock .contentHeader img {
  width: 40px;
  height: 40px;
  margin-top: -5px;
}
.complianceDocuemnts .contentBlock .contentHeader .statusButton {
  padding: 3px 15px;
}
.complianceDocuemnts .contentBlock .documentInfo h4 {
  font-weight: bold;
  font-size: 16px;
  margin: 10px 0;
}
.complianceDocuemnts .contentBlock .documentInfo p {
  margin: 0;
  color: #000000;
}
.complianceDocuemnts .contentBlock .documentInfo img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.eventAppointmentsPoup .event-details {
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
}

.eventAppointments {
  padding: 20px;
}
.eventAppointments .fc-header-toolbar .fc-toolbar-chunk div {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.eventAppointments .fc-header-toolbar .fc-toolbar-chunk div h2 {
  font-size: 20px;
  margin: 0 30px;
}
.eventAppointments .fc-header-toolbar .fc-toolbar-chunk div button {
  background: transparent;
  border: 0;
}
.eventAppointments .fc-header-toolbar .fc-toolbar-chunk div button span:before {
  color: #666;
}
.eventAppointments .fc-header-toolbar .fc-toolbar-chunk div button:hover, .eventAppointments .fc-header-toolbar .fc-toolbar-chunk div button:focus, .eventAppointments .fc-header-toolbar .fc-toolbar-chunk div button:active, .eventAppointments .fc-header-toolbar .fc-toolbar-chunk div button:active:focus {
  border: 0;
  background: transparent;
  box-shadow: unset;
}
.eventAppointments table tr th a {
  color: #333;
}
.eventAppointments table tr td {
  vertical-align: bottom !important;
}
.eventAppointments table tr td .fc-daygrid-day-number {
  color: #333;
}

.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover,
.fc-event-selected::after,
.fc-event:focus::after {
  background: transparent !important;
}

a.fc-daygrid-dot-event {
  display: block;
}

.fc .fc-daygrid-event {
  position: unset;
}

.eventAppointmentWrapper {
  position: unset;
  z-index: 1;
  padding: 10px;
}
.eventAppointmentWrapper .event-details {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.4);
  padding: 15px;
  position: absolute;
  z-index: 999;
  color: #666;
  font-size: 12px;
  transform: translate(-60px, -40%);
  width: 240px;
}
.eventAppointmentWrapper .event-details p {
  font-size: 12px;
}
.eventAppointmentWrapper .event-details header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.eventAppointmentWrapper .event-details header .heaerDetailContent {
  width: 75%;
}
.eventAppointmentWrapper .event-details header .heaerDetailContent .vitaminStatus {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.eventAppointmentWrapper .event-details header .heaerDetailContent .eventDetailName h4 {
  font-size: 17px;
  margin-bottom: 0;
}
.eventAppointmentWrapper .event-details .statusTag {
  text-transform: capitalize;
}
.eventAppointmentWrapper .event-details .statusTag small span {
  font-size: 11px !important;
}
.eventAppointmentWrapper .event-details .eventContent {
  padding-top: 10px;
}
.eventAppointmentWrapper .event-details .eventContent p {
  margin-bottom: 15px;
  font-size: 12px;
}
.eventAppointmentWrapper .event-details .eventContent p img {
  width: 20px;
  height: 20px;
  margin-top: -5px;
}
.eventAppointmentWrapper .event-details .eventContent p strong {
  color: rgba(51, 51, 51, 0.8);
}
.eventAppointmentWrapper .event-details .eventContent .usersNameInfo img {
  width: 25px;
  height: 25px;
}
.eventAppointmentWrapper .event-details .eventContent .usersNameInfo .userInfo .nameBlock {
  font-weight: bold;
  color: #333;
  font-size: 12px;
}
.eventAppointmentWrapper .event-details .eventContent .usersNameInfo .userInfo .infoBlock {
  font-weight: normal;
  font-size: 12px;
}
.eventAppointmentWrapper .event-details footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin-top: 20px;
}
.eventAppointmentWrapper .event-details footer p {
  font-size: 12px;
}
.eventAppointmentWrapper .event-details footer img {
  width: 25px;
  height: 25px;
}
.eventAppointmentWrapper .eventShortDetail {
  display: block;
}
.eventAppointmentWrapper .eventShortDetail p {
  margin-bottom: 0;
  font-size: 12px !important;
}
.eventAppointmentWrapper .eventShortDetail .viewDetailIcon {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  flex-wrap: nowrap;
}
.eventAppointmentWrapper .eventShortDetail .viewDetailIcon span {
  cursor: pointer;
  background: rgba(51, 51, 51, 0.1);
  border-radius: 50%;
  display: block;
  width: 25px;
  height: 25px;
  text-align: center;
  padding: 1px 0 0 0;
}
.eventAppointmentWrapper .eventShortDetail .viewDetailIcon span img {
  width: 20px;
  height: 20px;
}
.eventAppointmentWrapper .eventShortDetail .viewDetailTitle {
  font-size: 14px !important;
}
.mainContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding: 15px 20px 10px;
}
.mainContentHeader h3 {
  margin: 0;
}
@media (max-width: 575px) {
  .mainContentHeader {
    display: block;
  }
  .mainContentHeader .actionButtons {
    margin-top: 10px;
  }
}

.questionWrapper {
  padding: 10px 20px;
  position: relative;
}
.questionWrapper .usersNameInfo .userImage img {
  width: 45px;
  height: 45px;
}
.questionWrapper .usersNameInfo .userInfo .nameBlock {
  font-weight: 500;
}
.questionWrapper p {
  color: #333;
}
.questionWrapper .contentBox p {
  margin-top: 10px;
}
.questionWrapper .contentBox p small {
  font-weight: 400;
  margin-top: 0 !important;
}

.profileBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.profileBox .actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
}
.profileBox .actionButtons span {
  cursor: pointer;
  display: block;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  padding: 2px;
  text-align: center;
}
.profileBox .actionButtons span:hover {
  background: rgba(51, 51, 51, 0.2);
}
.profileBox .actionButtons span img {
  max-width: 100%;
}

.contentBox {
  position: relative;
}
.contentBox textarea {
  padding: 10px;
  border-radius: 10px;
  border: 2px solid #63b4ff;
  background: rgba(51, 51, 51, 0.05);
  resize: none;
}
.contentBox .editableActionButton {
  position: absolute;
  right: 10px;
  bottom: 40px;
}
.contentBox .editableActionButton span {
  cursor: pointer;
}

.mainWrapper .timeTableWrapper {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background: transparent;
}
.mainWrapper .timeTableWrapper .timeTableItem {
  margin-bottom: 30px;
}
.mainWrapper .timeTableWrapper .timeTableItem .doseScheduleTimeWrapper {
  margin-bottom: 30px;
}
.mainWrapper .timeTableWrapper .timeTableItem header {
  background: #ffffff;
  text-align: center;
  padding: 10px 0;
  font-weight: bold;
  position: relative;
  margin-top: 20px;
}
.mainWrapper .timeTableWrapper .timeTableItem header .preloader-container .preloader-spinner {
  width: 15px;
  height: 15px;
}
.mainWrapper .timeTableWrapper .timeTableItem header p {
  margin: 0;
  font-size: 16px;
}
.mainWrapper .timeTableWrapper .timeTableItem .scheduleType {
  margin-top: 20px;
}
.mainWrapper .timeTableWrapper .timeTableItem .scheduleType header {
  background: #46a37a;
}
.mainWrapper .timeTableWrapper .timeTableItem .scheduleType header p strong {
  color: #ffffff !important;
  text-transform: capitalize;
}
.mainWrapper .timeTableWrapper .timeTableItem .rangeWithButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 20px 10px;
}
.mainWrapper .timeTableWrapper .timeTableItem .rangeWithButtons .actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
@media (max-width: 575px) {
  .mainWrapper .timeTableWrapper .timeTableItem .rangeWithButtons {
    display: block;
  }
  .mainWrapper .timeTableWrapper .timeTableItem .rangeWithButtons .rangNavigation {
    max-width: 200px;
    margin: 10px auto;
  }
  .mainWrapper .timeTableWrapper .timeTableItem .rangeWithButtons .actionButtons {
    justify-content: center;
  }
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData {
  max-height: 250px;
  overflow-y: auto;
  overflow-x: auto;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData span.link {
  cursor: pointer;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table {
  font-size: 14px;
  min-width: 800px;
  margin: 0;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tr th,
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tr td {
  text-align: center;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tr th:nth-child(1),
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tr td:nth-child(1) {
  text-align: left;
  width: 240px;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table thead tr {
  border-bottom: 1px solid rgba(51, 51, 51, 0.2);
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table thead tr th {
  padding: 10px;
  font-weight: bold;
  position: sticky;
  top: -1px;
  z-index: 1;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table thead tr th:nth-child(1) {
  padding: 10px 20px;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tbody tr td {
  vertical-align: middle;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tbody tr td:nth-child(1) {
  position: static;
  font-weight: bold;
  padding: 10px 20px;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tbody tr td:nth-child(1) small {
  display: block;
  font-weight: normal;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tbody tr td .actionRow {
  position: relative;
  right: 0;
  top: 0;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tbody tr td span.doseType {
  text-transform: uppercase;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tbody tr td span.doseType:before {
  content: "-";
  margin: 0 5px;
}
.mainWrapper .timeTableWrapper .timeTableItem .medicineTableData table tbody tr td span.doseType:first-child:before {
  display: none;
}
.mainWrapper .timeTableWrapper .timeTableItem footer {
  background: #ffffff;
  padding: 20px 20px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}

.rangNavigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: stretch;
  flex-wrap: nowrap;
  gap: 5px;
}
.rangNavigation p {
  margin: 0;
}
.rangNavigation span {
  display: block;
  min-height: 20px;
  cursor: pointer;
}
.rangNavigation span img {
  width: 20px;
  height: 20px;
  vertical-align: top;
}
@media (max-width: 575px) {
  .rangNavigation .rangNavigation {
    max-width: 200px;
    margin: 10px auto;
  }
}

.medicineDetailPopup {
  position: absolute;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(51, 51, 51, 0.5);
  width: 550px;
  padding: 20px;
  margin-top: -50px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  z-index: 9;
}
.medicineDetailPopup .medDetail h3 {
  font-size: 16px;
}
.medicineDetailPopup .medDetail h3 small {
  font-size: 12px;
}
.medicineDetailPopup .medDetail p {
  font-size: 12px;
  font-weight: normal;
}
.medicineDetailPopup .medDetail p strong {
  display: block;
}
.medicineDetailPopup .medImage {
  border-left: 1px solid rgba(51, 51, 51, 0.2);
}
.medicineDetailPopup .medImage .closeButton {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}
.medicineDetailPopup .medImage img.medicImage {
  width: 200px;
  height: 150px;
}
@media (max-width: 768px) {
  .medicineDetailPopup {
    width: 100%;
    max-width: 250px;
    display: block;
  }
  .medicineDetailPopup .medImage {
    border-left: none;
  }
  .medicineDetailPopup .medImage img.medicImage {
    width: 100px;
    height: auto;
  }
}

.checkboxContainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
}
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.checkboxContainer .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 15px;
  width: 15px;
  background-color: #ffffff;
  border: 1px solid #278dec;
}
.checkboxContainer:hover input ~ .checkmark {
  background-color: #ccc;
}
.checkboxContainer input:checked ~ .checkmark {
  background-color: #278dec;
}
.checkboxContainer .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}
.checkboxContainer input:checked ~ .checkmark:after {
  display: block;
}
.checkboxContainer .checkmark:after {
  left: 4px;
  top: 2px;
  width: 5px;
  height: 9px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}
.checkboxContainer .labelText {
  line-height: 14px;
  position: relative;
  left: 20px;
}

.form-group .labelText {
  top: -10px;
}

.rosterPopup {
  padding: 10px 20px 0 !important;
  height: 500px;
  overflow-y: auto;
}
.rosterPopup table {
  border-left: 1px solid rgba(51, 51, 51, 0.1);
  border-right: 1px solid rgba(51, 51, 51, 0.1);
}
.rosterPopup table tr th,
.rosterPopup table tr td {
  font-size: 13px;
  padding: 10px 10px;
  border-width: 1px;
}
.rosterPopup table tr th .popupIcon,
.rosterPopup table tr td .popupIcon {
  float: right;
  cursor: pointer;
}
.rosterPopup table tr th:nth-child(1),
.rosterPopup table tr td:nth-child(1) {
  width: 40%;
}
.rosterPopup table tr th:nth-child(2),
.rosterPopup table tr td:nth-child(2) {
  width: 60%;
}
.rosterPopup table tr th span,
.rosterPopup table tr td span {
  padding: 0;
  font-size: 14px;
}
.rosterPopup .medicationPicture {
  border: 1px solid rgba(193, 195, 200, 0.5);
  border-radius: 5px;
  text-align: center;
}
.rosterPopup .medicationPicture img {
  max-width: 100%;
  max-height: 150px;
}

.checkboxList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
}
.checkboxList.spaceInCheckList {
  gap: 10px;
}
.checkboxList .checkboxContainer {
  width: 33.3333333333%;
  margin-bottom: 5px;
}
.checkboxList .checkboxContainer label {
  display: block;
  width: 100%;
  font-size: 13px;
}
.checkboxList.medicineCheckList {
  gap: 30px;
}

.subTabsWrapper .radioTabsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 20px 20px 15px;
}
.subTabsWrapper .radioTabsHeader .tabsBlocks {
  width: 33.3333333333%;
}
.subTabsWrapper .radioTabsHeader .tabsBlocks .rangNavigation {
  max-width: 210px;
  margin: 0 auto;
}
.subTabsWrapper .radioTabsHeader .nav-tabs {
  border: 0;
  gap: 20px;
}
.subTabsWrapper .radioTabsHeader .nav-tabs .nav-item .nav-link {
  border: 0;
  padding: 0 0 0 20px;
  margin: 0;
  background: url("../images/icons/radioUnTick.svg") left 3px no-repeat transparent;
  background-size: 14px;
  color: #666;
  font-size: 14px;
}
.subTabsWrapper .radioTabsHeader .nav-tabs .nav-item .nav-link.active {
  background: url("../images/icons/radioTick.svg") left 3px no-repeat transparent;
  background-size: 14px;
}
@media (max-width: 1024px) {
  .subTabsWrapper .radioTabsHeader .tabsBlocks {
    width: auto;
  }
}
@media (max-width: 768px) {
  .subTabsWrapper .radioTabsHeader {
    display: block;
  }
  .subTabsWrapper .radioTabsHeader .nav-tabs {
    justify-content: center;
  }
  .subTabsWrapper .radioTabsHeader .tabsBlocks {
    margin: 0 auto 20px;
    text-align: center;
  }
}

.colorStatus {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.colorStatus .colorStatusItem {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  font-size: 14px;
}
.colorStatus .color {
  width: 10px;
  height: 10px;
  display: block;
}
.colorStatus .color.green {
  background: #03b93f;
}
.colorStatus .color.red {
  background: #da0b0b;
}
.colorStatus .color.grey {
  background: #666;
}
@media (max-width: 768px) {
  .colorStatus {
    justify-content: center;
  }
}
@media (max-width: 480px) {
  .colorStatus {
    flex-wrap: wrap;
  }
}

.rosterTableContent table {
  width: 100%;
  margin: 0;
}
.rosterTableContent table tr th,
.rosterTableContent table tr td {
  width: 12%;
  padding: 5px;
  background: #f6f6f6;
  text-align: center;
  vertical-align: middle;
  font-size: 14px;
}
.rosterTableContent table tr th:nth-child(1),
.rosterTableContent table tr td:nth-child(1) {
  width: 16%;
  min-width: 180px;
  background: #ffffff;
  position: relative;
}
.rosterTableContent table tr th:nth-child(1) .staffCol,
.rosterTableContent table tr td:nth-child(1) .staffCol {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}
.rosterTableContent table tr th:nth-child(1) .staffCol img,
.rosterTableContent table tr td:nth-child(1) .staffCol img {
  width: 18px;
  height: 18px;
  cursor: pointer;
}
.rosterTableContent table tr .userInfoWrapper {
  padding: 10px 0;
}
.rosterTableContent table tr .contentcell {
  text-align: left;
  margin-bottom: 0;
}
.rosterTableContent table tr .shiftActionButtons {
  margin-bottom: 0;
  position: relative;
  top: -8px;
}
.rosterTableContent table tr .textDetail {
  cursor: pointer;
}
.rosterTableContent table tr .textDetail span,
.rosterTableContent table tr .textDetail strong,
.rosterTableContent table tr .textDetail small {
  display: block;
}
.rosterTableContent table tr .usersNameInfo {
  text-align: left;
  justify-content: center;
  cursor: pointer;
}
.rosterTableContent table tr .usersNameInfo span {
  font-weight: normal;
}
.rosterTableContent table tr .usersNameInfo .userInfo {
  font-weight: bold;
}
.rosterTableContent table thead tr th {
  background: #ffffff;
  padding: 10px;
}
@media (max-width: 1024px) {
  .rosterTableContent table {
    min-width: 1100px;
  }
}

.rosterShift .modal-dialog {
  width: 96%;
  max-width: 400px;
  margin: 0 auto;
}
.rosterShift .rosterShiftPopup {
  padding: 20px 0 0;
  font-size: 14px;
}
.rosterShift .rosterShiftPopup * {
  font-size: 12px;
}
.rosterShift .rosterShiftPopup textarea {
  min-height: 100px;
}
.rosterShift .rosterShiftPopup .timeTableBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: wrap;
  gap: 5px;
}
.rosterShift .rosterShiftPopup .timeTableBox .form-group {
  width: 33%;
  padding-right: 10px;
}
.rosterShift .rosterShiftPopup .timeTableBox .form-group:nth-child(3) {
  width: 30%;
}

.shiftHistory {
  height: calc(100vh - 250px);
  border: 1px solid rgba(193, 195, 200, 0.5);
  border-top: 0;
  border-bottom: 0;
}
.shiftHistory h3 {
  padding: 15px 15px 10px;
}
.shiftHistory table {
  width: 100%;
  font-size: 12px;
}
.shiftHistory table tr td {
  vertical-align: middle;
  border: 0;
}
.shiftHistory table tr td:nth-child(1) {
  width: 40%;
}
.shiftHistory table tr td:nth-child(2) {
  width: 60%;
  text-align: right;
}
.shiftHistory table tr td p {
  margin: 0;
  padding: 0;
}
.shiftHistory table tr td p small {
  margin-top: 0 !important;
}
@media (max-width: 768px) {
  .shiftHistory {
    padding: 20px;
  }
}

.mainContent.addNewShift {
  padding: 0 !important;
}
.mainContent.addNewShift header {
  padding: 20px;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.mainContent.addNewShift footer {
  background: #f8f9fb;
  padding: 20px;
}
.mainContent.addNewShift footer .formActionButtons {
  margin: 0;
}
.mainContent.addNewShift footer .btn {
  padding: 5px 15px;
}
.mainContent.addNewShift .addNewShiftForm {
  padding: 20px 0 20px 20px;
}
.mainContent.addNewShift .addNewShiftForm .hrs {
  font-size: 12px;
  text-align: right;
  margin: 0;
}
.mainContent.addNewShift .addNewShiftForm .form-group label.floatingLabel {
  left: 1px;
}
.mainContent.addNewShift .addNewShiftForm .form-group input:valid + label,
.mainContent.addNewShift .addNewShiftForm .form-group input:focus + label {
  left: 5px;
}
.mainContent.addNewShift .addNewShiftForm .col-lg-2.col-md-4.pr-0 input {
  width: 98%;
}
@media (max-width: 993px) {
  .mainContent.addNewShift .addNewShiftForm .pr-0,
  .mainContent.addNewShift .addNewShiftForm .pl-0 {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .mainContent.addNewShift .addNewShiftForm .col-lg-2.col-md-4.pr-0 input {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .mainContent.addNewShift .addNewShiftForm {
    padding: 20px;
  }
}

.personalAllowance {
  border-left: 1px solid rgba(193, 195, 200, 0.5);
  padding: 10px 10px 10px 20px;
}
.personalAllowance p {
  margin: 0;
  font-weight: bold;
}
.personalAllowance p span {
  color: #278dec;
}
@media (max-width: 768px) {
  .personalAllowance {
    border-left: 0;
    padding: 10px;
  }
}
.personalAllowance.generalNotes p {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 5px;
}

.careNotes {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.careNotes p.viewCareNotes {
  text-align: right;
  margin: 0;
  padding: 10px 10px 0;
}
.careNotes header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 15px 10px 5px;
}
.careNotes header h3 {
  padding: 0;
  margin: 0;
}
.careNotes header h3 small {
  margin-top: 5px;
  display: block;
}
.careNotes header .personalAllowance {
  padding: 0 3px 0 0;
  border: 0;
}
.careNotes h3 {
  font-size: 16px;
  padding: 0;
}
.careNotes .dataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol {
  padding: 10px;
}
.careNotes .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] {
  padding: 10px;
}
.careNotes .dataTableWrapper .maxWidthTable p {
  font-size: 13px;
  margin: 0;
}
@media (max-width: 768px) {
  .careNotes {
    margin-bottom: 20px;
  }
}

.careNotesPopup p {
  text-align: justify;
  margin: 20px 0;
  word-wrap: break-word;
}
.careNotesPopup p strong,
.careNotesPopup p small {
  display: block;
}
.careNotesPopup p.text-left {
  text-align: left !important;
}
.careNotesPopup.specificFormat p {
  margin: 0 0 20px;
}

.unReadMessages {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  margin-top: 10px;
}
.unReadMessages h3 {
  font-size: 16px;
  padding: 0;
}
.unReadMessages .dataTableWrapper .maxWidthTable .rdt_TableHeadRow .rdt_TableCol {
  display: none;
}
.unReadMessages .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] {
  padding: 0;
  border-left: 0 !important;
  border-bottom: 0 !important;
}
.unReadMessages .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] .usersNameInfo {
  padding: 10px 0 10px 10px;
  white-space: normal;
}
.unReadMessages .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] .usersNameInfo .nameBlock {
  font-weight: bold;
}
.unReadMessages .dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell] .usersNameInfo .paragraphBlock {
  font-weight: normal;
}
.unReadMessages .dataTableWrapper .maxWidthTable p {
  font-size: 13px;
  margin: 0;
}

.carePlanCalendar {
  font-size: 12px;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
}
.carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div h2 {
  font-size: 14px;
  margin: 0 30px;
}
.carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div button {
  background: transparent;
  border: 0;
}
.carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div button span:before {
  color: #666;
}
.carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div button:hover, .carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div button:focus, .carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div button:active, .carePlanCalendar .fc-header-toolbar .fc-toolbar-chunk div button:active:focus {
  border: 0;
  background: transparent;
  box-shadow: unset;
}
.carePlanCalendar .fc .fc-scroller-liquid-absolute {
  position: relative;
}
.carePlanCalendar .fc .fc-view-harness {
  position: relative;
  min-height: 250px;
  height: auto !important;
}
.carePlanCalendar .fc .fc-view-harness-active > .fc-view {
  position: relative;
}
.carePlanCalendar .fc .fc-col-header-cell-cushion {
  color: #c1c3c8;
  font-weight: normal;
}
.carePlanCalendar .fc .fc-toolbar.fc-header-toolbar {
  margin: 0;
  padding: 20px 0;
  width: 100%;
  display: block;
}
.carePlanCalendar .fc .fc-toolbar.fc-header-toolbar .fc-toolbar-chunk {
  width: 100%;
}
.carePlanCalendar .fc .fc-col-header-cell {
  padding: 10px 0;
}
.carePlanCalendar .fc .fc-daygrid-day-number {
  color: #333;
}
.carePlanCalendar .fc .fc-daygrid-day-top {
  justify-content: center;
  align-items: center;
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
}
.carePlanCalendar .fc .fc-daygrid-day.fc-day-today {
  background: transparent;
}
.carePlanCalendar .fc .fc-daygrid-day.fc-day-today .fc-daygrid-day-frame {
  outline: 1px solid #278dec;
  background: rgba(39, 141, 236, 0.2);
  top: 1px;
}
.carePlanCalendar .eventAppointmentWrapper {
  padding: 0;
}
.carePlanCalendar .eventAppointmentWrapper .viewDetailIcon {
  cursor: pointer;
}
.carePlanCalendar .eventAppointmentWrapper p {
  padding: 0;
  margin: 0;
}
.carePlanCalendar .todayAppointment {
  padding: 15px;
}
.carePlanCalendar .todayAppointment h4 {
  font-size: 16px;
  font-weight: bold;
  color: #278dec;
  margin-bottom: 5px;
}
.carePlanCalendar .todayAppointment p {
  font-size: 12px;
  margin-bottom: 0;
}
.carePlanCalendar .todayAppointment span {
  font-size: 12px;
  color: #278dec;
  font-weight: bold;
  cursor: pointer;
}
@media (max-width: 768px) {
  .carePlanCalendar {
    margin-bottom: 20px;
  }
}

.eventDetailPopup p {
  margin-bottom: 5px;
  border-bottom: 1px solid rgba(102, 102, 102, 0.3);
  padding: 10px 0;
  font-size: 13px;
}
.eventDetailPopup p strong {
  display: block;
}
.eventDetailPopup .usersNameInfo .userImage img {
  width: 40px !important;
  height: 40px !important;
}
.eventDetailPopup .usersNameInfo .userInfo span {
  font-size: 13px;
}

.carePlansBlocks .cards a {
  display: block;
  text-align: right;
}

header.memoryBoxContentHeader {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin: 10px 0;
}
header.memoryBoxContentHeader span {
  cursor: pointer;
}

.memoryBoxHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.memoryBoxContentWrapper {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px 20px 10px 10px;
  margin: 10px 0 20px;
  height: calc(100% - 20px);
}

.memoryBoxContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
}
.memoryBoxContent .imageBox {
  position: relative;
}
.memoryBoxContent .imageBox img {
  border-radius: 5px;
  width: 120px;
  height: 120px;
  margin: 0 auto;
}
.memoryBoxContent .imageBox .downloadIconButton {
  position: absolute;
  display: block;
  width: 25px;
  height: 25px;
  bottom: 2px;
  right: 2px;
  background: #278dec;
  border-radius: 50%;
  text-align: center;
  cursor: pointer;
}
.memoryBoxContent .imageBox .downloadIconButton img {
  max-width: 100%;
  position: relative;
  height: 23px;
  width: 23px;
  padding: 2px;
  filter: brightness(0) invert(1);
}
.memoryBoxContent .videoMemoryBox {
  max-width: 100%;
}
.memoryBoxContent .viewBox {
  background: #d8d9db;
  width: 120px;
  height: 120px;
}
.memoryBoxContent .viewBox .videoMemoryBox {
  width: 120px;
  height: 120px;
}
.memoryBoxContent .textBox {
  flex: 1;
}
.memoryBoxContent .textBox p {
  word-break: break-word;
}
@media (max-width: 480px) {
  .memoryBoxContent {
    display: block;
  }
  .memoryBoxContent .imageBox img {
    width: 80px;
    height: 80px;
    margin-bottom: 10px;
  }
  .memoryBoxContent .textBox {
    width: 100%;
    text-align: justify;
  }
}
.memoryBoxContent.profileVideoContent {
  justify-content: center;
}

.careplanOptions.scheduleBlocks .cards {
  padding: 30px 10px;
  cursor: pointer;
}
.careplanOptions.scheduleBlocks .cards img {
  margin-bottom: 20px;
}
.careplanOptions.scheduleBlocks .cards h3 {
  font-size: 18px;
}
@media (max-width: 768px) {
  .careplanOptions .headingTitle {
    text-align: center;
    font-size: 20px;
  }
}

.mainContent.youngInformation {
  padding: 0 !important;
}
.mainContent.youngInformation header {
  padding: 10px 20px;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.mainContent.youngInformation footer {
  background: #f8f9fb;
  padding: 20px;
}
.mainContent.youngInformation footer .formActionButtons {
  margin: 0;
}
.mainContent.youngInformation footer .btn {
  padding: 5px 15px;
}
.mainContent.youngInformation .youngInformationForm {
  padding: 20px;
}
.mainContent.youngInformation .youngInformationForm h3 {
  margin-bottom: 20px;
}
.mainContent.youngInformation .youngInformationForm table {
  min-width: 800px;
}
.mainContent.youngInformation .youngInformationForm .hrs {
  font-size: 12px;
  text-align: right;
  margin: 0;
}
.mainContent.youngInformation .youngInformationForm .form-group label.floatingLabel {
  left: 5px;
  top: -8px;
  padding: 0 5px;
}
.mainContent.youngInformation .youngInformationForm .form-group input:valid + label,
.mainContent.youngInformation .youngInformationForm .form-group input:focus + label {
  left: 5px;
}
.mainContent.youngInformation .youngInformationForm .col-lg-2.col-md-4.pr-0 input {
  width: 98%;
}
@media (max-width: 993px) {
  .mainContent.youngInformation .youngInformationForm .pr-0,
  .mainContent.youngInformation .youngInformationForm .pl-0 {
    padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
    padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
  }
  .mainContent.youngInformation .youngInformationForm .col-lg-2.col-md-4.pr-0 input {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .mainContent.youngInformation .youngInformationForm {
    padding: 20px;
  }
}

.messageWrapper .messageDropDownButton button.dropdown-toggle {
  background: transparent;
  border: 0;
  padding: 0;
  height: 26px;
}
.messageWrapper .messageDropDownButton button.dropdown-toggle:after {
  display: none;
}

.messageContainer {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  max-height: calc(100vh - 180px);
}
.messageContainer .nameSpace {
  width: 25%;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 20px 0;
  padding: 0;
}
.messageContainer .nameSpace .userBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 75px;
  border-bottom: 1px solid #c1c3c8;
  padding: 0;
}
.messageContainer .nameSpace .userBox .usersNameInfo {
  padding: 10px 15px;
  position: relative;
  width: 94%;
  gap: 5px;
}
.messageContainer .nameSpace .userBox .usersNameInfo .userInfo {
  width: 84%;
}
.messageContainer .nameSpace .userBox .usersNameInfo .userImage {
  width: 16%;
}
.messageContainer .nameSpace .userBox .usersNameInfo .dayTime {
  right: 20px;
}
.messageContainer .nameSpace .userBox .usersNameInfo .nameBlock {
  font-weight: bold;
}
.messageContainer .nameSpace .userBox .usersNameInfo .paragraphBlock {
  font-weight: normal;
}
.messageContainer .nameSpace .userBox .userAction {
  height: 100%;
  width: 6%;
  background: rgba(255, 242, 242, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.messageContainer .nameSpace .userBox:hover, .messageContainer .nameSpace .userBox.active, .messageContainer .nameSpace .userBox.activeChat {
  cursor: pointer;
  background: rgba(99, 180, 255, 0.2);
}
.messageContainer .nameSpace .userBox:hover .userAction, .messageContainer .nameSpace .userBox.active .userAction, .messageContainer .nameSpace .userBox.activeChat .userAction {
  opacity: 1;
}
@media (max-width: 1200px) {
  .messageContainer .nameSpace {
    display: none;
  }
}
.messageContainer .mobileNameSpace {
  width: 25%;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 20px 0;
}
.messageContainer .mobileNameSpace .userBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 75px;
  border-bottom: 1px solid #c1c3c8;
  padding: 0;
}
.messageContainer .mobileNameSpace .userBox .usersNameInfo {
  padding: 10px 15px;
  position: relative;
  width: 94%;
  gap: 5px;
}
.messageContainer .mobileNameSpace .userBox .usersNameInfo .userInfo {
  width: 84%;
}
.messageContainer .mobileNameSpace .userBox .usersNameInfo .userImage {
  width: 16%;
}
.messageContainer .mobileNameSpace .userBox .usersNameInfo .dayTime {
  right: 20px;
}
.messageContainer .mobileNameSpace .userBox .usersNameInfo .nameBlock {
  font-weight: bold;
}
.messageContainer .mobileNameSpace .userBox .usersNameInfo .paragraphBlock {
  font-weight: normal;
}
.messageContainer .mobileNameSpace .userBox .userAction {
  height: 100%;
  width: 6%;
  background: rgba(255, 242, 242, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.messageContainer .mobileNameSpace .userBox:hover, .messageContainer .mobileNameSpace .userBox.active, .messageContainer .mobileNameSpace .userBox.activeChat {
  cursor: pointer;
  background: rgba(99, 180, 255, 0.2);
}
.messageContainer .mobileNameSpace .userBox:hover .userAction, .messageContainer .mobileNameSpace .userBox.active .userAction, .messageContainer .mobileNameSpace .userBox.activeChat .userAction {
  opacity: 1;
}
.messageContainer .roomSpace {
  flex: 1;
  border-left: 2px solid #f6f6f6;
}
.messageContainer .roomSpace header {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding: 15px;
}
.messageContainer .roomSpace .usersNameInfo .top {
  margin-left: auto;
  background: none;
}
.messageContainer .roomSpace .usersNameInfo .top .messageDropDownButton button {
  border-radius: 0;
  height: auto;
}
.messageContainer .roomSpace .usersNameInfo .top .messageDropDownButton button img {
  width: 20px;
  height: 20px;
  border-radius: 0;
  background: transparent;
}
.messageContainer .roomSpace .messagesPanel {
  font-size: 12px;
  padding: 10px 25px;
  margin: 10px;
  max-height: calc(100vh - 330px);
  overflow-y: auto;
  border-radius: 5px;
}
.messageContainer .roomSpace .messagesPanel .messageDropDownButton button.dropdown-toggle img {
  width: 90%;
}
.messageContainer .roomSpace .messagesPanel .messageBoxWrapper {
  margin: 10px 0;
}
.messageContainer .roomSpace .messagesPanel .messageBoxWrapper.receiverWrapper .time {
  text-align: right;
}
.messageContainer .roomSpace .messagesPanel .attachmentMessage img {
  width: 150px;
}
.messageContainer .roomSpace .messagesPanel .attachment {
  padding: 10px 10px !important;
}
.messageContainer .roomSpace .messagesPanel .attachment a img.icon {
  width: 80px;
}
.messageContainer .roomSpace .messagesPanel .senderMessage {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  max-width: 50%;
}
.messageContainer .roomSpace .messagesPanel .senderMessage .textMessage {
  background: rgba(193, 195, 200, 0.5);
  border-radius: 10px 10px 10px 0;
  padding: 10px 15px;
  color: #333;
  max-width: 97%;
  min-width: 200px;
}
.messageContainer .roomSpace .messagesPanel .senderMessage .textMessage .time {
  text-align: right;
  font-size: 8px;
}
@media (max-width: 993px) {
  .messageContainer .roomSpace .messagesPanel .senderMessage {
    max-width: 100%;
  }
}
.messageContainer .roomSpace .messagesPanel .recieverMessageBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
}
.messageContainer .roomSpace .messagesPanel .receiverMessage {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  max-width: 50%;
}
.messageContainer .roomSpace .messagesPanel .receiverMessage .textMessage {
  background: #278dec;
  color: #ffffff;
  border-radius: 10px 10px 0 10px;
  padding: 10px 15px;
  max-width: 97%;
  min-width: 200px;
}
.messageContainer .roomSpace .messagesPanel .receiverMessage .textMessage img.icon {
  filter: brightness(0) saturate(100%) invert(99%) sepia(54%) saturate(48%) hue-rotate(16deg) brightness(125%) contrast(100%);
}
.messageContainer .roomSpace .messagesPanel .receiverMessage .textMessage .time {
  text-align: right;
  color: #ffffff;
  font-size: 8px;
}
@media (max-width: 993px) {
  .messageContainer .roomSpace .messagesPanel .receiverMessage {
    max-width: 100%;
  }
}
.messageContainer .roomSpace .messagesPanel .time {
  color: #666;
  width: 100%;
}
.messageContainer .roomSpace .messagesActions {
  background: #f6f6f6;
  padding: 5px;
  top: 4px;
  position: relative;
}
.messageContainer .roomSpace .messagesActions footer {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  padding: 10px;
  gap: 5px;
}
.messageContainer .roomSpace .messagesActions footer .textMessage {
  flex: 1;
}
.messageContainer .roomSpace .messagesActions footer .textMessage input,
.messageContainer .roomSpace .messagesActions footer .textMessage textarea {
  width: 100%;
  padding: 8px 5px;
  border: 0;
  height: 35px;
  margin: 7px 0 0;
}
.messageContainer .roomSpace .messagesActions footer .emoji,
.messageContainer .roomSpace .messagesActions footer .attachment {
  width: 25px;
}
.messageContainer .roomSpace .messagesActions footer .attachment {
  position: relative;
  width: 24px;
  height: 40px;
  display: flex;
  align-items: center;
}
.messageContainer .roomSpace .messagesActions footer .attachment input[type=file] {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.messageContainer .roomSpace .messagesActions footer .submit {
  width: 30px;
}
.messageContainer .roomSpace .messagesActions footer .submit button {
  background: #278dec;
  border: 0;
  height: 30px;
  width: 30px;
  border-radius: 5px;
  padding: 0;
  text-align: center;
}
.messageContainer .roomSpace .messagesActions footer .submit button img {
  margin: 0 auto;
}
.messageContainer .emptyChatRoom {
  min-height: calc(100vh - 200px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.messageContainer .emptyChatRoom header {
  border: 0;
  width: 100%;
  display: none;
}
@media (max-width: 1200px) {
  .messageContainer .emptyChatRoom header {
    display: block;
  }
}
.messageContainer .emptyChatRoom .chatBoxContainer {
  text-align: center;
}
.messageContainer .emptyChatRoom .chatBoxContainer img {
  width: 200px;
  opacity: 0.5;
  margin: 0 auto 10px;
  filter: grayscale(1);
}
.messageContainer .emptyChatRoom .chatBoxContainer p {
  font-style: italic;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace {
  width: 25%;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 20px 0;
  width: 50%;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  height: 75px;
  border-bottom: 1px solid #c1c3c8;
  padding: 0;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox .usersNameInfo {
  padding: 10px 15px;
  position: relative;
  width: 94%;
  gap: 5px;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox .usersNameInfo .userInfo {
  width: 84%;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox .usersNameInfo .userImage {
  width: 16%;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox .usersNameInfo .dayTime {
  right: 20px;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox .usersNameInfo .nameBlock {
  font-weight: bold;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox .usersNameInfo .paragraphBlock {
  font-weight: normal;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox .userAction {
  height: 100%;
  width: 6%;
  background: rgba(255, 242, 242, 0.5);
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox:hover, .messageContainer.responsiveMessageContainer .mobileNameSpace .userBox.active, .messageContainer.responsiveMessageContainer .mobileNameSpace .userBox.activeChat {
  cursor: pointer;
  background: rgba(99, 180, 255, 0.2);
}
.messageContainer.responsiveMessageContainer .mobileNameSpace .userBox:hover .userAction, .messageContainer.responsiveMessageContainer .mobileNameSpace .userBox.active .userAction, .messageContainer.responsiveMessageContainer .mobileNameSpace .userBox.activeChat .userAction {
  opacity: 1;
}
@media (max-width: 575px) {
  .messageContainer.responsiveMessageContainer .mobileNameSpace {
    width: 88%;
  }
  .messageContainer.responsiveMessageContainer .roomSpace {
    width: 10%;
  }
  .messageContainer.responsiveMessageContainer .roomSpace .messagesPanel,
  .messageContainer.responsiveMessageContainer .roomSpace .messagesActions,
  .messageContainer.responsiveMessageContainer .roomSpace .userImage,
  .messageContainer.responsiveMessageContainer .roomSpace .userInfo {
    display: none;
  }
}

.requestContentCards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 1.5%;
}
.requestContentCards .cards {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  flex: 0 0 32.2%;
  padding: 20px;
  margin-bottom: 20px;
}
.requestContentCards .cards .cardsUserDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin-bottom: 10px;
}
.requestContentCards .cards .cardsUserDetail .statusButton {
  margin-right: auto;
}
.requestContentCards .cards .cardsUserDetail .editIcon {
  background-size: 25px;
  width: 25px;
  height: 25px;
}
.requestContentCards .cards .cardsUserDetail .nameBlock {
  font-weight: bold;
}
.requestContentCards .cards .cardsUserDetail .infoBlock {
  font-weight: normal;
}
.requestContentCards .cards .commentBox {
  min-height: 70px;
  padding-top: 10px;
}
@media (max-width: 993px) {
  .requestContentCards .cards .commentBox {
    min-height: 70px;
  }
}
@media (max-width: 575px) {
  .requestContentCards .cards .commentBox {
    min-height: auto;
  }
}
.requestContentCards .cards .commentBox p {
  font-size: 13px;
}
.requestContentCards .cards p {
  margin: 0;
}
.requestContentCards .cards p small {
  display: block;
  margin-top: 10px;
}
.requestContentCards .cards .delIcon {
  position: relative;
  right: -10px;
  cursor: pointer;
}
@media (max-width: 768px) {
  .requestContentCards .cards {
    flex: 0 0 49%;
  }
}
@media (max-width: 575px) {
  .requestContentCards .cards {
    flex: 0 0 100%;
  }
}
.requestContentCards.assignUsersContentCards .cards {
  height: 225px;
  max-width: 32.33%;
}
.requestContentCards.assignUsersContentCards .cards .commentBox {
  padding-top: 0;
}
.requestContentCards.assignUsersContentCards .cards .commentBox header.assignUser {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: 10px;
  padding: 10px 0;
  border-top: 1px solid rgba(193, 195, 200, 0.5);
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  max-width: 100%;
  overflow-x: auto;
}
.requestContentCards .accordion {
  width: 100%;
}
.requestContentCards p.borderBottom {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding: 20px 0;
  margin: 0;
  width: 100%;
}

.addNewRequestSU .mainContentHeader .actionButtons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}

.confirmationPopup .modal-dialog {
  max-width: 400px;
}
.confirmationPopup.deletionConfirmation .modal-dialog .modal-content .modal-header {
  background: #da0b0b;
}
.confirmationPopup.deletionConfirmation .modal-dialog .modal-content .modal-body .editBlockContent {
  padding: 20px 0;
}
.confirmationPopup.deletionConfirmation .modal-dialog .modal-content .modal-body .editBlockContent p {
  margin: 10px 0;
}
.confirmationPopup.deletionConfirmation .modal-dialog .modal-content .modal-body .editBlockContent img {
  width: 30px;
}
.confirmationPopup.deletionConfirmation .modal-dialog .modal-content .modal-footer {
  justify-content: center;
}
.confirmationPopup.deletionConfirmation.restoreConfirmation .modal-dialog .modal-content .modal-header {
  background: #03b93f;
}

.reviewsContentCards {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 20px 0;
  gap: 0;
  position: relative;
}
.reviewsContentCards .cards {
  background: #ffffff;
  flex: 0 0 25%;
  padding: 15px 20px 10px;
  border-right: 1px solid rgba(193, 195, 200, 0.5);
}
.reviewsContentCards .cards:nth-child(4n) {
  border-right: 0;
}
.reviewsContentCards .cards a {
  font-size: 12px;
}
.reviewsContentCards .cards .cardsUserDetail {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  margin-bottom: 0;
}
.reviewsContentCards .cards .cardsUserDetail .nameBlock {
  font-weight: bold;
}
.reviewsContentCards .cards .cardsUserDetail .infoBlock {
  font-weight: normal;
}
@media (max-width: 993px) {
  .reviewsContentCards .cards {
    flex: 0 0 33%;
  }
  .reviewsContentCards .cards:nth-child(4n) {
    border-right: 1px solid rgba(193, 195, 200, 0.5);
  }
  .reviewsContentCards .cards:nth-child(3n) {
    border-right: 0;
  }
}
@media (max-width: 768px) {
  .reviewsContentCards .cards {
    flex: 0 0 49%;
  }
  .reviewsContentCards .cards:nth-child(3n) {
    border-right: 1px solid rgba(193, 195, 200, 0.5);
  }
  .reviewsContentCards .cards:nth-child(2n) {
    border-right: 0;
  }
}
@media (max-width: 575px) {
  .reviewsContentCards .cards {
    flex: 0 0 100%;
    border-right: 0 !important;
  }
}

.reviewContent .questionWrapper {
  padding: 20px 0;
}
.reviewContent .profileBox {
  justify-content: flex-start;
  align-items: flex-start;
  gap: 20px;
  margin-bottom: 20px;
  padding: 10px 20px;
}
.reviewContent .profileBox:nth-child(odd) {
  background: #f6f6f6;
}
.reviewContent .contentBox {
  flex: 1;
}
.reviewContent .contentBox .nameBlock {
  font-weight: bold;
}
.reviewContent .contentBox p {
  margin: 0;
}
.reviewContent .contentBox .editableActionButton {
  bottom: 0;
}
.reviewContent .requestContentCards {
  display: block;
}
@media (max-width: 768px) {
  .reviewContent .profileBox {
    display: block;
  }
  .reviewContent .contentBox .editableActionButton {
    position: relative;
    right: 0;
    bottom: 0;
  }
}
.reviewContent .reviewFormWizard {
  padding: 20px;
}
.reviewContent .reviewFormWizard header {
  margin-bottom: 30px;
  padding: 0 0 20px 0;
}
.reviewContent.userDetailContainer .wizardSteps p {
  padding: 0 20px;
}
.reviewContent.userDetailContainer .wizardSteps .supportAbleArea {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 30px;
  margin-top: 20px;
}
.reviewContent.userDetailContainer .wizardSteps .supportAbleArea li {
  color: #666;
}
.reviewContent.userDetailContainer .wizardSteps table.dynamicRows {
  width: calc(100% - 40px);
  min-width: 575px;
  margin: 10px auto;
}
.reviewContent header.mainContentHeader {
  width: calc(100% - 40px);
  margin: 0 0 !important;
  padding: 15px 20px 10px;
  border: 0;
}

.reviewContent header.mainContentHeader {
  padding: 15px 0 10px !important;
}
.reviewContent header.viewContentHeader {
  padding: 15px 20px 10px !important;
}

.userDetailReview {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 20px;
  padding: 20px 0;
}
.userDetailReview .userImage img {
  width: 150px;
  height: 150px;
  background: rgba(193, 195, 200, 0.5);
  border-radius: 50%;
}
.userDetailReview .userImage .avatarAction {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  position: relative;
  top: -20px;
}
.userDetailReview .userImage .avatarAction img {
  border: 1px solid #ffffff;
  width: 30px;
  height: 30px;
}
.userDetailReview .userImage .avatarAction span {
  cursor: pointer;
}
.userDetailReview .detailTable {
  flex-grow: 1;
}
.userDetailReview .detailTable .currentStatus {
  justify-content: flex-start;
}
.userDetailReview .detailTable header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.userDetailReview .detailTable h3 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}
.userDetailReview .detailTable h3 small {
  font-size: 75%;
}
.userDetailReview .detailTable .grid-container {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
}
.userDetailReview .detailTable .grid-container .grid-item p {
  margin: 0;
}
@media (max-width: 993px) {
  .userDetailReview .detailTable .grid-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media (max-width: 575px) {
  .userDetailReview .detailTable .grid-container {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media (max-width: 400px) {
  .userDetailReview .detailTable .grid-container {
    grid-template-columns: repeat(1, 1fr);
  }
}
@media (max-width: 993px) {
  .userDetailReview {
    display: block;
  }
  .userDetailReview .userImage img {
    margin-bottom: 20px;
  }
}

.upload-icon {
  margin-bottom: 20px;
}
.upload-icon .avatarAction {
  position: relative;
}
.upload-icon .avatarAction .uploadImage {
  position: absolute;
  right: 0;
  left: 0;
  background: rgba(39, 141, 236, 0.2);
  border-radius: 50%;
  height: 150px;
  width: 150px;
  cursor: pointer;
  opacity: 0;
}
.upload-icon .avatarAction .uploadImage img {
  width: 30px !important;
  background: none !important;
}
.upload-icon .avatarAction:hover .uploadImage {
  opacity: 1;
}
.upload-icon img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background: rgba(193, 195, 200, 0.5);
}

.userTitle {
  font-size: 22px;
  margin-bottom: 20px;
}
.userTitle small {
  display: block;
}
.userTitle span {
  font-size: 14px;
  margin-top: 10px;
}

.userProfile {
  position: relative;
  min-height: 150px;
}

.currentServiceUser {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 25px;
  position: relative;
}
.currentServiceUser header {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 0 10px 0;
  margin-bottom: 5px;
}
.currentServiceUser header h3 {
  font-size: 18px;
  padding: 0 0 0;
  margin: 0;
}
.currentServiceUser header a {
  font-size: 12px;
  color: #278dec;
}
.currentServiceUser .reviewsContentCards {
  max-height: 280px;
  overflow-y: auto;
}
.currentServiceUser .reviewsContentCards .cards {
  padding: 20px 20px 20px 10px;
}
.currentServiceUser .reviewsContentCards .link {
  color: #278dec;
  font-size: 12px;
  cursor: pointer;
}

.reviewsContentCards .link {
  color: #278dec;
  font-size: 12px;
  cursor: pointer;
}

.newNpastServiceUser .reviewsContentCards .cards {
  flex: 0 0 50%;
}
@media (max-width: 575px) {
  .newNpastServiceUser .reviewsContentCards .cards {
    flex: 0 0 100%;
  }
}
.newNpastServiceUser .reviewsContentCards .cards:nth-child(2n) {
  border: 0;
}

.statusButton {
  display: block;
  padding: 8px 20px;
  min-width: 120px;
  text-align: center;
  font-size: 12px;
}

.background-success {
  background: #c8ffd9;
  color: #46a37a;
  border-color: #c8ffd9;
}

.background-warning {
  background: #ffebb7;
  color: #faa308;
  border-color: #ffebb7;
}

.background-danger {
  background: #f9bfbf;
  color: #da0b0b !important;
  border-color: #f9bfbf;
}

.background-danger-inactive {
  background: #f9bfbf;
  color: #da0b0b !important;
  border-color: #f9bfbf;
}

.conditionalTextStatus {
  padding: 8px 20px;
  border-radius: 20px;
}

.addHolidayWrapper {
  padding: 20px 0 0;
}

.customSelectBox {
  font-size: 13px;
  font-weight: normal;
}
.customSelectBox div[aria-selected=true] {
  color: #ffffff !important;
}
.customSelectBox .optionsRow {
  border-bottom: 1px solid rgba(51, 51, 51, 0.05);
  font-size: 13px;
  font-weight: normal;
  cursor: pointer;
  padding: 5px;
}
.customSelectBox .optionsRow:hover {
  background: rgba(99, 180, 255, 0.2);
}
.customSelectBox .optionsRow img {
  margin: 0 5px 0 0;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(193, 195, 200, 0.5);
}
.customSelectBox.validationError input {
  border-color: transparent !important;
}

.chipBlockWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 30px;
  gap: 20px;
}
.chipBlockWrapper .chipBlock {
  background: #d8d9db;
  border-radius: 5px;
  font-size: 12px;
  padding: 5px 10px;
}
.chipBlockWrapper .chipBlock .deleteChip {
  position: relative;
  top: -15px;
  right: -15px;
  cursor: pointer;
}
.chipBlockWrapper .chipBlock .deleteChip img {
  width: 20px;
  height: 20px;
}

.rotaContentCell {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px 15px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 3px;
  position: relative;
  min-width: 175px;
}
.rotaContentCell span {
  font-size: 12px;
  color: #666;
}
.rotaContentCell span.time {
  font-weight: bold;
  color: #000000;
}
.rotaContentCell span.plusIcon {
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-18px);
  cursor: pointer;
}
.rotaContentCell span.plusIcon img {
  width: 18px;
  height: 18px;
}
.rotaContentCell:nth-child(1) {
  margin-top: 0;
}
.rotaContentCell.updatedRotaContentCell span {
  font-size: 11px;
}
.rotaContentCell.updatedRotaContentCell .sleepOver-icon {
  left: 0;
}
.rotaContentCell.updatedRotaContentCell .sleepOver-icon img {
  width: 8px;
}

.updatedRotaContentCellWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  border-radius: 5px;
}
.updatedRotaContentCellWrapper .rotaContentCell {
  background: transparent;
  box-shadow: none;
  border-radius: 0;
  margin: 0;
  gap: 0;
}
.updatedRotaContentCellWrapper .updateCellIcon {
  padding: 0 3px;
}
.updatedRotaContentCellWrapper .updateCellIcon .icons {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 15px;
}
.updatedRotaContentCellWrapper .updateCellIcon .icons img {
  width: 10px;
  height: 10px;
}

.rotaPlusCell .plusIcon {
  position: relative;
  cursor: pointer;
}

.actionRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
}
.actionRow span {
  cursor: pointer;
}
.actionRow span img {
  width: 25px;
  height: 25px;
}
.actionRow span.view {
  background: rgba(51, 51, 51, 0.1);
  border-radius: 50%;
}

.leaveHolidayCell {
  padding: 0 !important;
  border-left: 3px solid #da0b0b !important;
  background: #fff2f2 !important;
}
.leaveHolidayCell.unAssignedShift {
  border-left: 0 !important;
}
.leaveHolidayCell .redcell {
  color: #da0b0b;
  font-weight: bold;
}

.linkingCell {
  position: relative;
}
.linkingCell .locationDetail {
  position: absolute;
  right: 3px;
  top: 3px;
}
.toolTipLeftAlign * {
  text-align: left !important;
}

.scheduleBlocksHead {
  background: #ffffff;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 5px;
  padding: 15px 10px;
  color: #000000;
  border-radius: 5px 5px 0 0;
}

.rotaTableContent table tr td.unAssignedShift {
  height: 125px;
}

.addShiftWrapper {
  padding: 20px 0;
}

.css-1p3m7a8-multiValue {
  background: rgba(99, 180, 255, 0.3) !important;
  border: 0;
  border-radius: 10px !important;
  color: #da0b0b;
  padding: 0 0 0 5px !important;
  font-size: 13px !important;
}
.css-1p3m7a8-multiValue .css-wsp0cs-MultiValueGeneric {
  color: #278dec;
}
.css-1p3m7a8-multiValue .css-qbdosj-Input {
  font-size: 13px;
  color: #da0b0b !important;
}
.css-1p3m7a8-multiValue .css-12a83d4-MultiValueRemove svg {
  filter: invert(22%) sepia(36%) saturate(7135%) hue-rotate(351deg) brightness(100%) contrast(135%);
  width: 13px;
  height: 13px;
}

.css-1jqq78o-placeholder {
  font-size: 13px !important;
}

.css-1u9des2-indicatorSeparator {
  display: none;
}

.css-1xc3v61-indicatorContainer {
  padding: 0;
}

.css-tj5bde-Svg {
  width: 18px;
  height: 18px;
  filter: invert(17%) sepia(0%) saturate(1%) hue-rotate(347deg) brightness(89%) contrast(87%);
}

.multiSelectCustomLabel {
  color: #777d80;
  top: 10px;
  left: 5px !important;
  font-size: 12px;
  padding: 0 5px;
  position: relative;
  z-index: 9;
  background: #ffffff;
}

.checkList .checkboxContainer {
  font-size: 13px;
}
.checkList .checkboxContainer .labelText {
  top: 0;
}
.checkList .checkboxContainer .checkmark {
  left: 5px;
}

.individualStaffSchedule {
  padding: 15px 0 0 !important;
  margin-bottom: 15px;
}
.individualStaffSchedule h3 {
  padding-left: 15px;
}
.individualStaffSchedule .form-group {
  margin: 0;
}
.individualStaffSchedule table {
  width: 100%;
  margin: 0;
}
.individualStaffSchedule table tr td,
.individualStaffSchedule table tr th {
  width: 14.28%;
  vertical-align: middle;
  text-align: center;
}
.individualStaffSchedule table tr td {
  padding-top: 15px;
  padding-bottom: 15px;
}
.individualStaffSchedule table tr td.unAssignedShiftaDay {
  background: #fff2f2;
}
.individualStaffSchedule table tr td.unAssignedShiftaDay .checkList .labelText {
  display: none;
}
.individualStaffSchedule table tr td.unAssignedShiftaDay .checkList .checkmark {
  left: 50%;
  transform: translateX(-8px);
  border-color: #777d80;
}
.individualStaffSchedule.updateRotaSchedule table tr td {
  padding: 5px;
}
.individualStaffSchedule.updateRotaSchedule table tr td .usersNameInfo {
  align-items: center;
}
.individualStaffSchedule.updateRotaSchedule table tr td .usersNameInfo .userImage img {
  width: 36px;
  height: 36px;
  margin-bottom: 0;
}
.individualStaffSchedule.updateRotaSchedule table tr td .usersNameInfo span {
  line-height: normal;
}
.individualStaffSchedule.updateRotaSchedule table tr td .usersNameInfo span.nameBlock {
  margin-bottom: 5px;
  font-weight: 600;
}
.individualStaffSchedule.updateRotaSchedule table tr td .usersNameInfo span.actionRows {
  gap: 5px;
}
.individualStaffSchedule.updateRotaSchedule table tr td .usersNameInfo span.actionRows span img {
  margin: 0;
  width: 18px;
  height: 18px;
  background: transparent;
}
.individualStaffSchedule.updateRotaSchedule table tr td.unAssignedShiftaDay {
  padding: 20px 20px;
}

.hGcwUo {
  background: none !important;
}

.noRecordFound {
  background: #ffffff;
  padding: 30px 0;
  text-align: center;
  font-size: 18px;
  color: #666;
  width: 100%;
}

.commentColumn {
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
  padding: 0;
}
.commentColumn .messageContainer {
  box-shadow: none;
}
.commentColumn .messageContainer .roomSpace .messagesPanel {
  padding: 0 5px 0 0;
  min-height: calc(100vh - 270px);
  height: calc(100vh - 270px);
}
.commentColumn .messageContainer .roomSpace .messagesPanel .recieverMessageBox .receiverMessage {
  width: 100%;
  max-width: 100%;
  justify-content: flex-start;
}
.commentColumn .messageContainer .roomSpace .messagesPanel .recieverMessageBox .receiverMessage .textMessage {
  flex-grow: 1;
  flex-basis: 0;
  max-width: 100%;
  background: rgba(193, 195, 200, 0.1);
  padding: 5px;
  border-radius: 0;
  text-align: justify;
  color: #000000;
}
.commentColumn .messageContainer .roomSpace .messagesActions {
  background: #ffffff;
}
.commentColumn h6.heading {
  padding: 10px;
}
.commentColumn.commentBoxFormField {
  padding: 10px;
  height: 100%;
}

h6.heading {
  font-weight: bold;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding-bottom: 10px;
}

.addDocumentForm .addDocumentFormFields {
  background: #ffffff;
  padding: 10px;
  border-radius: 5px;
}
.addDocumentForm .customFileUpload {
  margin: 10px 0 20px;
}
.addDocumentForm .customFileUpload label {
  height: 250px;
  display: block;
  padding-top: 80px;
  text-align: center;
}
.addDocumentForm .customFileUpload label div {
  display: block;
}
.addDocumentForm .customFileUpload label div > span:first-child {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 3px;
  font-weight: normal;
  font-size: 14px;
}
.addDocumentForm .customFileUpload label div > span:first-child span {
  text-decoration: none;
}
.addDocumentForm .customFileUpload label div > span:last-child {
  display: block;
  margin: 0 auto;
}
.addDocumentForm .customFileUpload label svg {
  background: url("../images/icons/document.svg") no-repeat center center;
  background-size: contain;
  width: 40px;
  height: 40px;
}
.addDocumentForm .customFileUpload label svg path {
  opacity: 0;
}

.toggleProfileDetail {
  text-align: right;
}
.toggleProfileDetail span {
  color: #006bcf;
  font-size: 14px;
  font-weight: bold;
  cursor: pointer;
}
.toggleProfileDetail span img {
  width: 25px;
  height: 25px;
  filter: invert(28%) sepia(61%) saturate(7326%) hue-rotate(198deg) brightness(95%) contrast(101%);
}
.toggleProfileDetail span.hideDetail img {
  transform: rotate(180deg);
}

.modalCloseButton {
  text-align: right;
}
.modalCloseButton img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

.addReviewForm {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 0;
}
.addReviewForm .formActionButtons {
  justify-content: center;
}

.patientOptions {
  margin-top: 20px;
}
.patientOptions .careplanOptions .cards {
  cursor: pointer;
}

.tabContainer {
  margin: 20px 0 0;
  padding: 0;
  border: 0;
}
.tabContainer li.nav-item {
  border-radius: 0;
}
.tabContainer li.nav-item .nav-link {
  font-size: 14px;
  color: #666;
}
.tabContainer li.nav-item .nav-link.active {
  color: #000000;
}

.tab-content {
  position: relative;
}
.tab-content .requestContentCards {
  padding: 20px;
  background: #ffffff;
  border: 1px solid rgba(193, 195, 200, 0.5) !important;
  border-top: 0;
}

.titleandButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding: 0 20px;
}
.titleandButton h3 {
  padding-left: 0 !important;
}

.forgmGrouMarginBottomZero .form-group {
  margin-bottom: 0 !important;
}

.doseSchedule .row {
  margin-bottom: 20px;
}
.doseSchedule .row:nth-last-child(2) {
  margin-bottom: 0 !important;
}
.doseSchedule .row:nth-last-child(2) .form-group {
  margin-bottom: 0;
}
.doseSchedule .form-group {
  margin-bottom: 0;
}
.doseSchedule button {
  font-size: 11px;
}
.doseSchedule span.btn {
  font-size: 11px;
}
@media (max-width: 768px) {
  .doseSchedule .form-group {
    margin-bottom: 20px;
  }
}

.medicineInventoryStock .modal-dialog .modal-content .modal-body {
  max-height: unset;
  height: auto;
}

.riskCalculatorFields {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
}
.riskCalculatorFields .field {
  flex: 1;
}

.selectBoxLevel {
  margin-top: -25px;
}
.selectBoxLevel .css-13cymwt-control {
  min-height: 40px;
}
.selectBoxLevel.shiftSchedule {
  font-size: 13px;
}
.selectBoxLevel.shiftSchedule .css-1hb7zxy-IndicatorsContainer {
  display: none;
}

.checkboxesList {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 20px;
  margin-bottom: 25px;
}
.checkboxesList .form-check-input[type=radio] {
  width: 15px;
  height: 15px;
  border-width: 2px;
}
.checkboxesList label {
  color: #777d80;
  font-size: 14px;
  margin-left: 5px;
}

.addScheduleTiming {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 30px;
}
.addScheduleTiming div {
  width: 31%;
}
.addScheduleTiming div .form-check {
  width: 155px;
  padding-left: 17px;
}
@media (max-width: 768px) {
  .addScheduleTiming div {
    width: 48%;
  }
}

.hiddenDiv {
  display: none;
}

.css-13cymwt-control {
  min-height: 40px !important;
}

.wizardSteps .supportArea .checkboxList {
  gap: 20px;
}
.wizardSteps .supportArea .checkboxList .checkboxContainer {
  width: auto;
}
.wizardSteps .supportArea .checkboxList label {
  margin-left: 10px;
  font-size: 14px;
}
.wizardSteps table.dynamicRows {
  width: 100%;
}
.wizardSteps table.dynamicRows tr:last-child td {
  background: #fff !important;
  box-shadow: none;
}
.wizardSteps table.dynamicRows tr:last-child td:hover {
  background: #fff !important;
  box-shadow: none;
}
.wizardSteps table.dynamicRows tr td,
.wizardSteps table.dynamicRows tr th {
  padding: 10px;
  font-size: 13px;
}
.wizardSteps table.dynamicRows tr td:last-child,
.wizardSteps table.dynamicRows tr th:last-child {
  text-align: center;
}
.wizardSteps table.dynamicRows tr td.leftAlignText,
.wizardSteps table.dynamicRows tr th.leftAlignText {
  text-align: left;
}
.wizardSteps table.dynamicRows tr td .form-group,
.wizardSteps table.dynamicRows tr th .form-group {
  margin: 0;
}

.actionsPoint table.dynamicRows tr td,
.actionsPoint table.dynamicRows tr th,
.keyPoints table.dynamicRows tr td,
.keyPoints table.dynamicRows tr th {
  padding: 10px;
}
.actionsPoint table.dynamicRows tr td:nth-child(1),
.actionsPoint table.dynamicRows tr th:nth-child(1),
.keyPoints table.dynamicRows tr td:nth-child(1),
.keyPoints table.dynamicRows tr th:nth-child(1) {
  width: 70%;
}
.actionsPoint table.dynamicRows tr td:nth-child(2),
.actionsPoint table.dynamicRows tr th:nth-child(2),
.keyPoints table.dynamicRows tr td:nth-child(2),
.keyPoints table.dynamicRows tr th:nth-child(2) {
  width: 25%;
}
.actionsPoint table.dynamicRows tr td:nth-child(3),
.actionsPoint table.dynamicRows tr th:nth-child(3),
.keyPoints table.dynamicRows tr td:nth-child(3),
.keyPoints table.dynamicRows tr th:nth-child(3) {
  width: 5%;
}
.actionsPoint table.dynamicRows tr td .checkboxContainer,
.actionsPoint table.dynamicRows tr th .checkboxContainer,
.keyPoints table.dynamicRows tr td .checkboxContainer,
.keyPoints table.dynamicRows tr th .checkboxContainer {
  width: 20px;
  margin: 0 auto;
}

.keyPoints table tr td:nth-child(2) {
  text-align: center;
}

.incidentsPoint table.dynamicRows tr td,
.incidentsPoint table.dynamicRows tr th {
  padding: 10px;
}
.incidentsPoint table.dynamicRows tr td:nth-child(1),
.incidentsPoint table.dynamicRows tr th:nth-child(1) {
  width: 25%;
}
.incidentsPoint table.dynamicRows tr td:nth-child(2),
.incidentsPoint table.dynamicRows tr th:nth-child(2) {
  width: 70%;
}
.incidentsPoint table.dynamicRows tr td:nth-child(3),
.incidentsPoint table.dynamicRows tr th:nth-child(3) {
  width: 5%;
}

.riskAssesmentsPoints table.dynamicRows thead tr:first-child th:nth-child(1) {
  width: 50%;
}
.riskAssesmentsPoints table.dynamicRows thead tr:first-child th:nth-child(2) {
  width: 25%;
}
.riskAssesmentsPoints table.dynamicRows thead tr:first-child th:nth-child(3) {
  width: 25%;
}
.riskAssesmentsPoints table.dynamicRows tbody td:nth-child(1) {
  width: 4%;
}
.riskAssesmentsPoints table.dynamicRows tbody td select {
  width: 250px;
  margin: 0 auto;
}
.riskAssesmentsPoints table.dynamicRows tr td,
.riskAssesmentsPoints table.dynamicRows tr th {
  text-align: center;
  padding: 5px 10px;
  vertical-align: middle;
}

.summaryOfPlansPoints table.dynamicRows tr td {
  padding: 10px;
  vertical-align: middle;
}
.summaryOfPlansPoints table.dynamicRows tr td:nth-child(1) {
  width: 30%;
}
.summaryOfPlansPoints table.dynamicRows tr td:nth-child(2) {
  width: 70%;
}

.individualReviewsActions img {
  width: 24px;
  height: 24px;
  max-width: 100%;
}

img.passwordViewIcon {
  background: #c1c3c8;
  border-radius: 50%;
  padding: 2px;
  width: 24px;
  height: 24px;
}

.enteredBy {
  margin-bottom: 50px;
}
.enteredBy .usersNameInfo img {
  width: 60px;
  height: 60px;
}
.enteredBy .usersNameInfo .userInfo span {
  font-weight: normal;
}
.enteredBy .usersNameInfo .userInfo strong {
  font-size: 16px;
  font-weight: bold;
}

.feedBackIcons {
  width: 30px;
  height: 30px;
  background: #ffffff;
  border-radius: 50%;
  margin: 0 auto;
}
.feedBackIcons img {
  width: 100%;
}
.feedBackIcons.Inadequate {
  background: #e81224;
}
.feedBackIcons.RequiresImprovement {
  background: #f7630c;
}
.feedBackIcons.Good {
  background: #fff100;
}
.feedBackIcons.Outstanding {
  background: #16c60c;
}
.feedBackIcons.notSubmited {
  opacity: 0.3;
  background: rgba(102, 102, 102, 0.1);
}

.carePlanDataTableWrapper .jNIkof {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.lastModifiedList {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  margin-top: 30px;
  padding: 20px;
  height: calc(100% - 30px);
}
.lastModifiedList header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 0 0 10px;
  margin-bottom: 20px;
}
.lastModifiedList header h3 {
  font-size: 18px;
}
.lastModifiedList .lastModifiedListContent {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px;
}
.lastModifiedList .lastModifiedListContent .listContentItem {
  flex: 1 0 calc(50% - 20px); /* Adjust the percentage and gap as needed */
  max-width: calc(50% - 20px); /* Adjust the percentage and gap as needed */
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 20px;
  margin-bottom: 0;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}
.lastModifiedList .lastModifiedListContent .listContentItem small {
  font-weight: normal;
}
@media (max-width: 1024px) {
  .lastModifiedList .lastModifiedListContent .listContentItem {
    flex: 1 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 10px auto;
  }
}
@media (max-width: 993px) {
  .lastModifiedList .lastModifiedListContent .listContentItem {
    flex: 1 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 10px auto;
  }
}
@media (max-width: 768px) {
  .lastModifiedList .lastModifiedListContent .listContentItem {
    flex: 1 0 calc(50% - 20px);
    max-width: calc(50% - 20px);
    margin: 10px auto;
  }
}
@media (max-width: 575px) {
  .lastModifiedList .lastModifiedListContent .listContentItem {
    flex: 1 0 calc(100% - 20px);
    max-width: calc(100% - 20px);
    margin: 10px auto;
  }
}
@media (max-width: 480px) {
  .lastModifiedList .lastModifiedListContent .listContentItem {
    min-width: 100%;
  }
}

.emojiInstructions .instructionsContent {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 50px;
  padding: 30px 0 30px;
}
.emojiInstructions .instructionsContent .item {
  text-align: center;
}
.emojiInstructions .instructionsContent .item p {
  margin: 0;
}
.emojiInstructions .instructionsContent .item img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
}
.emojiInstructions .instructionsContent .item img.Inadequate {
  background: #e81224;
}
.emojiInstructions .instructionsContent .item img.RequiresImprovement {
  background: #f7630c;
}
.emojiInstructions .instructionsContent .item img.Good {
  background: #fff100;
}
.emojiInstructions .instructionsContent .item img.Outstanding {
  background: #16c60c;
}

.commonOptionsHeaders {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 15px;
  margin-bottom: 15px;
}
.commonOptionsHeaders h4 {
  margin: 0;
}

.optionsBlocks {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px;
}
.optionsBlocks p {
  margin: 0;
  padding: 0;
}

.isReviewed label {
  margin-top: 3px;
  font-weight: normal;
}

p.createdBy {
  font-size: 14px;
  font-weight: normal;
  margin: 10px 0;
}
p.createdBy small {
  margin-top: 0 !important;
}

.downloadLink {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}
.downloadLink .viewIcons img {
  width: 30px;
  height: 30px;
}

.referenceDiv {
  height: 0;
  width: 0;
  font-size: 0;
}

.observationImg {
  max-width: 100%;
}

.sc-eDPEul {
  background: transparent !important;
}

.allCarePlansNotes .sc-eDPEul {
  display: none;
}

.searchFiltersFields .validationError {
  margin-top: -15px;
}

.initialNumbersOfInc {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}
.initialNumbersOfInc span {
  text-align: center;
  background: rgba(102, 102, 102, 0.1);
  padding: 5px 10px;
  display: block;
  cursor: default;
}

.preLoaderWrapper {
  background: #ffffff;
  padding: 30px;
  text-align: center;
}
.preLoaderWrapper .preloader-container {
  position: relative;
  top: auto;
  left: 0;
}

.reviewListHeader .actionColumns {
  justify-content: flex-end;
  gap: 10px;
}

.patientReviewCard .card .cardContent .contentIcon img {
  filter: brightness(0) saturate(100%) invert(47%) sepia(20%) saturate(4153%) hue-rotate(188deg) brightness(95%) contrast(94%) !important;
}

.capitalizeTransform {
  text-transform: capitalize;
}

.dateInputWrapper {
  position: relative;
}
.dateInputWrapper .inputTypeText {
  background: url("../images/icons/dateIcon.svg") no-repeat 94% center;
  background-size: 11px;
}
.dateInputWrapper .inputTypeDate {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.holidaysAbsence {
  height: calc(100% - 20px);
}

.grid-item {
  display: block;
  width: 100%;
  height: 250px;
}

.applicationProcessWrapper .tableTitle {
  background: #ffffff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  padding: 10px 15px;
  margin-bottom: 20px;
}
.applicationProcessWrapper .rowRecruitment {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  min-width: 1400px;
  height: calc(100vh - 185px);
  gap: 10px;
}
.applicationProcessWrapper .rowRecruitment .recColumn {
  width: 20%;
}
.applicationProcessWrapper .rowRecruitment .recColumn header {
  font-weight: bold;
  width: 100%;
  background: #ffffff;
  border-radius: 0;
  min-height: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
}
.applicationProcessWrapper .rowRecruitment .recColumn header small {
  display: block;
  font-weight: normal;
  font-size: 65%;
}
.applicationProcessWrapper .container {
  display: flex;
  touch-action: none;
  width: 100%;
  margin: 1rem auto;
}
.applicationProcessWrapper .dropzone {
  position: relative;
  flex: 1;
  min-height: calc(100vh - 250px);
  margin-top: 60px;
  border-radius: 0;
  padding: 10px;
  background: rgba(0, 0, 0, 0.05);
  border: 1px solid rgba(0, 0, 0, 0.01);
  margin-bottom: 20px;
}
.applicationProcessWrapper .dropzone.left {
  margin-right: 10px;
}
.applicationProcessWrapper .grid-item {
  padding: 0 0 10px 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  cursor: move;
}
.applicationProcessWrapper .GridItem {
  margin-top: 10px;
  width: calc(100% - 20px) !important;
  left: auto !important;
  right: auto;
}
.applicationProcessWrapper .grid-item-content {
  background: #ffffff;
  padding: 20px;
  box-shadow: 0 0 5px rgba(102, 102, 102, 0.1);
}
.applicationProcessWrapper .grid-item-content header.gridContent {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  padding: 0 !important;
  margin-bottom: 0;
}
.applicationProcessWrapper .grid-item-content span {
  display: block;
}
.applicationProcessWrapper .grid-item-content span.edit {
  text-align: right;
}
.applicationProcessWrapper .grid-item-content span.edit img {
  width: 25px;
  height: 25px;
}
.applicationProcessWrapper .grid-item-content span.dpImage img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: rgba(193, 195, 200, 0.5);
}
.applicationProcessWrapper .grid-item-content .userInfo {
  padding: 0;
}
.applicationProcessWrapper .grid-item-content .userInfo span {
  display: block;
  margin: 5px 0 0;
  font-size: 12px;
}
.applicationProcessWrapper .grid-item-content .userInfo .nameBlock {
  font-weight: bold;
  font-size: 14px;
}

.candidateDetailPopup {
  padding: 20px !important;
}
.candidateDetailPopup header {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 20px;
  padding: 10px 0;
  margin-bottom: 10px;
}
.candidateDetailPopup span.dpImage img {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  background: rgba(193, 195, 200, 0.5);
}
.candidateDetailPopup .userInfo .nameBlock {
  font-weight: bold;
  font-size: 16px;
}
.candidateDetailPopup .userInfo .nameBlock small {
  font-weight: normal;
  margin-left: 10px;
  font-size: 70%;
  text-transform: capitalize;
}
.candidateDetailPopup .userInfo span {
  display: block;
  margin: 5px 0 0;
  font-size: 14px;
}
.candidateDetailPopup main p strong {
  display: block;
}

.candidateProfile {
  margin-bottom: 30px;
  display: block;
}
.candidateProfile img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

p.attachedFile {
  background: rgba(102, 102, 102, 0.08);
  padding: 10px;
  border-radius: 5px;
}
p.attachedFile a {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  flex-wrap: nowrap;
  gap: 10px;
  color: #000000;
}
p.attachedFile a img {
  width: 25px;
  height: 25px;
}

.checkedSUinvolved {
  margin-bottom: 20px;
}

.myContactsPoints table.dynamicRows tr td,
.myContactsPoints table.dynamicRows tr th {
  padding: 20px;
}
.myContactsPoints table.dynamicRows tr td:nth-child(1),
.myContactsPoints table.dynamicRows tr th:nth-child(1) {
  width: 95%;
}
.myContactsPoints table.dynamicRows tr td:nth-child(2),
.myContactsPoints table.dynamicRows tr th:nth-child(2) {
  width: 5%;
  vertical-align: middle;
}
.myContactsPoints table.dynamicRows tr td h3,
.myContactsPoints table.dynamicRows tr th h3 {
  font-size: 16px;
  font-weight: bold;
  margin: 0 0 20px 0;
}
.myContactsPoints table.dynamicRows tr td .col-md-12 .form-group,
.myContactsPoints table.dynamicRows tr th .col-md-12 .form-group {
  margin: 0;
}
.myContactsPoints table.dynamicRows tfoot tr td {
  margin: 0;
  padding: 0;
}
.myContactsPoints table.dynamicRows tfoot tr td:nth-child(2) {
  text-align: center;
}
.myContactsPoints table.dynamicRows tfoot tr td:nth-child(2) img.addRow {
  margin: 10px 0;
}

.suInvolvement {
  display: block;
  line-height: normal;
}

.attachedDocumentWrapper {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.attachedDocumentWrapper .alert {
  width: 30%;
  text-align: center;
  padding: 10px;
}
.attachedDocumentWrapper .alert a {
  color: #333333;
  font-size: 14px;
}

.archievedCommentBox {
  margin-top: 10px;
}

.notesWrapper {
  background: rgba(102, 102, 102, 0.08);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
}
.notesWrapper .notesContent p strong,
.notesWrapper .notesContent p span {
  font-size: 13px;
}
.notesWrapper .notesContent p strong,
.notesWrapper .notesContent p span,
.notesWrapper .notesContent p small {
  display: block;
}
.notesWrapper .notesContent p small {
  text-transform: capitalize;
}

.actionIconsButtons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.actionIconsButtons span {
  display: block;
  width: 25px;
  height: 25px;
  border-radius: 50%;
}
.actionIconsButtons span.disabledOnClickButton {
  background: #000000;
  opacity: 0.2;
}
.actionIconsButtons span.disabledOnClickButton img {
  filter: brightness(0) saturate(100%) invert(88%) sepia(100%) saturate(1%) hue-rotate(72deg) brightness(104%) contrast(101%);
  border-radius: 0;
  padding: 3px;
}
.actionIconsButtons span.completeOnBoard {
  background: #03b93f;
  cursor: pointer;
}
.actionIconsButtons span.completeOnBoard img {
  filter: brightness(0) saturate(100%) invert(88%) sepia(100%) saturate(1%) hue-rotate(72deg) brightness(104%) contrast(101%);
  border-radius: 0;
  padding: 4px;
}
.actionIconsButtons span img {
  max-width: 100%;
  border-radius: 50%;
}

.mainContent.minHeight {
  min-height: 300px;
}

span.contentcell {
  display: block;
}

.contentDataCell {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 10px;
}

.shiftActionButtons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
  margin-bottom: -10px;
  position: relative;
}
.shiftActionButtons img {
  width: 20px;
  height: 20px;
}

.inActiveWeek {
  opacity: 0.6;
  filter: blur(1px);
}

.rosterDragnDrop .container {
  display: flex;
  touch-action: none;
  width: 800px;
  margin: 1rem auto;
}
.rosterDragnDrop .dropzone {
  flex: 1;
  height: 800px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  background: #ff0000;
}
.rosterDragnDrop .dropzone.left {
  margin-right: 10px;
}
.rosterDragnDrop .grid-item {
  padding: 10px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
.rosterDragnDrop .grid-item-content {
  width: 100%;
  height: 50px;
  box-sizing: border-box;
  background: #08e;
  display: flex;
  justify-content: center;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  align-items: center;
}

.staffRolesList {
  margin: 20px 0 !important;
  padding-left: 20px !important;
}

.permissionDenied {
  text-align: center;
}
.permissionDenied img {
  width: 50px;
  height: 50px;
  margin-bottom: 30px;
}

.attachmentChat {
  width: 20px !important;
  height: 20px !important;
}

.groupMemberName {
  font-weight: bold;
  display: block;
  font-size: 10px;
}

.myRosterDragNDrop .container {
  padding: 20px;
}
.myRosterDragNDrop table {
  width: 100%;
  border-collapse: collapse;
}
.myRosterDragNDrop tr {
  height: 70px;
}
.myRosterDragNDrop td {
  border: 1px solid #ddd;
  text-align: center;
  vertical-align: middle;
  width: 33.33%; /* For a 3-column layout */
}
.myRosterDragNDrop .grid-item {
  padding: 10px;
  background-color: #fff;
  border: 1px solid #ccc;
  height: auto;
}
.myRosterDragNDrop .dropzone {
  flex: 1;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 1rem;
  background: #ff0000;
}
.myRosterDragNDrop .grid-item-content {
  font-size: 18px;
  font-weight: bold;
}

.border-dark {
  border: 1px solid gray;
}

.payrollSubHeader {
  background: #ffffff;
  padding: 0;
  margin-bottom: 20px;
}
.payrollSubHeader .payrollDateFilter {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
  gap: 10px;
}
.payrollSubHeader .payrollDateFilter .form-group {
  margin: 0 0 10px;
  min-width: 130px;
}
.payrollSubHeader .radioTabsHeader .tabsBlocks {
  width: 100%;
}

.payrollConfirmation img.payrollIcon {
  width: 80px;
  height: 80px;
  margin: 20px 0;
}
.payrollConfirmation .modal-dialog .modal-content .modal-footer {
  justify-content: center;
}

.payrollActionButtons {
  margin-left: auto;
  display: flex;
  gap: 10px;
}

.trainingQuizzesWrapper .actionsPoint table tr td,
.trainingQuizzesWrapper .actionsPoint table tr th {
  vertical-align: middle;
}
.trainingQuizzesWrapper .actionsPoint table tr td img.addRow,
.trainingQuizzesWrapper .actionsPoint table tr th img.addRow {
  margin: 0;
}
.trainingQuizzesWrapper .actionsPoint .form-group {
  margin-bottom: 0;
}

.staffMemberWrapper {
  padding: 10px 0;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}
.staffMemberWrapper .claimedStatus {
  font-weight: normal;
  text-align: center;
  padding: 3px;
  border-radius: 3px;
}
.staffMemberWrapper .claimedStatus.background-success, .staffMemberWrapper .claimedStatus.background-danger {
  cursor: no-drop;
}
.staffMemberWrapper .claimedStatus.background-warning {
  cursor: pointer;
}
.staffMemberWrapper .claimedStatus span {
  font-size: 12px !important;
}

.staffWorkLoadHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  margin: 0 0 10px;
}
.staffWorkLoadHeader h3 {
  margin: 0;
}

#uploadedFilePathData img {
  max-width: 250px;
}

.greyBackground {
  background: #f6f6f6;
  border: 2px solid #d8d9db;
  padding: 10px 10px 0 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}

.alreadyAssigned img {
  width: 20px;
  height: 20px;
  margin: -10px 0 0 10px;
  cursor: pointer;
}

.assignedNoRecordFound .preloader-container {
  left: 40%;
}

.requestChanges {
  padding: 10px 0;
  line-height: 20px;
}

.staffRecordsGroupsTable .rdt_TableHeader > div {
  position: relative;
  width: 100%;
}

.buttonWrap {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  max-width: 450px;
  width: 100%;
  gap: 10px;
}

.medicationDetail {
  background: rgba(102, 102, 102, 0.05);
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto 20px;
  width: 98%;
}
.medicationDetail .row .col-md-3,
.medicationDetail .row p {
  font-size: 12px !important;
  margin-bottom: 0;
  font-weight: normal;
}
.medicationDetail .row .col-md-3 strong,
.medicationDetail .row p strong {
  font-weight: bold;
}

.taskList .usersNameInfo {
  border-bottom: 0 !important;
}
.taskList .usersNameInfo img {
  width: 30px !important;
  height: 30px !important;
  margin-top: 0;
  margin-bottom: 0;
}

table.overViewTaskDetail {
  width: 100%;
}
table.overViewTaskDetail tr td,
table.overViewTaskDetail tr th {
  width: 25%;
  vertical-align: middle;
}

.fontWeightNormal {
  font-weight: normal !important;
}

header.primaryHeader {
  background: #278dec;
  color: #ffffff;
}
header.primaryHeader h3 {
  color: #ffffff;
}

.textareaTooltip {
  position: absolute;
  top: -10px;
  right: 0;
}
.textareaTooltip img {
  background: #fff;
  border-radius: 50%;
}

.memberSelectBox .select__menu-list {
  position: relative;
}

.quizList {
  list-style-type: none;
  padding: 0;
  margin: 0;
}
.quizList li {
  margin: 10px 0 20px;
  font-weight: bold;
}
.quizList li ul {
  margin-left: 20px;
  padding-left: 20px;
  list-style-type: disc;
}
.quizList li ul li {
  margin: 5px 0;
  font-weight: normal;
}

.currentHealthIssuesWrapper .currentHealthIssues {
  min-height: calc(100vh - 100px);
}
.currentHealthIssuesWrapper .currentHealthIssues.carePlanCurrentHealthIssues {
  min-height: unset;
}
.currentHealthIssuesWrapper .currentHealthIssues .tabContainer {
  margin-top: 10px;
}
.currentHealthIssuesWrapper .currentHealthIssues h3 {
  font-size: 18px;
  padding: 20px 20px 10px;
}
.currentHealthIssuesWrapper .currentHealthIssues .titleandButton {
  background: #ffffff;
  border-radius: 5px;
}
.currentHealthIssuesWrapper .currentHealthIssues .tabContents {
  background: #ffffff;
  background: url("../images/healthIssueBg.svg") no-repeat center bottom #ffffff;
  background-size: contain;
  border: 1px solid rgba(193, 195, 200, 0.5) !important;
  border-top: 0;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyHeader {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  position: relative;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyHeader .rootSpanWrapper {
  position: relative;
  display: flex;
  /* Allow flex items to wrap */
  flex-wrap: nowrap; /* Ensure items don't wrap to the next line */
  gap: 10px; /* Adjust the gap between spans */
  overflow-x: auto; /* Enable horizontal scrolling */
  /* Set a minimum width to ensure the container has a size even when empty */
  min-width: 100%; /* Adjust this to suit your layout */
  padding: 10px 10px;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyHeader .rootSpanWrapper .rootSpan {
  width: auto;
  white-space: nowrap;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyHeader .preloader-container .preloader-spinner {
  width: 20px;
  height: 20px;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyHeader span.bodyPartsLink {
  font-size: 13px;
  cursor: pointer;
  padding: 6px 15px;
  border-radius: 5px;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyHeader span.bodyPartsLink.active {
  background: rgba(99, 180, 255, 0.3);
  color: #278dec;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyHeader span.bodyPartsLink:hover {
  border: 0;
  color: #278dec;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent {
  padding-bottom: 50px;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody {
  width: 100%;
  max-width: 360px;
  margin: 20px auto 50px;
  position: relative;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody img {
  max-width: 100%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint {
  width: 2.5%;
  height: 2%;
  position: absolute;
  border: 1px solid #ffffff;
  border-radius: 50%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint .target {
  display: block;
  width: 100%;
  border-radius: 50%;
  position: relative;
  z-index: 1;
  cursor: pointer;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint .target:hover, .currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint .target:focus, .currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint .target:active {
  background: #278dec;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint img[aria-describedby],
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint img.target.notActiveCirlce {
  filter: invert(52%) sepia(73%) saturate(1533%) hue-rotate(98deg) brightness(90%) contrast(98%);
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.head {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.fronthead {
  top: 11%;
  left: 19.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.backhead {
  top: 11%;
  left: 77.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.eyes {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.righteye {
  top: 14%;
  left: 17%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.lefteye {
  top: 14%;
  left: 22%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.ears {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightear {
  top: 15%;
  left: 14%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftear {
  top: 15%;
  left: 25.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.nose {
  top: 16%;
  left: 19.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.mouth {
  top: 18%;
  left: 19.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.neck {
  top: 21%;
  left: 19.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.nape {
  top: 20%;
  left: 77.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.shoulders {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightshoulder {
  top: 26%;
  left: 7.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftshoulder {
  top: 26%;
  left: 31.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.shoulderblade {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightshoulderblade {
  top: 26%;
  left: 88.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftshoulderblade {
  top: 26%;
  left: 66.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.arms {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightArm {
  top: 35%;
  left: 5.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftArm {
  top: 35%;
  left: 33.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.forearm {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightforearm {
  top: 46%;
  left: 4.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftforearm {
  top: 46%;
  left: 34.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.elbows {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightelbow {
  top: 41%;
  left: 93%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftelbow {
  top: 41%;
  left: 62%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.wrists {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightwrist {
  top: 51%;
  left: 3%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftwrist {
  top: 51%;
  left: 36%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.hands {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.righthand {
  top: 55%;
  left: 3%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.lefthand {
  top: 55%;
  left: 36%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.chest {
  top: 30%;
  left: 19.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.back {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.topback {
  top: 30%;
  left: 77.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.middleback {
  top: 38%;
  left: 77.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.bottomback {
  top: 47%;
  left: 77.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.abdomen {
  top: 40%;
  left: 19.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.hips {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.lefthip {
  top: 54%;
  left: 67%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.righthip {
  top: 54%;
  left: 88.3%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.buttocks {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightbuttock {
  top: 52%;
  left: 83%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftbuttock {
  top: 52%;
  left: 72%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.groin {
  top: 54%;
  left: 19.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.thighs {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightthigh {
  top: 61%;
  left: 13.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftthigh {
  top: 61%;
  left: 25.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.knees {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightknee {
  top: 73%;
  left: 13.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftknee {
  top: 73%;
  left: 25.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.calves {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightcalf {
  top: 81%;
  left: 84%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftcalf {
  top: 81%;
  left: 71.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.ankles {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftankle {
  top: 93%;
  left: 25%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightankle {
  top: 93%;
  left: 14.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.feet {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftfoot {
  top: 96%;
  left: 25%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightfoot {
  top: 96%;
  left: 14.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.healthIssueContent {
  display: none;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.leftheel {
  top: 94%;
  left: 72.5%;
}
.currentHealthIssuesWrapper .currentHealthIssues .partsOfBodyContent .humanBody .targetPoint.rightheel {
  top: 94%;
  left: 83%;
}

.popover {
  border-radius: 0;
  border: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 300px;
}
.popover .popover-body {
  padding: 0;
}

.issuePointDetail {
  padding: 0;
  z-index: 2;
  max-height: 300px;
  min-width: 300px;
  overflow-y: auto;
}
.issuePointDetail p {
  font-size: 13px;
}
.issuePointDetail header {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding: 15px 10px 5px 10px;
}
.issuePointDetail header .headerRow {
  display: flex;
  flex-direction: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: nowrap;
  padding: 0;
  margin-bottom: 5px;
}
.issuePointDetail header h3 {
  font-size: 15px;
  padding: 0;
  margin-bottom: 0;
}
.issuePointDetail header h3 small {
  display: block;
  font-weight: normal;
  font-size: 13px;
}
.issuePointDetail header p {
  margin-bottom: 0;
}
.issuePointDetail header p strong {
  display: block;
}
.issuePointDetail header select.statusButton {
  padding: 1px 10px 3px;
  font-size: 12px;
}
.issuePointDetail .healthIssueContent {
  padding: 10px 10px 10px;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
}
.issuePointDetail .healthIssueContent p {
  margin-bottom: 10px;
  font-size: 13px;
}
.issuePointDetail .dataRow:nth-child(even) {
  background: #f6f6f6;
}

.rootSpan span {
  display: block;
  text-align: center;
}

.clickableTargetPoints .clickPoint {
  cursor: pointer;
}

.staffOverview .maxWidthTableRowWidth div[role=rowgroup] div[role=row] div[role=cell]:nth-child(2) {
  padding-left: 16px;
}
.staffOverview .maxWidthTableRowWidth div[role=rowgroup] div[role=row] div[role=cell]:nth-child(3) {
  padding-left: 16px;
}
.staffOverview .maxWidthTableRowWidth div[role=rowgroup] div[role=row] div[role=cell] .tableText {
  padding: 16px 15px;
}

.d-flex {
  display: flex;
  justify-content: space-between;
}

.d-flex span[role=user-name-avatar]:first-of-type {
  justify-content: flex-start;
  display: flex;
  gap: 5px;
}

.d-flex span[role=user-name-avatar] img[role=user-avatar-image] {
  width: 22px;
  height: auto;
  min-width: 22px;
  max-width: 22px;
}

.select-box Button[role=btn-rejected] {
  background: #ffcccc !important;
  color: #da0b0b !important;
  border-color: #ffcccc !important;
}

.select-box Button[role=btn-approved] {
  background: #c8ffd9 !important;
  color: #46a37a !important;
  border-color: #c8ffd9 !important;
}

.staffOverview .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(3) {
  padding-left: 15px;
}

.phoneFieldContainer span[role=container] {
  position: absolute;
  z-index: 1;
  margin: 6px 5px 2px 3px;
  width: 11%;
  height: 34px;
  background: transparent;
}

.phoneFieldContainer span[role=container] select[role=countryCode] {
  border-radius: 5px;
  object-fit: contain;
  border: none;
  width: 100%;
  height: 100%;
}

.cursor-pointer {
  cursor: pointer;
}

.object-fit {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.display-grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}

.pageTitleText img {
  width: 27px;
  height: auto;
}

.modal-closer-icon-container {
  display: block;
  margin-right: 3%;
  cursor: pointer;
}

.modal-closer-icon-container span {
  color: red;
  display: flex;
  font-size: larger;
  margin-left: 93%;
  position: absolute;
  cursor: pointer;
}

.avatarAction .image-upload-icon img {
  height: 36px;
  width: auto;
  max-height: 50px;
}

.padding-column {
  padding: 14px;
}

.greyColor {
  color: #666;
}

.blackColor {
  color: #000000;
}

.p-left-15 {
  padding-left: 15px;
}

.p-left-0 {
  padding-left: 0px;
}

.display-flex-end {
  display: flex;
  justify-content: end;
  gap: 8px;
}

.addPlusIcon {
  width: 24px;
  height: auto;
  max-width: 24px;
  cursor: pointer;
}

.addEditIcon {
  width: 23px;
  height: auto;
  max-width: 23px;
}

.addAnswerText {
  font-size: smaller;
  font-weight: 600;
  cursor: pointer;
}

.font-size-10 {
  font-size: 10px;
}

.display-flex-spaceBetween {
  display: flex;
  justify-content: space-between;
}

.display-flex-start {
  display: flex !important;
  justify-content: start !important;
}

.display-flex-item {
  position: absolute;
  display: contents;
}

.addReferenceDocumentsPlusIcon {
  width: 34px;
  height: auto;
  cursor: pointer;
}

.addRefCounterLabel {
  cursor: pointer;
  position: relative;
  top: 2px;
  left: -10px;
}

.cursorPointer {
  cursor: pointer !important;
}

.candidatesBlocks .mainContent .iconList {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 7px;
  min-width: 100px;
}

.candidatesBlocks .mainContent .iconList a img {
  width: 25px;
  height: auto;
}

.cursor-pointer {
  cursor: pointer;
}

.usersNameInfo {
  gap: 0px !important;
}

.display-grid-container {
  gap: 10px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  margin: auto;
}

.redColor {
  color: #da0b0b;
}

.d-grid-row-item1 {
  display: "flex";
  align-items: "center";
  gap: "8px";
}

.d-grid-row-item2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}

.shadow-container {
  box-shadow: "0 4px 8px rgba(0, 0, 0, 0.1)";
  border-radius: "8px";
  padding: "16px";
  margin-bottom: "16px";
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.basic-hr {
  border: 1px solid #ccc;
  margin: 10px 0;
}

.styled-hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #ccc, #555, #ccc);
  margin: 10px 0;
}

.dotted-hr {
  border-top: 1px dotted #ccc;
  margin: 10px 0;
}

.double-hr {
  border-top: 3px double #555;
  margin: 10px 0;
}

.thick-hr {
  border-top: 5px solid #555;
  margin: 10px 0;
}

.custom-hr {
  border: 2px solid #007bff;
  height: 10px;
  margin: 10px 0;
}

.css-1dimb5e-singleValue {
  color: #777d80 !important;
}

.customSelectBox {
  color: #777d80 !important;
  font-size: 13px;
}
.customSelectBox * {
  cursor: pointer !important;
}
.customSelectBox label.floatingLabel {
  top: -8px;
  color: #777d80 !important;
  left: 5px;
  font-size: 12px;
  padding: 0 5px;
}

.check-icons {
  display: flex;
  gap: 10px;
  position: absolute;
  margin-top: -80px;
  left: 91%;
}

.form-group textarea:focus + label,
.form-group textarea:valid + label {
  top: -8px;
  left: 5px;
  font-size: 12px;
  padding: 0 5px;
}

.editableActionButtons {
  display: flex;
  position: relative;
  left: 92%;
  top: -34%;
}

.paginationContainer {
  position: absolute;
  display: block;
  margin: auto;
}

.contentBox .editableActionButton {
  position: absolute;
  right: 10px;
  bottom: 12px;
}

.textMessage .form-group {
  margin-bottom: 0;
}
.textMessage textarea {
  border: 0;
}
.textMessage textarea:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.commentColumn .messageContainer .roomSpace .messagesPanel {
  margin-top: 0 !important;
}

.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs {
  min-width: max-content;
  border-bottom: 0;
  padding: 11px;
}

.display-flex-gap-10 {
  display: flex;
  gap: 10px;
}

.font-size-16 {
  font-size: 16px;
}

.docUploadValidation {
  border: 1px solid #da0b0b !important;
  color: #da0b0b !important;
}

.docUploadValidation span {
  color: #da0b0b !important;
}

.profileDetailWrapper .profileDetailBlock .userAvatar img {
  height: 150px;
  max-height: 150px;
  border-radius: 50%;
  max-width: 150px;
  background: rgba(193, 195, 200, 0.5);
}

.validationError select {
  border-color: #da0b0b;
  color: #da0b0b;
}

.button-box {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer; /* Added cursor style */
}

.button-box span {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.level {
  display: flex;
  justify-content: space-between;
}

.input-border {
  border: 1px solid #ccc;
  border-radius: 5px;
}

.passwordShowEye {
  width: 18px !important;
  height: 38px !important;
  position: absolute;
  z-index: 1;
  top: 4%;
  opacity: 0.8;
  left: 92%;
  background: none !important;
}

.customValidationLabel {
  top: -47px !important;
  left: 4px !important;
  position: relative;
  background: white;
}

.customValidationMsg {
  position: relative;
  margin-top: -13px;
}

.height-300 {
  height: 300px !important;
}

.modal-body.YAHAN_APNI_CUSTOM_CLASS_LAGA_LAIN .css-1nmdiq5-menu {
  max-height: 180px;
  overflow-y: auto;
}
.modal-body.YAHAN_APNI_CUSTOM_CLASS_LAGA_LAIN .css-1nmdiq5-menu * {
  height: 100%;
  overflow: unset;
}

.d-flex-m-left {
  display: flex;
  left: 9px;
}

.trainingTitleHr {
  padding: 0 0 15px;
  border-bottom: 1px solid rgba(51, 51, 51, 0.1);
  margin-bottom: 10px;
}

.d-grid-2-fr {
  display: grid;
  grid-template-columns: 9fr 1fr;
}

.d-grid-2-fr img {
  position: relative;
  top: 10px;
  left: 10px;
  width: 23px;
  opacity: 0.8;
}

.d-grid-17-2-fr {
  display: grid;
  grid-template-columns: 20fr 1fr;
}

.d-grid-17-2-fr img {
  position: relative;
  top: 10px;
  left: 10px;
  width: 23px;
  opacity: 0.8;
}

.ui-ux-color {
  color: #777d80;
}

.font-size {
  font-size: 15px;
  position: relative;
  right: 0%;
  top: 8px;
  height: max-content;
}

.d-grid-gap {
  display: flex;
  gap: 11%;
}

.custom-d-grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 12px;
}

.pointerEvent-none {
  pointer-events: none;
}

.sleepOver-icon {
  position: relative;
}
.sleepOver-icon img {
  width: 15px !important;
  max-width: 15px;
  position: relative;
}

.customOption {
  display: flex;
  gap: 6px;
  padding: 8px;
  position: relative;
  background-color: white;
  color: #777d80 !important;
  transition: background-color 0.3s, color 0.3s;
}
.customOption img {
  width: 40px !important;
  height: 40px !important;
  max-width: 40px;
  border-radius: 50% !important;
  overflow: hidden !important;
}
.customOption span.label {
  font-size: 14px;
  position: relative;
  margin-top: 5px;
}

.optionHover {
  background-color: rgba(39, 141, 236, 0.25);
  cursor: pointer !important;
}

.optionSelected {
  color: white;
  background-color: #278dec;
  cursor: pointer !important;
}

.display-grid-spaceBetween {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

/* Your CSS file or styled-components styles */
.radioButtonContainer {
  position: relative;
}

.radiomark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.textCenter {
  text-align: center;
}

.d-flex-space-between {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 11px;
  color: #777d80 !important;
}

.d-flex-checkbox-container {
  display: flex;
}

.radioBoxSize {
  width: 20px;
  height: 15px;
}

.radioLabel {
  top: -3px;
  position: relative;
}

.rec-column {
  margin-bottom: 20px;
}

.rec-colum-child {
  background: #ffffff;
  padding: 12px 0px 11px 1px;
  width: 100%;
}

.darkBlack {
  color: #3c4549 !important;
}

.d-flex-gap-43 {
  display: flex;
  gap: 41px;
  color: #777d80 !important;
}

.messageContainer .roomSpace .messagesActions footer .textMessage input {
  width: 100%;
  padding: 5px;
  border: 0;
}
.messageContainer .roomSpace .messagesActions footer .textMessage input:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.messageContainer .mobileNameSpace {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 7px 2px;
  width: 100%;
}

.unAssignedShiftaDay {
  background: #fff2f2;
}
.unAssignedShiftaDay .checkList .labelText {
  display: none;
}
.unAssignedShiftaDay .checkList .checkmark {
  left: 50%;
  transform: translateX(-8px);
  border-color: #777d80;
}

.publishRosterButtons {
  display: flex;
  justify-content: end;
  position: relative;
  top: 10px;
  gap: 10px;
}

.rosterTable .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(5) {
  padding-left: 0;
}

.d-flex-space-around {
  display: flex !important;
  justify-content: space-around;
  gap: 8px;
}

.fs-7 {
  font-size: 7px;
}

.fs-13 {
  font-size: 13px;
}

.fs-12 {
  font-size: 12px !important;
}

.d-flex-align-start {
  display: flex;
  align-items: start;
}

.bg-transparent {
  background: transparent;
}

.scheduleTableGap {
  gap: 2px !important;
  display: grid !important;
  position: relative !important;
  margin: 5px !important;
}

.disabled {
  background: rgba(0, 0, 0, 0.1);
}

.left-align {
  position: relative;
  text-align: left;
}

.padding-10 {
  padding: 7px 6px 10px 12px !important;
}

.padding-7 {
  padding: 4px 0px 6px 10px !important;
}

.scheduleTableTh1 {
  position: relative;
  left: rig;
  right: 22px;
}

.messageContainer .mobileNameSpace .userBox .usersNameContainer img {
  position: relative;
  width: 37px;
  height: 37px;
  border-radius: 50%;
}

.messageContainer .roomSpace .messagesPanel .recieverMessageBoxContainer {
  background-color: #ebeded;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  left: 58% !important;
  padding: 10px 10px;
  color: black;
  max-width: 40%;
  position: relative;
}

.msgFooterIcons {
  display: flex;
  background: #f2f2f2;
  gap: 9px;
  right: 8px;
  top: 3px;
  height: 40px;
  position: relative;
  border-radius: 0px 10px 10px 0px;
}

.messageContainer .roomSpace .messagesActions footer {
  padding: 6px !important;
}

.msgContainer-DisplayBlock {
  display: block;
  width: 100%;
}

.adminTextMsgContainer {
  border-radius: 10px 10px 0px 10px !important;
}

.sendMessagingLoader {
  position: relative;
  left: 112px;
  top: 50px;
}

.emojisContainer {
  height: 352px !important;
  width: 266px !important;
  right: 178px !important;
  bottom: 365px !important;
}

.textFromChatBox {
  position: relative;
  right: 184px;
}

.sendTextMessageChatBox {
  position: relative;
  left: 24px !important;
}

.r-0 {
  right: 0px !important;
}

.messageTime {
  position: absolute;
  top: 4px !important;
  color: #777d80;
  display: flex;
  right: -8px !important;
  justify-content: end;
}

.sendMessageTextArea {
  width: 100%;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 0 !important;
  font-size: 13px;
}

.messageContainer .roomSpace .messagesActions footer {
  padding: 6px !important;
  height: 46px;
}

.sendMsgContainer {
  display: block;
}

.objectFitAndBorder {
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  border-radius: 5px;
  color: #777d80;
  font-size: 13px;
  border: 0;
}
.objectFitAndBorder:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
}

.textAreaContainer {
  height: 100%;
  width: 100%;
  display: flex;
}

.p-13 {
  padding: 13px !important;
}

.individualStaffSchedule table tr td,
.individualStaffSchedule table tr th {
  width: 12%;
  vertical-align: middle;
  text-align: center;
}

.rosterTableContent.p-0 table thead tr th {
  background: #ffffff;
  padding: 0px !important;
}

.bg-none {
  background: #278dec !important;
}

.dropdown-toggle-custom,
.dropdown-toggle-custom::after,
.dropdown-toggle-custom:hover {
  background: none !important;
  border: none !important;
}

.dropdown-toggle-custom:active {
  background: transparent !important;
}

.icon-custom {
  width: 24px;
  height: 24px;
}

.dropdownPosition {
  display: flex;
  right: 250px;
  top: 42px;
  position: absolute;
}

.background-active {
  background: #d3eaff !important;
  color: #278dec !important;
  border-color: #d3eaff !important;
  width: max-content !important;
}

.dataTableWrapper .maxWidthTable div[role=rowgroup] div[role=row] div[role=cell]:nth-child(5) {
  display: grid;
}

.d-flex-row {
  display: flex;
  justify-content: center;
  gap: 14px;
}

.p-relative-deleteIcon {
  position: relative !important;
  top: 6px !important;
}

.fs-patientName {
  position: relative;
  bottom: 0px;
  font-size: 9px !important;
  color: #777d80 !important;
}

.publishScheduleText {
  color: #777d80;
  font-size: 16px;
}

.futureTempInput label {
  color: #777d80;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-align: left;
}

.rosterTableContent table tr .usersNameInfo {
  text-align: left;
  justify-content: flex-start;
  cursor: pointer;
}

.actionRows span img {
  width: 22px;
  height: 22px;
}

.actionRows {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0;
}

.d-flex-start {
  display: flex;
  justify-content: flex-start;
  color: #3c4549 !important;
}

.rotaContentCell {
  padding: 4px 10px !important;
  position: relative !important;
  min-width: 100px !important;
}

.d-flex-space-betweens {
  display: flex;
  justify-content: space-between;
}

.top- {
  top: 5px;
  position: relative;
}

.disableButton {
  border-color: #b1b7ba !important;
  background: #b1b7ba !important;
}

.cursor-default {
  cursor: default !important;
}

.groupInfoImage {
  width: 158px;
  height: auto;
}

.d-flex-space-between- {
  display: flex;
  justify-content: space-between;
}

.mt-minus {
  margin-top: -15px !important;
}

.min-height {
  height: 600px !important;
}

.object-fit-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.sendMsgContainerSpan {
  width: 100%;
  bottom: 3px;
  left: 5px;
  position: relative;
}

.sendMessageEmojiChildIcon {
  top: 7px;
  height: max-content;
  position: relative;
}

.sendMessageEmojiChildSubmitIcon {
  top: 4px;
  right: 7px;
  height: max-content;
  position: relative;
}

.textMessageContainerClass {
  word-break: break-word;
  width: 50%;
}

.d-flex-wrap {
  display: flex !important;
  flex-wrap: wrap !important;
}

.msgFooterIconsChild {
  background: white !important;
}

.editGroupMembersContainer .userInfo {
  font-size: 10px;
}
.editGroupMembersContainer .userImage img {
  width: 36px !important;
  height: 34px !important;
}
.editGroupMembersContainer .crossImageContainer {
  display: block;
  cursor: pointer;
  bottom: 10px;
  position: relative;
  left: 13px;
}
.editGroupMembersContainer .crossImageContainer .cross {
  color: red;
  font-size: 13px;
}

.fs-10 {
  font-size: 10px;
}

.forwardMessageModelContainer {
  position: relative;
}
.forwardMessageModelContainer .childContainer {
  display: grid;
  grid-template-columns: 5fr 1fr;
}
.forwardMessageModelContainer .childContainer .avatarContainer {
  margin: 3px;
}
.forwardMessageModelContainer .childContainer .avatarContainer .userImage img {
  width: 32px !important;
  height: 32px !important;
  margin-right: 4px;
}
.forwardMessageModelContainer .childContainer .sendButtonContainer {
  display: block;
  position: relative;
  width: max-content;
}
.forwardMessageModelContainer .childContainer .sendButtonContainer .sendButton {
  display: block;
  position: relative;
  margin: 12px;
}

.ml-6 {
  margin-left: 6px !important;
}

.groupMembersListingContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 9px;
}
.groupMembersListingContainer .listingUserRecordsContainer .userInfo {
  gap: 7px;
}
.groupMembersListingContainer .userImage img {
  width: 32px !important;
  height: 32px !important;
  margin-right: 4px !important;
}

.allUsersListingContainer {
  left: 7px;
  position: relative;
}
.allUsersListingContainer .childContainer .avatarContainer {
  margin: 3px;
  display: flex;
  position: relative;
  place-items: center;
  gap: 2px;
}
.allUsersListingContainer .childContainer .avatarContainer .userImage img {
  width: 32px !important;
  height: 32px !important;
  margin-right: 4px;
}
.allUsersListingContainer .childContainer .avatarContainer .userInfo .nameBlock {
  color: #777d80;
}
.allUsersListingContainer .childContainer .sendButtonContainer {
  display: block;
  position: relative;
  width: max-content;
}
.allUsersListingContainer .childContainer .sendButtonContainer .sendButton {
  display: block;
  position: relative;
  margin: 12px;
}

.activeMessageRow {
  cursor: pointer !important;
  background: rgba(99, 180, 255, 0.2) !important;
}

.nameBlockContainer {
  position: relative;
  top: 4px;
  font-size: small;
  color: #3c4549 !important;
}
.nameBlockContainer .paragraphBlock {
  display: flex;
  position: relative;
  bottom: 4px;
  font-size: 13 !important;
  color: #777d80 !important;
}

.chatHeaderNameBlock {
  position: relative;
  top: 0px;
  font-size: small;
  color: #3c4549 !important;
}

.chatMessageTexts {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 0px;
  font-size: small;
  color: #3c4549 !important;
}

.uploadGroupImageIcon img {
  width: 140px !important;
  height: 140px !important;
}

.editMessageCrossContainer {
  cursor: pointer;
  width: 40px;
  position: absolute;
  top: 5px;
  left: 88%;
  display: grid;
}

.messagePanelContainer {
  font-size: 12px !important;
  padding: 0px 15px !important;
  max-height: calc(100vh - 330px) !important;
}

.msgSeenUnseenContainer {
  display: flex;
  gap: 10px;
  top: 4px;
  left: -10px !important;
  text-align: center;
  position: relative;
  justify-content: flex-end;
}
.msgSeenUnseenContainer .child {
  display: grid;
  place-items: center;
}
.msgSeenUnseenContainer .child img {
  width: 15px;
  max-width: 15px;
}

.color-black {
  color: black !important;
}

.groupMessageSeenUsersProfilesElementsContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
}
.groupMessageSeenUsersProfilesElementsContainer .imageChainBlock {
  display: flex;
  position: relative;
  width: max-content;
  left: 11px;
  padding: 3px;
}
.groupMessageSeenUsersProfilesElementsContainer .imageChainBlock .chainImage.firstView img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d8d9db;
  border: 2px solid #ffffff;
}
.groupMessageSeenUsersProfilesElementsContainer .imageChainBlock .chainImage img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background: #d8d9db;
  border: 2px solid #ffffff;
}

.groupMessageSeenUsers .imageChainBlock {
  display: flex;
  position: relative;
  width: max-content;
  left: 11px;
  padding: 3px;
}
.groupMessageSeenUsers .imageChainBlock .chainImage img {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #d8d9db;
  border: 2px solid #ffffff;
}

.profileCounts {
  top: -1px;
  right: 6px;
  font-size: smaller;
  position: relative;
}

.border-bottom {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding-bottom: 5px;
}

.profileWidth {
  margin: 1px !important;
  width: max-content !important;
  left: 4px !important;
  position: relative !important;
}

.d-flex-space-betweenn {
  display: flex;
  /* width: max-content; */
  justify-content: space-between;
}

.textAlign {
  display: flex;
  place-items: center;
  color: #777d80;
}

.chainBlocks- {
  display: flex;
  /* width: max-content; */
  justify-content: flex-end;
}

.d-flex-container {
  display: flex;
  justify-content: flex-end;
  place-items: center;
  bottom: 6px;
  position: relative;
}

.f-s13 {
  font-size: 13px !important;
}

.color-black {
  color: #777d80;
}

.rotaContentCell span.plusIcon img {
  width: 14px;
  height: 14px;
}

.rotaContentCell span.plusIcon {
  left: 66px;
  gap: 2px;
  position: absolute;
  display: flex;
  justify-content: center;
}

.rotaImageWidth {
  width: 14px;
  height: 14px;
  max-width: 14px;
}

.dropDownRota p {
  font-size: 13px !important;
  padding: 0 !important;
}

.d-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}

.popOver {
  width: 22% !important;
  min-width: 22% !important;
}

.rotaTableStaffPatientViewContainer .thChildContainer {
  display: flex;
  place-items: center;
}
.rotaTableStaffPatientViewContainer .thChildContainer img {
  width: 24px !important;
  height: 24px !important;
}

.position-left {
  right: 9px !important;
  position: relative !important;
}

.bg-- {
  background-color: #f6f6f6 !important;
}

.c-red {
  color: red !important;
}

.fs-10 {
  font-size: 10px !important;
}

.fs-smaller {
  font-size: small !important;
}

.gap-1 {
  display: flex;
  justify-content: space-between;
}

.actionRow span img {
  width: 19px !important;
  height: 19px !important;
}

.mb-bottom-top {
  margin-bottom: 15px !important;
}

.scheduleTableGap .contentcell {
  border-radius: 5px !important;
  padding: 2px 1px !important;
  margin: 5px 0 !important;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(193, 195, 200, 0.5);
}

.selectWeekWidth {
  min-width: 185px !important;
}
.selectWeekWidth div {
  font-size: 12px !important;
  color: black !important;
}

.selectWeekWidthRota {
  min-width: 125px !important;
}
.selectWeekWidthRota div {
  font-size: 12px !important;
  color: black !important;
}

.lightBlue {
  background-color: #278dec;
}

.lightYellow {
  background-color: #ffb000;
}

.time.darkBlack {
  display: flex;
}

.color-white {
  color: white;
}

.blur {
  filter: blur(1px);
}

.onOffSwitchContainer {
  display: flex;
  justify-content: space-between;
  gap: 9px;
}
.onOffSwitchContainer label {
  font-size: small;
  font-weight: 500;
}

.onOffSwitchContainer1 {
  display: flex;
  justify-content: space-between;
  gap: 9px;
}
.onOffSwitchContainer1 label {
  font-size: small;
  font-weight: 500;
}

.selectBox select {
  font-size: small !important;
}

.bg-white {
  background-color: white !important;
}

.roster-calendar-container {
  display: flex;
  align-items: center;
}
.roster-calendar-container .calenderImgContainer {
  position: relative;
  right: 25px;
}
.roster-calendar-container input {
  font-size: 13px !important;
}

.roster-calendar-day {
  font-size: 16px;
  color: #555;
}

.react-datepicker {
  left: 70px;
}

.gridTemplateColumns {
  display: flex;
  justify-content: space-between;
}

@media print {
  .no-print {
    display: none !important;
  }
}

