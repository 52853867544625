.quizList {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
        margin: 10px 0 20px;
        font-weight: bold;

        ul {
            margin-left: 20px;
            padding-left: 20px;
            list-style-type: disc;

            li {
                margin: 5px 0;
                font-weight: normal;
            }
        }
    }
}
