.FTCAuthenticationWrapper {
  a {
    color: #3c4549;
    text-decoration: none;
  }
  h3 {
    font-size: 22px;
    margin-top: 20px;
  }
  .authenticationContainer {
    display: flex;
    flex-direction: row;
    height: 100vh;
  }
  .column {
    flex: 1;
    padding: 20px;
  }
  .left-column {
    background: url("../images/authentication/authenticationBg.jpg") no-repeat
      center left;
    background-size: cover;
    padding-top: 50px;
    h2 {
      font-size: 30px;
      margin-bottom: 10px;
    }
    p {
      font-size: 18;
    }
  }
  .right-column {
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    form {
      margin-top: 10px;
    }
    .login-form {
      max-width: 400px;
      padding: 40px;
      border-radius: 5px;
      width: 100%;
    }
  }
  .text-right {
    text-align: right;
  }
  .text-center {
    text-align: center;
  }
  .logo {
    margin-bottom: 20px;
    max-width: 100%;
  }
  .form-group {
    position: relative;
    margin-bottom: 20px;
    input {
      width: 100%;
      padding: 12px 10px;
      border: 1px solid #ccc;
      border-radius: 5px;
      color: #3c4549;
      &:active,
      &:focus {
        border-color: #007bff;
        outline: none;
        border: 1px solid #ccc;
      }
      &:valid,
      &:focus {
        + label {
          top: -8px;
          left: 5px;
          font-size: 12px;
          padding: 0 5px;
        }
      }
    }
    input:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
      -webkit-text-fill-color: #3c4549; /* Set the text color to your desired color */
    }
    input[type="checkbox"] {
      width: auto;
      position: relative;
      top: 2px;
    }
    .button {
      width: 100%;
      padding: 12px 10px;
      background-color: $primaryColor;
      color: #fff !important;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      font-size: 16px;
      display: block;
    }
    a.button {
      @include flexbox(row, center, center, nowrap);
      img {
        margin-right: 5px;
      }
    }
    label.floatingLabel {
      display: block;
      color: #777d80;
      position: absolute;
      top: 12px;
      left: 5px;
      pointer-events: none;
      transition: 0.2s ease-out;
      background: #ffffff;
      padding: 0 10px;
    }
  }
  .remember-authentication {
    display: flex;
    flex-direction: row;
    margin: 17px 0 !important;
    div {
      width: 50%;
    }
    label {
      position: relative;
      top: 0;
      left: 0;
    }
  }
  .successIcon {
    img {
      width: 120px;
      margin: 20px auto;
      display: block;
    }
  }
  .content {
    color: #828282;
  }
  .password-toggle {
    position: relative;
    display: inline-block;
    width: 100%;
  }

  .password-toggle-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    cursor: pointer;
  }
  @include respond-to(mlarge) {
    .authenticationContainer {
      flex-direction: column;
    }
    .column {
      padding: 10px;
    }
    .left-column {
      display: none;
    }
    .right-column {
      justify-content: center;
    }
    .login-form {
      border: 1px solid #ccc;
    }
  }
}
.otpContainer {
  .form-group {
    @include flexbox(row, space-between, center, nowrap);
    gap: 10px;
    input {
      text-align: center;
    }
  }
}
