// Branding Variables
$primaryColor: #278dec;
$primaryLightColor: #63b4ff;
$primaryDarkColor: #006bcf;
$secondaryColor: #57cc98;
$secondaryLightColor: #79d6ad;
$secondaryLightestColor: #c8ffd9;
$secondaryDarkColor: #46a37a;
$TertiaryColor: #cde0e8;
$QuaternaryColor: #faa308;
$QuaternaryLightColor: #ffebb7;
$white: #ffffff;
$black: #000000;
$greyColor: #666;
$greyDarkColor: #333;
$greyLightColor: #c1c3c8;
$greyLightestColor: #f6f6f6;
$lightGrey: #777d80;
$greyImageBg: #d8d9db;
$lemonGreenColor: #77bf00;
$green: #03b93f;
$redPinkColor: #d24949;
$redColor: #da0b0b;
$redLightColor: #fff2f2;
$redLighterColor: #f9bfbf;
$lightBlueColor: #278dec;
$blueLightColor: #d3eaff;
$modifiedBlack: #777d80;
$yellowColor: #ffb000;
$textHeadingDarkBlackColor: #3c4549;

// General Variables
$fullHeight: 100vh;

// FlexBox Mixin
//  @include flexbox(row, flex-start, normal);
@mixin flexbox($direction: row, $justify-content: flex-start, $align-items: stretch, $flex-wrap: nowrap) {
  display: flex;
  flex-direction: $direction;
  justify-content: $justify-content;
  align-items: $align-items;
  flex-wrap: $flex-wrap;
}

@mixin whiteShadowBg {
  background: $white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
}

@mixin whiteShadow {
  background: $white;
  padding: 10px;
  border-radius: 5px;
}
@mixin bottomBorder {
  border-bottom: 1px solid rgba($greyLightColor, 0.5);
}
// Vertically and Horizontaly Centered align
@mixin center-element {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin nameSpace {
  width: 25%;
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 20px 0;
  .userBox {
    @include flexbox(row, flex-start, center, nowrap);
    height: 75px;
    border-bottom: 1px solid $greyLightColor;
    padding: 0;

    .usersNameInfo {
      padding: 10px 15px;
      position: relative;
      width: 94%;
      gap: 5px;
      .userInfo {
        width: 84%;
      }
      .userImage {
        width: 16%;
      }
      .dayTime {
        right: 20px;
      }
      .nameBlock {
        font-weight: bold;
      }
      .paragraphBlock {
        font-weight: normal;
      }
    }
    .userAction {
      height: 100%;
      width: 6%;
      background: rgba($redLightColor, 0.5);
      text-align: center;
      display: flex;
      align-items: center;
      justify-content: center;
      opacity: 0;
    }
    &:hover,
    &.active,
    &.activeChat {
      cursor: pointer;
      background: rgba($primaryLightColor, 0.2);
      .userAction {
        opacity: 1;
      }
    }
  }
}
// ClearFix
@mixin clearfix {
  &::after {
    content: "";
    display: table;
    clear: both;
  }
}

// Media Queries
$breakpoints: (
  smallest: 400px,
  small: 480px,
  smedium: 575px,
  medium: 768px,
  mlarge: 993px,
  large: 1024px,
  xlarge: 1200px,
  xllarge: 1400px
);

@mixin respond-to($breakpoint) {
  @media (max-width: map-get($breakpoints, $breakpoint)) {
    @content;
  }
}

@mixin respond-to-range($min, $max) {
  @media (min-width: map-get($breakpoints, $min)) and (max-width: map-get($breakpoints, $max)) {
    @content;
  }
}
