::-webkit-scrollbar {
  width: 6px;
  background: $greyLightestColor;
}
::-webkit-scrollbar:horizontal {
  height: 6px;
}

::-webkit-scrollbar-thumb {
  background-color: $greyLightColor;
}
html {
  background: $greyLightestColor;
}
body {
  scrollbar-width: thin;
}
* {
  transition: all 0.5s;
}
div {
  transition: font-size 0s;
}
body::-webkit-scrollbar-thumb {
  background-color: $greyLightColor;
  color: $greyDarkColor;
}
video {
  max-width: 100%;
}
p {
  font-size: 14px;
  color: $greyColor;
}
strong {
  color: $greyDarkColor;
}
a {
  color: $primaryColor;
}
li {
  font-size: 14px;
  white-space: normal;
  color: $greyColor;
  margin-bottom: 5px;
}
.text-center {
  text-align: center;
}
.visibilityHidden {
  visibility: hidden;
}
.displayNone {
  display: none;
}
.text-right {
  text-align: right;
}
.textTransformCapitalize {
  text-transform: capitalize;
}
.loadingText {
  font-size: 12px !important;
  color: $greyColor !important;
}
textarea {
  resize: none;
}
.curSorPointer {
  cursor: pointer;
}
span {
  font-size: 14px;
}
a {
  text-decoration: none;
  &:hover {
    text-decoration: none;
  }
}
table {
  tr {
    td,
    th {
      font-size: 14px;
    }
  }
}
img.loadingButtonIcon {
  width: 20px;
  height: 20px;
  margin-top: -3px;
}
.toolTip {
  font-size: 12px;
}
.pr-0 {
  padding-right: 0 !important;
}
.pl-0 {
  padding-left: 0 !important;
}
.clearfix {
  @include clearfix;
}
img.removeRow,
img.addRow {
  width: 24px;
  height: 24px;
  cursor: pointer;
}
img.addRow {
  margin-top: 20px;
}
.minHeight300 {
  min-height: 300px;
}
.minHeight250 {
  min-height: 250px;
}
.MuiBox-root {
  .fullWidthHeader {
    background: $white;
    padding: 0 20px;
    .logoBox {
      img {
        width: 120px;
      }
    }
  }
  .headerToolbar {
    background: $white;
    color: $greyDarkColor;
    padding: 0 5px;
    @include flexbox(row, space-between, center);
    .headerToolbarMenuProfile {
      @include flexbox(row, space-between, center);
      gap: 30px;
    }

    .contentHeader {
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      border-bottom: 1px solid rgba($greyLightColor, 0.5);
    }

    .profileDropdown {
      button {
        @include flexbox(row, flex-end, center);
        background: none;
        border: 0;
        color: $greyDarkColor;
        img {
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
        p {
          font-size: 14px;
          margin: 0 0 0 5px;
          text-align: left;
          color: $greyDarkColor;
          font-weight: 600;
          small {
            font-size: 12px;
            display: block;
            color: $greyColor;
            font-weight: normal;
          }
        }
        &:after {
          // margin-top: -15px;
        }
      }
    }
    .headerMenu {
      @include flexbox(row, flex-end, center, wrap);
      margin: 0;
      gap: 20px;
      li {
        list-style: none;
        font-size: 14px;
        a {
          color: $greyColor;
        }
      }
    }
    .secondaryMenuToggleIcon {
      display: none;
    }
  }
  .mainWrapper {
    background: $greyLightestColor;
    padding: 15px;
    overflow-x: hidden;
    position: relative;
    .mainContent {
      @include whiteShadowBg;
      padding: 10px;
      p {
        font-size: 14px;
        white-space: normal;
        small {
          display: block;
          margin-top: 10px;
        }
      }
      h3 {
        font-size: 18px;
        small {
          font-weight: normal;
        }
        &.viewCarePlanTitle {
          @include flexbox(row, space-between, center, nowrap);
          gap: 10px;
          width: 100%;
        }
      }
      &.headingContent {
        h3 {
          padding: 10px;
          margin: 0;
        }
      }
    }
  }

  @include respond-to(medium) {
    .headerToolbar {
      padding: 0 20px;
      .headerToolbarMenuProfile {
        position: relative;
        gap: 10px;
        .secondaryMenuToggleIcon {
          display: block;
          img {
            width: 25px;
          }
        }
      }
      .headerMenu {
        display: none;

        &.mobileSecondaryMenu {
          @include flexbox(column, space-between, flex-start, wrap);
          position: absolute;
          top: 100%;
          right: 0;
          left: 0;
          margin: 0;
          padding: 0;
          gap: 0;
          width: 200px;
          box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
          border-radius: 10px;
          li {
            padding: 5px 20px;
            border-bottom: 1px solid $greyLightestColor;
            text-align: left;
            background: $white;
            width: 100%;
            a {
              display: block;
              text-align: left;
            }
          }
        }
      }
    }
  }
}
table {
  &.tableStriped {
    tbody {
      tr {
        &:nth-child(odd) {
          td {
            background: #f8f9fb !important;
          }
        }
      }
    }
  }
}
.tableText {
  padding-left: 20px;
  @include flexbox(row, space-between, start);
  gap: 10px;
  p {
    margin: 20px 0;
    width: 95%;
  }
  &.warning {
    border-left: 4px solid $QuaternaryColor;
  }
  &.success {
    border-left: 4px solid $green;
  }
  &.danger {
    border-left: 4px solid $redColor;
  }

  .editIcon {
    margin: 20px 0;
  }
  a {
    color: $greyDarkColor;
  }
}
.edit,
.close,
.delete,
.restore {
  cursor: pointer;
}
.restore {
  img {
    width: 25px;
    height: 25px;
  }
}
.btn-success {
  background: $green;
  border: 1px solid $green;
}
body.modal-open {
  .mainWrapper.MuiBox-root {
    position: relative;
    z-index: 9;
  }
}
.modal.show {
  .modal-dialog {
    max-height: calc(100% - 56px);
    margin: 0 auto;
    width: 96%;
    top: 50%;
    transform: translateY(-45%);
    .modal-header {
      .btn-close {
        filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(301deg) brightness(102%)
          contrast(101%);
        opacity: 1;
        &:hover,
        &:active,
        &:focus {
          outline: none;
          box-shadow: none;
        }
      }
    }
    .modal-content {
      // top: 50%;
      // transform: translateY(-50%);
      .modal-body {
        max-height: 500px;
        overflow-y: auto;
      }
    }
  }
}
.headerToggleTitle {
  @include flexbox(row, flex-start, center);
  gap: 0;
  button {
    margin-right: 0;
  }
}
.maxWidthTable {
  .preloader-container {
    margin-top: 30px;
  }
}
.pbsPlan {
  .dataTableWrapper {
    .rdt_TableHeader {
      overflow: visible;
      .OWguJ {
        background: transparent;
      }
    }
    .maxWidthTable {
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="cell"] {
            > div {
              width: 100%;
            }
            &:nth-child(2) {
              // padding-left: 0;
            }
            .usersNameInfo {
              min-width: 500px;
            }
          }
        }
      }
    }
  }
  &.supervisionBlocks {
    .maxWidthTable {
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="cell"] {
            &:nth-child(2),
            &:nth-child(3),
            &:nth-child(4) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  &.supervisionDetailTable {
    .rdt_TableHeader {
      width: 100%;
      float: none;
      div {
        font-weight: bold;
        font-size: 16px;
      }
    }
    .maxWidthTable {
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="cell"] {
            &:nth-child(1) {
              padding-left: 0;
            }
          }
        }
      }
    }
  }
  &.pbsPlanUpdated {
    .dataTableWrapper {
      .maxWidthTable {
        div[role="rowgroup"] {
          div[role="row"] {
            div[role="cell"] {
              > div {
                padding-top: 15px;
                padding-bottom: 15px;
                .contentBox {
                  p {
                    font-size: 14px;
                    font-weight: normal;
                    color: $greyDarkColor;
                    margin: 10px 5px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.pbsFieldWrapper {
  border-bottom: 2px solid rgba($greyLightColor, 0.5);
  padding-bottom: 30px;
  margin-bottom: 30px;
  .alert {
    margin: 0;
    border-radius: 0;
    @include flexbox(row, space-between, center, wrap);
    h3 {
      padding: 0 !important;
      margin: 0;
      border: 0 !important;
    }
    span {
      font-size: 12px;
    }
    &.mobpBg {
      background-color: #006400;
      h3 {
        color: $white !important;
      }
    }
    &.spBg {
      background-color: rgb(241, 194, 50);
      h3 {
        color: $black !important;
      }
    }
    &.rsBg {
      background-color: rgb(204, 0, 0);
      h3 {
        color: $black !important;
      }
    }
    &.pisrBg {
      background-color: rgb(60, 120, 216);
      h3 {
        color: $black !important;
      }
    }
  }
  .jodit-react-container {
    .jodit-container {
      border-radius: 0;
      &.mobp {
        .jodit-workplace {
          background: rgba(182, 215, 168, 0.5);
        }
      }
      &.secondaryPrevention {
        .jodit-workplace {
          background: rgba(255, 229, 153, 0.5);
        }
      }
      &.reactiveStartegies {
        .jodit-workplace {
          background: rgba($redLightColor, 0.3);
        }
      }
      &.pisr {
        .jodit-workplace {
          background: rgba($primaryColor, 0.2);
        }
      }
    }
  }
  .pbsField {
    @include flexbox(row, space-between, flex-end, wrap);

    padding-bottom: 10px;
    margin-bottom: 10px;

    button {
      font-size: 12px;
    }
  }
}
.mobpContentBg {
  background: rgba(182, 215, 168, 0.5);
}
.spContentBg {
  background: rgba(255, 229, 153, 0.5);
}
.rsContentBg {
  background: rgba($redLightColor, 0.3);
}
.pisrContentBg {
  background: rgba($primaryColor, 0.2);
}
.viewEditorWrapper {
  position: relative;
  .usersNameInfo {
    align-items: flex-start;
  }
  .viewEditorContent {
    padding: 20px;
    header.createAndModifyStatus {
      @include flexbox(row, space-between, center, wrap);
      border-bottom: 1px solid rgba($greyLightColor, 0.5) !important;
      padding: 0 0 20px;
      margin-bottom: 20px;
      .enteredBy {
        margin-bottom: 0;
      }
    }
    &.qualitySurveyEditorContent {
      .mainContentHeader {
        padding: 0 0 10px;
      }
      .personalDetailContent {
        @include flexbox(row, space-between, flex-start, wrap);
        gap: 20px;
        padding: 20px 0 0;
      }
    }
  }
}

.usersNameInfo {
  display: block;
  font-weight: bold;
  display: flex;
  align-items: center;
  img {
    margin: 5px 5px 7px 0;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background: rgba($greyLightColor, 0.5);
    &.isDriver {
      background: transparent;
      width: 19px;
      height: 18px;
      margin: -20px 0 0 -17px;
      border: 2px solid #fff;
    }
  }
  .iconImage {
    img {
      width: 20px;
      height: 20px;
      border-radius: 0;
      background: transparent;
    }
  }
  .userInfo {
    display: flex;
    flex-direction: column;
    .nameBlock {
      font-weight: normal;
    }
    .dayTime {
      position: absolute;
      top: 5px;
      right: 5px;
      font-weight: normal;
      font-size: 10px;
    }
  }
  &.shiftLogs {
    border-bottom: 0 !important;
    img {
      width: 25px !important;
      height: 25px !important;
    }
    span {
      font-size: 12px !important;
    }
    .dateTime {
      font-weight: normal;
    }
  }
}
.userInfoWrapper {
  @include flexbox(row, space-between, flex-start, nowrap);
  .btn-primary {
    background: transparent;
    padding: 0;
    margin: 0;
    border: 0;
    text-align: right;
    &:active,
    &:focus,
    &:hover {
      background: none;
    }
    img {
      margin: 0;
      background: none;
      width: 15px;
      height: 15px;
      display: block;
    }
  }
}
.toolTipInfo {
  @include whiteShadowBg;
  background-color: $white;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  color: $greyDarkColor;
  border-radius: 4px;
  font-size: 14px;
  padding: 10px;
  width: 200px;
  opacity: 1 !important;
  border: 0 !important;
}

.toolTipInfo .tooltip-inner {
  background-color: $white;
  color: $greyDarkColor;
  border-radius: 4px;
  font-size: 14px;
  text-align: left;
  opacity: 1 !important;
  border: 0 !important;
}

.toolTipInfo .tooltip-arrow::before {
  border-right-color: $white !important;
}
.careNoteToolTip.show.tooltip {
  z-index: 9999;
}
.preloader-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: auto;
  position: absolute;
  top: 50%;
  z-index: 9999;
  // background: $white;
  transform: translateY(-50%);
  max-width: 100%;
  left: 50%;

  .preloader-spinner {
    border: 4px solid rgba(0, 0, 0, 0.1);
    border-left-color: #333;
    animation: spin 1s linear infinite;
    width: 30px;
    height: 30px;
    border-radius: 50%;
  }

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}
.editIcon {
  background: url(../images/icons/editBlock.svg) no-repeat center center transparent;
  background-size: 20px;
  display: block;
  float: right;
  height: 20px;
  width: 20px;
  cursor: pointer;
}
.btn.btn-sm.btn-outline-primary {
  &.editButtonGrid {
    border: 0;
    .editIconGrid {
      display: block;
      float: right;
      height: 18px;
      width: 18px;
      img {
        margin-top: -4px;
        filter: invert(45%) sepia(96%) saturate(1781%) hue-rotate(188deg) brightness(94%) contrast(96%);
      }
    }
    &:hover {
      background: transparent;
      .editIconGrid {
        img {
          filter: invert(32%) sepia(81%) saturate(7491%) hue-rotate(199deg) brightness(98%) contrast(101%);
        }
      }
    }
  }
}

.scheduleBlocks {
  .cards {
    @include whiteShadowBg;
    padding: 10px 10px 20px 10px;

    margin: 8px 0 20px 0;

    .cardContent {
      text-align: center;
      .contentIcon {
        img {
          width: 40px;
          height: 40px;
        }
      }
      h3 {
        font-size: 22px;
        small {
          display: block;
          font-size: 14px;
          color: $greyColor;
          margin: 10px 0;
          font-weight: normal;
        }
      }
    }
  }
  @include respond-to(mlarge) {
    .cards {
      .cardContent {
        h3 {
          font-size: 18px;
        }
      }
    }
  }
  @include respond-to(medium) {
    margin-top: 10px;
    .cards {
      margin: 0 auto 20px;
      max-width: 250px;
    }

    p.currentStatus {
      max-width: 250px;
      margin: 0 auto 20px;
    }
  }
  &.trainingBlocks {
    .clearfix {
      height: 0;
    }
  }
}

.dashboardBlocksWrapper {
  @include whiteShadowBg;
  width: 100%;
  max-width: 1000px;
  margin: 50px auto;
  padding: 60px;
  border-radius: 10px;
  h2 {
    margin-bottom: 60px;
    font-size: 30px;
  }

  .dashboardBlock {
    @include flexbox(row, center, center);
    text-align: center;
    height: 165px;
    border: 1px solid rgba($greyLightColor, 0.5);
    border-top: 0;
    border-left: 0;
    &:hover {
      @include whiteShadowBg;
    }

    img {
      width: 40px;
      height: 40px;
      margin-bottom: 10px;
    }
    h3 {
      font-weight: normal;
      font-size: 16px;
      font-family: "Proxima Nova Lt";
    }
  }
  .col-md-3.col-sm-6 {
    padding: 0;
    &:nth-child(5),
    &:nth-child(6),
    &:nth-child(7),
    &:nth-child(8) {
      .dashboardBlock {
        border-bottom: 0;
      }
    }
    &:nth-child(4n) {
      .dashboardBlock {
        border-right: 0;
      }
    }
  }
  @include respond-to(mlarge) {
    .dashboardBlock {
      h3 {
        font-size: 16px;
      }
    }
  }
  @include respond-to(mlarge) {
    .dashboardBlock {
      h3 {
        font-size: 12px;
      }
    }
  }
  @include respond-to(medium) {
    .col-md-3.col-sm-6 {
      padding: 0;
      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        .dashboardBlock {
          border: 1px solid rgba($greyLightColor, 0.5);
        }
      }
      &:nth-child(4n) {
        .dashboardBlock {
          border: 1px solid rgba($greyLightColor, 0.5);
          border-left: 0;
        }
      }

      &:nth-child(5),
      &:nth-child(6),
      &:nth-child(7),
      &:nth-child(8) {
        .dashboardBlock {
          border-left: 0;
        }
      }
      &:nth-child(2n) {
        .dashboardBlock {
          border-right: 0;
        }
      }
    }
  }
  @include respond-to(smedium) {
    .dashboardBlock {
      max-width: 250px;
      margin: 0 auto;
      padding: 30px 0;
      height: auto;
      border: 0 !important;
      border-bottom: 1px solid rgba($greyLightColor, 0.5) !important;
    }
  }
}

.profileDetailWrapper {
  .profileDetailBlock {
    background: $white;
    padding: 20px;
    border-radius: 5px;
    position: relative;

    .userAvatar {
      text-align: center;
      padding-bottom: 30px;
      border-bottom: 1px solid rgba($greyLightColor, 0.5);
      img {
        border-radius: 50%;
        width: 150px;
        height: 150px;
        max-width: 100%;
        max-height: 100%;
        background: rgba($greyLightColor, 0.5);
      }
      .avatarAction {
        @include flexbox(row, center, center);
        gap: 5px;
        position: relative;
        top: -20px;
        img {
          border: 1px solid $white;
          width: 30px;
          height: 30px;
        }
        span {
          cursor: pointer;
        }
      }
      h3 {
        font-size: 20px;
      }
    }
    .userDetail {
      font-size: 14px;
      color: $greyColor;
      padding: 20px;
      p {
        margin-bottom: 20px;
        small {
          display: block;
        }
      }
    }
  }
  .userPayrollDetail {
    .scheduleBlocks {
      .row {
        align-items: end;
      }
      .cards {
        margin-top: 0;
      }
      p.currentStatus {
        @include whiteShadowBg;
        padding: 10px 15px;
        font-weight: bold;
        @include flexbox(row, space-between, center);
        font-size: 14px;
        span {
          background: $green;
          display: block;
          height: 10px;
          width: 10px;
          border-radius: 50%;
          margin-left: 5px;
        }
      }
    }
  }

  @include respond-to(xllarge) {
    .userPayrollDetail {
      .scheduleBlocks {
        p.currentStatus {
          font-size: 12px;
        }
      }
    }
  }
  @include respond-to(medium) {
    .profileDetailBlock {
      max-width: 300px;
      margin: 20px auto;
    }
  }
}

.headerContainerSearchAction {
  @include flexbox(row, space-between, center);
  @include whiteShadowBg;
  flex-direction: row-reverse;
  margin-bottom: 15px;
  padding: 15px 15px;
  .actionButtons {
    @include flexbox(row, space-between, center);
    gap: 10px;
  }
  .img-btn {
    img {
      width: 20px;
      height: 20px;
    }
    &:hover {
      img {
        filter: brightness(0) invert(1);
      }
    }
  }
  @include respond-to(medium) {
    @include flexbox(column, center, flex-start);
    gap: 10px;
    .searchContainer {
      width: 100%;
    }
  }
}
.headerContainerUserWithStatus {
  flex-direction: row;
  position: relative;
  z-index: 2;

  .usersNameInfo {
    .userInfo {
      .nameBlock {
        font-weight: 600;
      }
      .infoBlock {
        font-weight: normal;
        .statusCircle {
          float: right;
          margin: 5px 0 0 10px;
          width: 8px;
          height: 8px;
        }
      }
    }
  }
  @include respond-to(medium) {
    display: block;
    .usersNameInfo {
      margin-bottom: 10px;
    }
  }
  @include respond-to(smedium) {
    .actionButtons {
      display: block;
      .dropdown {
        margin-top: 10px;
      }
    }
  }
}
.contentOverview {
  @include whiteShadowBg;

  h3 {
    font-size: 18px;
    border-bottom: 1px solid rgba($greyLightColor, 0.5);
    padding: 20px;
    margin: 0;
  }
}
.payrollOverview {
  // min-width: 1000px;
  .calendarOverview {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    .headerRow {
      .dayCell {
        font-weight: bold;
        font-size: 14px;
        padding: 15px 0 !important;
        text-align: center !important;
      }
    }
    .dayRow {
      @include flexbox(row, space-between, center);
      &:nth-child(even) {
        background: $greyLightestColor;
      }
      .dayCell {
        border: 1px solid rgba($greyLightColor, 0.5);
        border-top: 0;
        border-right: 0;
        width: 100%;
        text-align: left;
        padding: 0 5px;
        min-width: 130px;
        min-height: 56px;
        &:last-child {
          border-right: 1px solid rgba($greyLightColor, 0.5);
        }

        &.holidayCell {
          background: transparent;
          .contentcell {
            background: transparent;
            box-shadow: none;
          }
        }
        &.sleepCell {
          background: transparent;
          .contentcell {
            background: transparent;
            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-shadow: none;
          }
        }
      }
    }
  }
}
.contentcell {
  border-radius: 5px;
  padding: 5px 5px;
  margin: 5px 0;
  background: $white;
  box-shadow: 0 0 3px rgba($greyLightColor, 0.5);
  .statusBall {
    display: block;
    float: right;
    height: 6px;
    width: 6px;
    background: $greyColor;
    border-radius: 50%;
  }
  &.greenCell {
    border-left: 3px solid $green;
    .statusBall {
      background: $green;
    }
  }
  &.redCell {
    border-left: 3px solid $redColor;
    .statusBall {
      background: $redColor;
    }
  }
  &.greyCell {
    border-left: 3px solid $greyColor;
  }
  &.publishedCell {
    border-left: 3px solid $lightBlueColor;
    .statusBall {
      background: $lightBlueColor;
    }
  }
  &.savedCell {
    border-left: 3px solid $yellowColor;
    .statusBall {
      background: $yellowColor;
    }
  }
  &.futureCell {
    border-left: 3px solid $secondaryLightestColor;
  }
  &.holidayCell {
    background: none;
  }
  span {
    display: block;
  }
  .time {
    font-size: 12px;
    font-weight: bold;
  }
  .type {
    font-size: 10px;
  }
  .request {
    font-size: 12px;
    font-weight: bold;
  }
  .requestStatus {
    font-size: 12px;
  }
}

.statusButton {
  border-radius: 20px;
  padding: 5px 20px;
  font-size: 13px;
  text-transform: capitalize;
  &:hover,
  &:focus {
    outline: 0;
  }
  &.btn-danger,
  &.Inactive {
    background: $redLightColor;
    color: $redColor;
    border-color: $redLightColor;
  }
  &.btn-success,
  &.future {
    background: $secondaryLightestColor;
    color: $secondaryDarkColor;
    border-color: $secondaryLightestColor;
  }
  &.btn-warning,
  &.saved {
    background: $QuaternaryLightColor;
    color: $QuaternaryColor;
    border-color: $QuaternaryLightColor;
  }
  &.active {
    background: $blueLightColor !important;
    color: $lightBlueColor !important;
    border-color: $blueLightColor !important;
  }
  .statusText {
    font-size: 13px;
  }
}
select.statusButton {
  padding: 5px 10px;
  &:disabled {
    background: rgba($greyColor, 0.3);
    color: $black;
    border-color: rgba($greyColor, 0.3);
    opacity: 0.3;
  }
}
.actionColumns {
  @include flexbox(row, center, center);
  gap: 10px;
  span {
    cursor: pointer;
  }
  .duplicate,
  .assignRole,
  .view {
    img {
      width: 25px;
      height: 25px;
    }
  }
}

.dataTableWrapper {
  .actionColumns {
    @include respond-to(mlarge) {
      flex-wrap: wrap;
      display: block;
      img {
        width: 20px;
        height: 20px;
        margin: 3px;
      }
    }
  }
}
.viewIcons {
  background: $primaryColor;
  border-radius: 50%;
  img {
    filter: brightness(0) invert(1);
    width: 23px;
    height: 23px;
    padding: 2px;
  }
}
.incidents {
  .headerContainerSearchAction {
    box-shadow: none;
    padding: 10px;
    .searchContainer {
      @include flexbox(row, flex-start, center, wrap);
      gap: 10px;
      width: 75%;
    }
  }
  .mainContentText {
    padding: 20px;
    min-height: calc(100vh - 200px);
    h3 {
      border-bottom: 1px solid rgba($greyLightColor, 0.5);
      padding-bottom: 20px;
    }
  }

  &.consentCapacityAddNew {
    // margin-top: 20px;
    .formBlockContent {
      padding-bottom: 0;
    }
    .formActionButtons {
      margin-top: 0;
      margin-bottom: 20px;
    }
  }

  @include respond-to(medium) {
    .headerContainerSearchAction {
      .searchContainer {
        display: block;
        width: 100%;
        .searchTextBox {
          margin-bottom: 10px;
          input,
          select {
            min-width: 100%;
          }
        }
      }
    }
    .mainContentText {
      padding: 10px;
    }
  }
  &.mainContentHeightAuto {
    .mainContentText {
      min-height: auto;
    }
  }
}

.dropdown {
  .headerDropDownToggle {
    background: $primaryColor !important;
    border: 1px solid $primaryColor !important;

    &:hover {
      background: $primaryDarkColor !important;
    }
  }
  &.show {
    .dropdown-menu.show {
      padding: 0;
      font-size: 12px;
      overflow: hidden;
      width: 100%;
      max-width: 200px;
      border-radius: 0;
      border: 0;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      background: #fff !important;
      z-index: 9;
      a {
        padding: 10px 10px;
        &:hover {
          background: rgba($primaryLightColor, 0.2);
        }
        &:active {
          color: $greyDarkColor;
        }
      }
    }
  }
}
.chainBlocks {
  position: relative;
  @include flexbox(row, flex-start, center);
  margin-left: 14px;
  .imageChainBlock {
    margin: 5px 0 5px -14px;
    width: 32px;
    height: 32px;
    position: relative;

    img {
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: $greyImageBg;
      border: 2px solid $white;
    }
  }
  .moreUsersCounts {
    margin-left: 15px;
  }
}

.training {
  .addTrainingText {
    h4 {
      font-size: 17px;
    }
  }
}

.addNewCandidate {
  @include whiteShadowBg;
  @include flexbox(row, center, center);
  width: 100%;
  text-align: center;
  a {
    font-size: 16px;
    color: $primaryColor;
    font-family: "Proxima Nova Lt";
    img {
      width: 40px;
      height: 40px;
    }
    span {
      display: block;
      margin-top: 10px;
    }
  }
  @include respond-to(medium) {
    height: auto !important;
    margin-bottom: 15px;
    padding: 20px;
  }
}
.color-black {
  color: $black;
}
.archievedCandidates {
  @include whiteShadowBg;
  padding: 20px;
  margin-top: 20px;
  font-size: 14px;
  .tabsHeader {
    @include flexbox(row, space-between, flex-start);
    flex-direction: row-reverse;
    border-bottom: 1px solid rgba($greyLightColor, 0.5);
  }
  .nav.nav-tabs {
    border-bottom: 0;
    .nav-link {
      border: 0;
      color: $greyDarkColor;
      &.active {
        color: $primaryColor;
        border-bottom: 3px solid $primaryColor;
      }
    }
  }
  .archievedCandidatesContent {
    @include flexbox(row, flex-start, center, wrap);
    gap: 0 10px;
    padding: 20px 0 0;
    max-height: 330px;
    overflow-y: auto;
    position: relative;
    .ProgressPadding {
      padding: 40px 0 20px;
      position: relative;
      top: 20px;
      width: 100%;
    }
    .blocks {
      width: 19%;
      border-right: 1px solid rgba($greyLightColor, 0.5);
      padding: 10px;
      &:nth-child(5n) {
        border-right: 0;
      }
      .nameBlock {
        font-weight: bold;
      }
      .infoBlock {
        font-weight: normal;
      }
    }
  }
  @include respond-to(mlarge) {
    .archievedCandidatesContent {
      .blocks {
        width: 23.5%;
        &:nth-child(4n) {
          border-right: 0;
        }
        &:nth-child(5n) {
          border-right: 1px solid rgba($greyLightColor, 0.5);
        }
      }
    }
  }
  @include respond-to(medium) {
    margin-top: 0;
    .archievedCandidatesContent {
      .blocks {
        width: 31.5%;

        &:nth-child(4n) {
          border-right: 1px solid rgba($greyLightColor, 0.5);
        }
        &:nth-child(3n) {
          border-right: 0;
        }
      }
    }
  }
  @include respond-to(smedium) {
    .tabsHeader {
      display: block;
      .actionButtons {
        margin-bottom: 20px;
      }
    }
    .archievedCandidatesContent {
      .blocks {
        width: 48%;

        &:nth-child(3n) {
          border-right: 1px solid rgba($greyLightColor, 0.5);
        }
        &:nth-child(2n) {
          border-right: 0;
        }
      }
    }
  }
  @include respond-to(small) {
    .archievedCandidatesContent {
      .blocks {
        width: 100%;
        max-width: 170px;
        margin: 0 auto;
        border-right: 0 !important;
        border-bottom: 1px solid rgba($greyLightColor, 0.5);
      }
    }
  }
}
.applicationProcessWrapper {
  .mainContent {
    background: transparent !important;
    box-shadow: none !important;
    div[role="table"] {
      background: transparent;
      div[role="row"] {
        background: transparent;
      }
    }
    .dataTableWrapper {
      .rdt_TableHeader {
        margin-bottom: 15px;
        width: 50%;
        padding: 10px 15px 14px;
        border-radius: 0 10px 0 0;
      }
      header {
        padding: 10px 15px !important;
        width: 50%;
        border-radius: 10px 0 0 0;
      }

      .maxWidthTable {
        .rdt_Table {
          .rdt_TableHead {
            border-radius: 10px 10px 0 0;
            overflow: hidden;
            .rdt_TableHeadRow {
              .rdt_TableCol {
                background: $white;
                padding: 10px 10px;
              }
            }
          }
        }
        div[role="rowgroup"] {
          div[role="row"] {
            div[role="cell"] {
              border: 0;
              padding: 0;
              &:nth-child(1) {
                border: 0 !important;
              }
              &.dahnUA div:first-child {
                overflow: unset;
              }
            }
          }
        }
      }
    }
  }
  .applicantBlock {
    // @include whiteShadowBg;
    // max-width: 96%;
    // width: 230px;
    // margin: 15px auto 0;
    // display: block;
    // padding: 20px;

    img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: rgba($greyLightColor, 0.5);
      margin-bottom: 10px;
    }
    .userInfo {
      span {
        display: block;
        margin: 5px 0 0;
        font-size: 12px;
      }
      .nameBlock {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
  @include respond-to(medium) {
    .mainContent {
      .dataTableWrapper {
        .rdt_TableHeader {
          width: 100%;
          border-radius: 10px 10px 0 0;
        }
        header {
          width: 100%;
          border-radius: 10px 10px 0 0;
        }
      }
    }
  }
}
.staffOverview {
  .mainContent {
    margin-bottom: 20px;
    h4 {
      font-size: 16px;
      padding: 5px 0 10px;
      margin-bottom: 0;
    }
  }
  .maxWidthTable {
    div[role="rowgroup"] {
      div[role="row"] {
        div[role="cell"] {
          &:nth-child(3) {
            padding-left: 0;
          }
          .tableText {
            padding: 16px 15px;
          }
        }
      }
    }
  }

  .h-100 {
    height: calc(100% - 20px) !important;
  }
}
.secondColumnPaddingZero {
  div[role="rowgroup"] {
    div[role="row"] {
      div[role="cell"] {
        &:nth-child(2) {
          padding-left: 0 !important;
        }
      }
    }
  }
}
.shiftInfo {
  text-align: center;
  padding: 0 10px;
  span {
    display: block;
  }
  .shiftType {
    font-size: 14px;
    font-weight: bold;
  }
  .shiftTitle {
    font-size: 13px;
  }
}
.statusCircle {
  display: block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  &.green {
    background: $green;
  }
  &.red {
    background: $redColor;
  }
}
.modal-backdrop.show {
  z-index: 9999;
}
.ftcModalPopup {
  z-index: 9999;
  .modal-dialog {
    .modal-content {
      border-radius: 8px;
      border: 0;
      overflow: hidden;
      .modal-header {
        background: $primaryColor;
        color: $white;
        padding: 10px 20px;
        .modal-title {
          font-size: 16px;
          font-weight: normal;
        }
      }
      .modal-body {
        padding: 5px 20px;
        .modalBodyContent {
          padding: 10px 0;
          p {
            margin: 0;
          }
        }
        .leaveStatusApproval {
          @include flexbox(row, flex-start, center);
          gap: 10px;
        }
      }
      .usersNameInfo {
        border-bottom: 1px solid rgba($greyLightColor, 0.5);
        padding-bottom: 5px;
        img {
          width: 60px;
          height: 60px;
          margin-right: 10px;
        }
        .nameBlock {
          font-weight: bold;
        }
        .infoBlock {
          font-weight: normal;
        }
      }
      .userListAddChat {
        @include flexbox(row, space-between, center, wrap);
        .cards {
          width: 50%;
          .usersNameInfo {
            .userImage {
              img {
                width: 50px;
                height: 50px;
              }
            }
            .infoBlock {
              font-size: 12px;
              word-break: break-all;
            }
          }
        }
        &.editGroupInfo {
          .usersNameInfo {
            padding: 10px 0;
            .userImage {
              img {
                width: 40px;
                height: 40px;
              }
            }
            .crossImageContainer {
              margin-left: auto;
              margin-bottom: auto;
              .closeButton {
                cursor: pointer;
                img {
                  background: #fbe2e2;
                  width: 15px !important;
                  height: 15px !important;
                  border-radius: 50%;
                }
              }
            }
          }
        }
      }
      .modal-footer {
        background: rgba($greyLightColor, 0.1);
        border: 0;
      }
    }
  }
  &.carePlanSectionsModal {
    .modal-dialog {
      max-width: 400px;
      width: 100%;
    }
    .rosterPopup {
      height: auto;
      overflow-y: auto;
      max-height: 600px;
    }
    .carePlanSections {
      text-align: center;

      .buttonLink {
        display: block;
        /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#278dec+0,00519d+100 */
        background: linear-gradient(
          45deg,
          #278dec 0%,
          #00519d 100%
        ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */

        color: $white;
        max-width: 300px;
        margin: 20px auto;
        padding: 10px;
        border-radius: 5px;
        text-align: left;
        @include flexbox(row, space-between, center, nowrap);
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  &.editProfileModalPopup {
    .modal-dialog {
      .modal-content {
        max-width: 600px;
        width: 96%;
        transform: translateX(-50%);
        left: 50%;
        .modal-header {
          // background: transparent;
          // border: 0;

          // padding: 10px;
          padding: 5px;
          margin-right: 0;
          span {
            img {
              filter: brightness(0) invert(1);
            }
          }
          .btn-close {
            background: url(../images/icons/actionButtonDel.svg) center center no-repeat;
            background-size: 20px;
            opacity: 1;
          }
        }
        .editBlockContent {
          img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            background: rgba($greyLightColor, 0.5);
            // margin-bottom: 30px;
          }
          .closeButton {
            text-align: right;
            span {
              cursor: pointer;
              img {
                width: 25px;
                height: 25px;
                background: none;
              }
            }
          }
        }
      }
    }
  }
  &.suProfileEdit {
    .modal-dialog {
      max-width: 800px;
      .modal-content {
        max-width: 800px;
        width: 96%;
      }
    }
    @include respond-to(mlarge) {
      .modal-dialog {
        .modal-content {
          max-width: 800px;
          width: 96%;
        }
      }
    }
  }
  &.patientVideoPopup {
    img {
      max-width: 100%;
    }
    .text-center {
      padding: 10px 0 0;
      p {
        padding: 10px 0;
        margin-bottom: 0;
      }
    }
  }
}

.stafOverviewBlocksContent {
  @include whiteShadowBg;
  padding: 20px 30px;
  border-radius: 10px;
  border-left: 5px solid $green;
  margin: 5px 0 20px 0;
  @include flexbox(row, space-between, center);
  h4 {
    font-size: 28px;
    font-weight: normal;
    margin: 0;
    small {
      display: block;
      font-size: 50%;
      color: rgba($greyDarkColor, 0.8);
    }
  }
  .contentNumber {
    font-size: 50px;
  }
  &.warningContent {
    border-left: 5px solid $QuaternaryColor;
  }
  &.dangerContent {
    border-left: 5px solid $redColor;
  }
  @include respond-to(large) {
    h4 {
      font-size: 26px;
    }
    .contentNumber {
      font-size: 40px;
    }
  }
  @include respond-to(mlarge) {
    h4 {
      font-size: 22px;
    }
    .contentNumber {
      font-size: 40px;
    }
  }
  @include respond-to(medium) {
    padding: 20px 10px;
    h4 {
      small {
        font-size: 12px;
      }
      font-size: 20px;
    }
    .contentNumber {
      font-size: 30px;
    }
  }
  @include respond-to(smedium) {
    max-width: 300px;
    margin: 10px auto;
    &.greenContent {
      margin-top: 15px;
    }
    &.dangerContent {
      margin-bottom: 20px;
    }
  }
}
.taskDetail {
  height: calc(100% - 20px);
}
.holidaysWrapper {
  .tabsWrapper {
    .tabsHeader {
      @include flexbox(row, space-between, flex-end);
      @include whiteShadowBg;
      flex-direction: row-reverse;
      padding: 10px 15px 0 0;
      border-radius: 10px 10px 0 0;
      margin-bottom: 10px;
      font-size: 14px;

      .nav.nav-tabs {
        border-bottom: 0;
        .nav-link {
          border: 0;
          color: $greyColor;
          &.active {
            color: $primaryColor;
            border-bottom: 3px solid $primaryColor;
          }
        }
      }
      .holidayHeaderStuff {
        @include flexbox(row, space-between, center, wrap);
        margin-bottom: 8px;
        .actionButtons {
          margin-left: 10px;
        }
      }
    }
  }
  .stafOverviewBlocksContent {
    margin-bottom: 15px;
  }
  .rdt_Table {
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol * {
          white-space: normal;
        }
      }
    }
  }

  @include respond-to(medium) {
    .tabsWrapper {
      .tabsHeader {
        display: block;
        padding-left: 15px;
      }
    }
  }
}
.absenceLeave {
  h3 {
    margin: 10px 0 20px;
  }
  .rdt_TableHead {
    // display: none;
  }
  div[role="rowgroup"] {
    div[role="row"] {
      div[role="cell"] {
        &:nth-child(3) {
          padding-left: 0;
        }
        .tableText {
          padding: 16px 15px;
        }
      }
    }
  }
}

.staffRecordsGroup {
  div[role="rowgroup"] {
    div[role="row"] {
      div[role="cell"] {
        &:nth-child(3) {
          padding-left: 0;
        }
        .tableText {
          padding: 16px 15px;
        }
      }
    }
  }
}
.staffRecordsBlocks {
  .clearfix {
    height: 0;
  }
}
.staffRecordDetailWrapper {
  .headerContainerSearchAction {
    margin-bottom: 0;
    border-radius: 10px 10px 0 0;
  }
}
.customTabsWrapper {
  .tabsWrapper {
    ::-webkit-scrollbar:horizontal {
      background: $white;
    }
    .tabsHeader {
      @include whiteShadowBg;
      overflow-x: auto;
      border-radius: 0 !important;
      border-top: 1px solid rgba($greyLightColor, 0.5);

      margin-bottom: 15px;

      .nav.nav-tabs {
        min-width: 985px;
        border-bottom: 0;
        padding: 8px;
        .nav-link,
        a {
          font-size: 14px;
          padding: 6px 15px;
          color: $greyColor;
          border: 0;
          border-radius: 5px;
          display: block;
          &.active {
            background: rgba($primaryLightColor, 0.3);
            color: $primaryColor;
          }
          &:hover {
            border: 0;
            color: $primaryColor;
          }
        }
      }
    }
  }
}
.complianceDocuemnts {
  .contentBlock {
    @include whiteShadowBg;
    padding: 10px;
    margin: 0 auto 25px;
    max-width: 300px;
    .contentHeader {
      @include flexbox(row, space-between, center, nowrap);
      .dropdown {
        text-align: right;
        .btn.btn-primary {
          background: url(../images/icons/menuToggle.svg) no-repeat center center transparent;
          transform: rotate(90deg);
          border: 0;
          height: 16px;
          &:after {
            display: none;
          }
        }
        &.show {
          .dropdown-menu.show {
            a {
              padding: 8px 10px;
            }
          }
        }
      }
      img {
        width: 40px;
        height: 40px;
        margin-top: -5px;
      }
      .statusButton {
        padding: 3px 15px;
        // font-size: 12px;
      }
    }

    .documentInfo {
      h4 {
        font-weight: bold;
        font-size: 16px;
        margin: 10px 0;
      }
      p {
        margin: 0;
        color: $black;
        // font-size: 12px;
      }
      img {
        width: 40px;
        height: 40px;
        margin-bottom: 10px;
      }
    }
  }
}

// .tabsWrapper {
//   .tabsHeader {
//     box-shadow: unset;
//     border-bottom: 1px solid rgba($greyLightColor, 0.5);
//     margin-bottom: 0;
//     .nav.nav-tabs {
//       min-width: 100%;
//       justify-content: space-between;

//     }
//   }
//   .tab-content{
//     padding: 20px
//   }
// }

.eventAppointmentsPoup {
  // position: fixed;
  // min-height: 100vh;
  // overflow: hidden;
  // background: rgba($greyDarkColor, 0.8);
  // z-index: 99999999;
  // width: 100%;
  // top: 0;
  .event-details {
    // position: absolute;
    background-color: white;
    border: 1px solid #ccc;
    padding: 10px;
    // z-index: 999;
    // top: 50%;
    // transform: translate(-50%, -50%);
    // left: 50%;
  }
}
.eventAppointments {
  padding: 20px;
  .fc-header-toolbar {
    .fc-toolbar-chunk {
      div {
        @include flexbox(row, center, center, nowrap);
        h2 {
          font-size: 20px;
          margin: 0 30px;
        }
        button {
          background: transparent;
          border: 0;
          span {
            &:before {
              color: $greyColor;
            }
          }
          &:hover,
          &:focus,
          &:active,
          &:active:focus {
            border: 0;
            background: transparent;
            box-shadow: unset;
          }
        }
      }
    }
  }
  table {
    tr {
      th {
        a {
          color: $greyDarkColor;
        }
      }
      td {
        vertical-align: bottom !important;
        .fc-daygrid-day-number {
          color: $greyDarkColor;
        }
      }
    }
  }
}
.fc-daygrid-dot-event.fc-event-mirror,
.fc-daygrid-dot-event:hover,
.fc-event-selected::after,
.fc-event:focus::after {
  background: transparent !important;
}
a.fc-daygrid-dot-event {
  display: block;
}
.fc .fc-daygrid-event {
  position: unset;
}
.eventAppointmentWrapper {
  position: unset;
  z-index: 1;
  padding: 10px;
  .event-details {
    // display: none;
    @include whiteShadowBg;
    box-shadow: 0 0 10px rgba($greyDarkColor, 0.4);
    padding: 15px;
    position: absolute;
    z-index: 999;
    color: $greyColor;
    font-size: 12px;
    transform: translate(-60px, -40%);
    width: 240px;
    p {
      font-size: 12px;
    }
    header {
      @include flexbox(row, space-between, flex-start, nowrap);
      .heaerDetailContent {
        width: 75%;
        .vitaminStatus {
          @include flexbox(row, space-between, flex-start, nowrap);
        }
        .eventDetailName {
          h4 {
            font-size: 17px;
            margin-bottom: 0;
          }
        }
      }
    }
    .statusTag {
      text-transform: capitalize;
      small {
        span {
          font-size: 11px !important;
        }
      }
    }
    .eventContent {
      padding-top: 10px;
      p {
        margin-bottom: 15px;
        font-size: 12px;
        img {
          width: 20px;
          height: 20px;
          margin-top: -5px;
        }
        strong {
          color: rgba($greyDarkColor, 0.8);
        }
      }
      .usersNameInfo {
        img {
          width: 25px;
          height: 25px;
        }
        .userInfo {
          .nameBlock {
            font-weight: bold;
            color: $greyDarkColor;
            font-size: 12px;
          }
          .infoBlock {
            font-weight: normal;
            font-size: 12px;
          }
        }
      }
    }
    footer {
      @include flexbox(row, center, center, nowrap);
      gap: 10px;
      margin-top: 20px;
      p {
        font-size: 12px;
      }
      img {
        width: 25px;
        height: 25px;
      }
    }
  }
  .eventShortDetail {
    display: block;
    p {
      margin-bottom: 0;
      font-size: 12px !important;
    }
    .viewDetailIcon {
      @include flexbox(row, flex-end, flex-start, nowrap);
      span {
        cursor: pointer;
        background: rgba($greyDarkColor, 0.1);
        border-radius: 50%;
        display: block;
        width: 25px;
        height: 25px;
        text-align: center;
        padding: 1px 0 0 0;
        img {
          width: 20px;
          height: 20px;
        }
      }
    }
    .viewDetailTitle {
      font-size: 14px !important;
    }
  }
  // &:hover {
  //   .eventShortDetail {
  //     opacity: 0;
  //     visibility: hidden;
  //   }
  //   .event-details {
  //     display: block;
  //   }
  // }
  @include respond-to(medium) {
  }
}
.mainContentHeader {
  @include flexbox(row, space-between, center, nowrap);
  @include bottomBorder;
  padding: 15px 20px 10px;
  h3 {
    margin: 0;
  }
  @include respond-to(smedium) {
    display: block;
    .actionButtons {
      margin-top: 10px;
    }
  }
}
.questionWrapper {
  padding: 10px 20px;
  position: relative;
  .usersNameInfo {
    .userImage {
      img {
        width: 45px;
        height: 45px;
      }
    }
    .userInfo {
      .nameBlock {
        font-weight: 500;
      }
    }
  }
  p {
    color: $greyDarkColor;
  }
  .contentBox {
    p {
      margin-top: 10px;
      small {
        font-weight: 400;
        margin-top: 0 !important;
      }
    }
  }
}
.profileBox {
  @include flexbox(row, space-between, center, nowrap);
  .actionButtons {
    @include flexbox(row, flex-end, center, nowrap);
    gap: 5px;
    span {
      cursor: pointer;
      display: block;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      padding: 2px;
      text-align: center;
      &:hover {
        background: rgba($greyDarkColor, 0.2);
      }
      img {
        max-width: 100%;
      }
    }
  }
}
.contentBox {
  position: relative;
  textarea {
    padding: 10px;
    border-radius: 10px;
    border: 2px solid $primaryLightColor;
    background: rgba($greyDarkColor, 0.05);
    resize: none;
  }
  .editableActionButton {
    position: absolute;
    right: 10px;
    bottom: 40px;
    span {
      cursor: pointer;
    }
  }
}
.mainWrapper {
  .timeTableWrapper {
    @include whiteShadowBg;
    background: transparent;

    .timeTableItem {
      margin-bottom: 30px;
      .doseScheduleTimeWrapper {
        margin-bottom: 30px;
      }
      header {
        background: $white;
        text-align: center;
        padding: 10px 0;
        font-weight: bold;
        position: relative;
        margin-top: 20px;
        .preloader-container {
          .preloader-spinner {
            width: 15px;
            height: 15px;
          }
        }

        p {
          margin: 0;
          font-size: 16px;
        }
      }
      .scheduleType {
        margin-top: 20px;
        header {
          background: rgba($secondaryDarkColor, 1);
          p {
            strong {
              color: $white !important;
              text-transform: capitalize;
            }
          }
        }
      }
      .rangeWithButtons {
        @include flexbox(row, space-between, center, nowrap);
        padding: 20px 10px;

        .actionButtons {
          @include flexbox(row, space-between, center, nowrap);
          gap: 10px;
        }
        @include respond-to(smedium) {
          display: block;
          .rangNavigation {
            max-width: 200px;
            margin: 10px auto;
          }
          .actionButtons {
            justify-content: center;
          }
        }
      }
      .medicineTableData {
        max-height: 250px;
        overflow-y: auto;
        overflow-x: auto;
        span.link {
          cursor: pointer;
        }
        table {
          font-size: 14px;
          min-width: 800px;
          margin: 0;
          tr {
            th,
            td {
              text-align: center;
              &:nth-child(1) {
                text-align: left;
                width: 240px;
              }
            }
          }
          thead {
            tr {
              border-bottom: 1px solid rgba($greyDarkColor, 0.2);
              th {
                padding: 10px;
                font-weight: bold;
                position: sticky;
                top: -1px;
                z-index: 1;

                &:nth-child(1) {
                  padding: 10px 20px;
                }
              }
            }
          }
          tbody {
            tr {
              td {
                vertical-align: middle;
                &:nth-child(1) {
                  position: static;
                  font-weight: bold;
                  padding: 10px 20px;
                  small {
                    display: block;
                    font-weight: normal;
                  }
                }
                .actionRow {
                  position: relative;
                  right: 0;
                  top: 0;
                }
                span.doseType {
                  text-transform: uppercase;
                  &:before {
                    content: "-";
                    margin: 0 5px;
                  }
                  &:first-child {
                    &:before {
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      footer {
        background: $white;
        padding: 20px 20px;
        @include flexbox(row, flex-start, center, wrap);
        gap: 20px;
      }
    }
  }
}
.rangNavigation {
  @include flexbox(row, space-between);
  gap: 5px;
  p {
    margin: 0;
  }
  span {
    display: block;
    min-height: 20px;
    cursor: pointer;
    img {
      width: 20px;
      height: 20px;
      vertical-align: top;
    }
  }
  @include respond-to(smedium) {
    .rangNavigation {
      max-width: 200px;
      margin: 10px auto;
    }
  }
}
.medicineDetailPopup {
  position: absolute;
  @include whiteShadowBg;
  box-shadow: 0 0 10px rgba($greyDarkColor, 0.5);
  width: 550px;
  padding: 20px;
  margin-top: -50px;
  @include flexbox(row, space-between, center, nowrap);
  z-index: 9;

  .medDetail {
    h3 {
      font-size: 16px;
      small {
        font-size: 12px;
      }
    }
    p {
      font-size: 12px;
      font-weight: normal;
      strong {
        display: block;
      }
    }
  }
  .medImage {
    border-left: 1px solid rgba($greyDarkColor, 0.2);
    .closeButton {
      position: absolute;
      right: 10px;
      top: 10px;
      cursor: pointer;
    }
    img.medicImage {
      width: 200px;
      height: 150px;
    }
  }
  @include respond-to(medium) {
    width: 100%;
    max-width: 250px;
    display: block;
    .medImage {
      border-left: none;

      img.medicImage {
        width: 100px;
        height: auto;
      }
    }
  }
}
.checkboxContainer {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 22px;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: $white;
    border: 1px solid $primaryColor;
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input:checked ~ .checkmark {
    background-color: $primaryColor;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    left: 4px;
    top: 2px;
    width: 5px;
    height: 9px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
  .labelText {
    line-height: 14px;
    position: relative;
    left: 20px;
  }
}
.form-group {
  .labelText {
    top: -10px;
  }
}
.rosterPopup {
  padding: 10px 20px 0 !important;
  height: 500px;
  overflow-y: auto;
  table {
    border-left: 1px solid rgba($greyDarkColor, 0.1);
    border-right: 1px solid rgba($greyDarkColor, 0.1);
    tr {
      th,
      td {
        font-size: 13px;
        padding: 10px 10px;
        border-width: 1px;
        .popupIcon {
          float: right;
          cursor: pointer;
        }

        &:nth-child(1) {
          width: 40%;
        }
        &:nth-child(2) {
          width: 60%;
        }
        span {
          padding: 0;
          font-size: 14px;
        }
      }
    }
  }
  .medicationPicture {
    border: 1px solid rgba($greyLightColor, 0.5);
    border-radius: 5px;
    text-align: center;
    img {
      max-width: 100%;
      max-height: 150px;
    }
  }
}
.checkboxList {
  @include flexbox(row, flex-start, flex-start, wrap);
  &.spaceInCheckList {
    gap: 10px;
  }
  .checkboxContainer {
    width: calc(100% / 3);
    margin-bottom: 5px;
    label {
      display: block;
      width: 100%;
      font-size: 13px;
    }
  }
  &.medicineCheckList {
    gap: 30px;
  }
}
.subTabsWrapper {
  .radioTabsHeader {
    @include flexbox(row, space-between, center);
    // @include bottomBorder;
    padding: 20px 20px 15px;
    .tabsBlocks {
      width: calc(100% / 3);
      .rangNavigation {
        max-width: 210px;
        margin: 0 auto;
      }
    }
    .nav-tabs {
      border: 0;
      gap: 20px;
      .nav-item {
        .nav-link {
          border: 0;
          padding: 0 0 0 20px;
          margin: 0;
          background: url("../images/icons/radioUnTick.svg") left 3px no-repeat transparent;
          background-size: 14px;
          color: $greyColor;
          font-size: 14px;
          &.active {
            background: url("../images/icons/radioTick.svg") left 3px no-repeat transparent;
            background-size: 14px;
          }
        }
      }
    }
  }
  @include respond-to(large) {
    .radioTabsHeader {
      .tabsBlocks {
        width: auto;
      }
    }
  }
  @include respond-to(medium) {
    .radioTabsHeader {
      display: block;
      .nav-tabs {
        justify-content: center;
      }
      .tabsBlocks {
        margin: 0 auto 20px;
        text-align: center;
      }
    }
  }
}
.colorStatus {
  @include flexbox(row, flex-end, center);
  gap: 10px;
  .colorStatusItem {
    @include flexbox(row, flex-start, center);
    gap: 10px;
    font-size: 14px;
  }
  .color {
    width: 10px;
    height: 10px;
    display: block;
    &.green {
      background: $green;
    }
    &.red {
      background: $redColor;
    }
    &.grey {
      background: $greyColor;
    }
  }
  @include respond-to(medium) {
    justify-content: center;
  }
  @include respond-to(small) {
    flex-wrap: wrap;
  }
}
.rosterTableContent {
  table {
    width: 100%;
    margin: 0;
    tr {
      th,
      td {
        width: 12%;
        padding: 5px;
        background: $greyLightestColor;
        text-align: center;
        vertical-align: middle;
        font-size: 14px;
        &:nth-child(1) {
          width: 16%;
          min-width: 180px;
          background: $white;
          position: relative;
          .staffCol {
            @include flexbox(row, space-between, center, nowrap);
            img {
              width: 18px;
              height: 18px;
              cursor: pointer;
            }
          }
        }
      }
      .userInfoWrapper {
        padding: 10px 0;
      }
      .contentcell {
        text-align: left;
        margin-bottom: 0;
      }
      .shiftActionButtons {
        margin-bottom: 0;
        position: relative;
        top: -8px;
      }
      .textDetail {
        cursor: pointer;
        span,
        strong,
        small {
          display: block;
        }
      }
      .usersNameInfo {
        text-align: left;
        justify-content: center;
        cursor: pointer;
        span {
          font-weight: normal;
        }
        .userInfo {
          font-weight: bold;
        }
      }
    }
    thead {
      tr {
        th {
          background: $white;
          padding: 10px;
        }
      }
    }
  }
  @include respond-to(large) {
    table {
      min-width: 1100px;
    }
  }
}
.rosterShift {
  .modal-dialog {
    width: 96%;
    max-width: 400px;
    margin: 0 auto;
  }
  .rosterShiftPopup {
    padding: 20px 0 0;
    font-size: 14px;
    * {
      font-size: 12px;
    }
    textarea {
      min-height: 100px;
    }
    .timeTableBox {
      @include flexbox(row, flex-start, flex-end, wrap);
      gap: 5px;
      .form-group {
        width: 33%;
        padding-right: 10px;
        &:nth-child(3) {
          width: 30%;
        }
      }
    }
  }
}
.shiftHistory {
  height: calc(100vh - 250px);
  border: 1px solid rgba($greyLightColor, 0.5);
  border-top: 0;
  border-bottom: 0;
  h3 {
    padding: 15px 15px 10px;
  }
  table {
    width: 100%;
    font-size: 12px;
    tr {
      td {
        vertical-align: middle;
        border: 0;
        &:nth-child(1) {
          width: 40%;
        }
        &:nth-child(2) {
          width: 60%;
          text-align: right;
        }
        p {
          margin: 0;
          padding: 0;
          small {
            margin-top: 0 !important;
          }
        }
      }
    }
  }
  @include respond-to(medium) {
    padding: 20px;
  }
}
.mainContent {
  &.addNewShift {
    padding: 0 !important;
    header {
      padding: 20px;
      @include bottomBorder;
    }

    footer {
      background: #f8f9fb;
      padding: 20px;
      .formActionButtons {
        margin: 0;
      }
      .btn {
        padding: 5px 15px;
      }
    }
    .addNewShiftForm {
      padding: 20px 0 20px 20px;
      .hrs {
        font-size: 12px;
        text-align: right;
        margin: 0;
      }
      .form-group {
        label.floatingLabel {
          left: 1px;
        }
      }
      .form-group input:valid + label,
      .form-group input:focus + label {
        left: 5px;
      }
      .col-lg-2.col-md-4.pr-0 {
        input {
          width: 98%;
        }
      }
      @include respond-to(mlarge) {
        .pr-0,
        .pl-0 {
          padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
          padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        }
        .col-lg-2.col-md-4.pr-0 {
          input {
            width: 100%;
          }
        }
      }
      @include respond-to(medium) {
        padding: 20px;
      }
    }
  }
}
.personalAllowance {
  border-left: 1px solid rgba($greyLightColor, 0.5);
  padding: 10px 10px 10px 20px;
  p {
    margin: 0;
    font-weight: bold;
    span {
      color: $primaryColor;
    }
  }
  @include respond-to(medium) {
    border-left: 0;
    padding: 10px;
  }
  &.generalNotes {
    p {
      @include flexbox(row, space-between, center, wrap);
      gap: 5px;
    }
  }
}
.careNotes {
  @include whiteShadowBg;
  p {
    &.viewCareNotes {
      text-align: right;
      margin: 0;
      padding: 10px 10px 0;
    }
  }
  header {
    @include flexbox(row, space-between, center, wrap);
    padding: 15px 10px 5px;
    h3 {
      padding: 0;
      margin: 0;
      small {
        margin-top: 5px;
        display: block;
      }
    }
    .personalAllowance {
      padding: 0 3px 0 0;
      border: 0;
    }
  }
  h3 {
    font-size: 16px;
    padding: 0;
  }
  .dataTableWrapper {
    .maxWidthTable {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          padding: 10px;
        }
      }
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="cell"] {
            padding: 10px;
          }
        }
      }
      p {
        font-size: 13px;
        margin: 0;
      }
    }
  }
  @include respond-to(medium) {
    margin-bottom: 20px;
  }
}
.careNotesPopup {
  p {
    text-align: justify;
    margin: 20px 0;
    word-wrap: break-word;
    strong,
    small {
      display: block;
    }
    &.text-left {
      text-align: left !important;
    }
  }
  &.specificFormat {
    p {
      margin: 0 0 20px;
    }
  }
}
.unReadMessages {
  @include whiteShadowBg;
  padding: 15px;
  margin-top: 10px;
  h3 {
    font-size: 16px;
    padding: 0;
  }
  .dataTableWrapper {
    .maxWidthTable {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          display: none;
        }
      }
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="cell"] {
            padding: 0;
            border-left: 0 !important;
            border-bottom: 0 !important;
            .usersNameInfo {
              padding: 10px 0 10px 10px;
              white-space: normal;
              .nameBlock {
                font-weight: bold;
              }
              .paragraphBlock {
                font-weight: normal;
              }
            }
          }
        }
      }
      p {
        font-size: 13px;
        margin: 0;
      }
    }
  }
}
.carePlanCalendar {
  font-size: 12px;

  @include whiteShadowBg;
  .fc-header-toolbar {
    .fc-toolbar-chunk {
      div {
        @include flexbox(row, space-between, center, nowrap);
        width: 100%;
        h2 {
          font-size: 14px;
          margin: 0 30px;
        }
        button {
          background: transparent;
          border: 0;
          span {
            &:before {
              color: $greyColor;
            }
          }
          &:hover,
          &:focus,
          &:active,
          &:active:focus {
            border: 0;
            background: transparent;
            box-shadow: unset;
          }
        }
      }
    }
  }
  .fc {
    .fc-scroller-liquid-absolute {
      position: relative;
    }
    .fc-view-harness {
      position: relative;
      min-height: 250px;
      height: auto !important;
    }
    .fc-view-harness-active > .fc-view {
      position: relative;
    }
    .fc-col-header-cell-cushion {
      color: $greyLightColor;
      font-weight: normal;
    }
    .fc-toolbar.fc-header-toolbar {
      margin: 0;
      padding: 20px 0;
      width: 100%;
      display: block;
      .fc-toolbar-chunk {
        width: 100%;
      }
    }
    .fc-col-header-cell {
      padding: 10px 0;
    }
    .fc-scrollgrid-liquid {
      // border: 0;
    }
    .fc-daygrid-day-number {
      color: $greyDarkColor;
    }
    .fc-daygrid-day-top {
      justify-content: center;
      align-items: center;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
      width: 100%;
    }
    // .fc-daygrid-body-unbalanced .fc-daygrid-day-events {
    //   display: none;
    // }
    .fc-daygrid-day.fc-day-today {
      background: transparent;
      .fc-daygrid-day-frame {
        outline: 1px solid $primaryColor;
        background: rgba($primaryColor, 0.2);
        top: 1px;
      }
    }
  }
  .fc-theme-standard td,
  .fc-theme-standard th {
    // border: 0;
  }

  .eventAppointmentWrapper {
    padding: 0;
    .viewDetailIcon {
      cursor: pointer;
    }
    p {
      padding: 0;
      margin: 0;
    }
  }
  .todayAppointment {
    padding: 15px;
    h4 {
      font-size: 16px;
      font-weight: bold;
      color: $primaryColor;
      margin-bottom: 5px;
    }
    p {
      font-size: 12px;
      margin-bottom: 0;
    }
    span {
      font-size: 12px;
      color: $primaryColor;
      font-weight: bold;
      cursor: pointer;
    }
  }
  @include respond-to(medium) {
    margin-bottom: 20px;
  }
}
.eventDetailPopup {
  p {
    margin-bottom: 5px;
    border-bottom: 1px solid rgba($greyColor, 0.3);
    padding: 10px 0;
    font-size: 13px;
    strong {
      display: block;
    }
  }
  .usersNameInfo {
    .userImage {
      img {
        width: 40px !important;
        height: 40px !important;
      }
    }
    .userInfo {
      span {
        font-size: 13px;
      }
    }
  }
}
.carePlansBlocks {
  .cards {
    a {
      display: block;
      text-align: right;
    }
  }
}
header.memoryBoxContentHeader {
  @include flexbox(row, flex-end, center, nowrap);
  gap: 10px;
  margin: 10px 0;
  span {
    cursor: pointer;
  }
}
.memoryBoxHeader {
  @include flexbox(row, space-between, center, wrap);
}
.memoryBoxContentWrapper {
  @include whiteShadowBg;
  padding: 10px 20px 10px 10px;
  margin: 10px 0 20px;
  height: calc(100% - 20px);
}
.memoryBoxContent {
  @include flexbox(row, flex-start, flex-start, nowrap);
  gap: 10px;
  .imageBox {
    position: relative;
    img {
      border-radius: 5px;
      width: 120px;
      height: 120px;
      margin: 0 auto;
    }
    .downloadIconButton {
      position: absolute;
      display: block;
      width: 25px;
      height: 25px;
      bottom: 2px;
      right: 2px;
      background: $primaryColor;
      border-radius: 50%;
      text-align: center;
      cursor: pointer;
      img {
        max-width: 100%;
        position: relative;
        height: 23px;
        width: 23px;
        padding: 2px;
        filter: brightness(0) invert(1);
      }
    }
  }

  .videoMemoryBox {
    max-width: 100%;
  }
  .viewBox {
    background: $greyImageBg;
    width: 120px;
    height: 120px;
    .videoMemoryBox {
      width: 120px;
      height: 120px;
    }
  }
  .textBox {
    flex: 1;
    p {
      word-break: break-word;
    }
  }
  @include respond-to(small) {
    display: block;
    .imageBox {
      img {
        width: 80px;
        height: 80px;
        margin-bottom: 10px;
      }
    }
    .textBox {
      width: 100%;
      text-align: justify;
    }
  }
  &.profileVideoContent {
    justify-content: center;
  }
}
.careplanOptions {
  &.scheduleBlocks {
    .cards {
      padding: 30px 10px;
      cursor: pointer;
      // height: calc(100% - 20px);
      img {
        margin-bottom: 20px;
      }
      h3 {
        font-size: 18px;
      }
    }
  }
  @include respond-to(medium) {
    .headingTitle {
      text-align: center;
      font-size: 20px;
    }
  }
}

.mainContent {
  &.youngInformation {
    padding: 0 !important;
    header {
      padding: 10px 20px;
      @include bottomBorder;
    }

    footer {
      background: #f8f9fb;
      padding: 20px;
      .formActionButtons {
        margin: 0;
      }
      .btn {
        padding: 5px 15px;
      }
    }
    .youngInformationForm {
      padding: 20px;
      h3 {
        margin-bottom: 20px;
      }
      table {
        min-width: 800px;
      }
      .hrs {
        font-size: 12px;
        text-align: right;
        margin: 0;
      }
      .form-group {
        label.floatingLabel {
          left: 5px;
          top: -8px;
          padding: 0 5px;
        }
      }
      .form-group input:valid + label,
      .form-group input:focus + label {
        left: 5px;
      }
      .col-lg-2.col-md-4.pr-0 {
        input {
          width: 98%;
        }
      }
      @include respond-to(mlarge) {
        .pr-0,
        .pl-0 {
          padding-left: calc(var(--bs-gutter-x) * 0.5) !important;
          padding-right: calc(var(--bs-gutter-x) * 0.5) !important;
        }
        .col-lg-2.col-md-4.pr-0 {
          input {
            width: 100%;
          }
        }
      }
      @include respond-to(medium) {
        padding: 20px;
      }
    }
  }
}

.messageWrapper {
  .messageDropDownButton {
    button.dropdown-toggle {
      background: transparent;
      border: 0;
      padding: 0;
      height: 26px;

      &:after {
        display: none;
      }
    }
  }
}
.messageContainer {
  @include whiteShadowBg;
  @include flexbox(row, flex-start, flex-start, nowrap);
  max-height: calc(100vh - 180px);
  .nameSpace {
    @include nameSpace;
    padding: 0;
    @include respond-to(xlarge) {
      display: none;
    }
  }
  .mobileNameSpace {
    @include nameSpace;
  }
  .roomSpace {
    flex: 1;
    border-left: 2px solid $greyLightestColor;

    header {
      @include bottomBorder;
      padding: 15px;
    }
    .usersNameInfo {
      .top {
        margin-left: auto;
        background: none;
        .messageDropDownButton {
          button {
            border-radius: 0;
            height: auto;
            img {
              width: 20px;
              height: 20px;
              border-radius: 0;
              background: transparent;
            }
          }
        }
      }
    }
    .messagesPanel {
      font-size: 12px;
      padding: 10px 25px;
      margin: 10px;
      max-height: calc(100vh - 330px);
      overflow-y: auto;
      border-radius: 5px;
      .messageDropDownButton {
        button.dropdown-toggle {
          img {
            width: 90%;
          }
        }
      }
      .messageBoxWrapper {
        margin: 10px 0;
        &.receiverWrapper {
          .time {
            text-align: right;
          }
        }
      }
      .attachmentMessage {
        img {
          width: 150px;
        }
      }
      .attachment {
        padding: 10px 10px !important;
        a {
          img.icon {
            width: 80px;
          }
        }
      }
      .senderMessage {
        @include flexbox(row, flex-start, center, nowrap);
        gap: 5px;
        max-width: 50%;
        .textMessage {
          background: rgba($greyLightColor, 0.5);
          border-radius: 10px 10px 10px 0;
          padding: 10px 15px;
          color: $greyDarkColor;
          max-width: 97%;
          min-width: 200px;
          .time {
            text-align: right;
            font-size: 8px;
          }
        }
        @include respond-to(mlarge) {
          max-width: 100%;
        }
      }

      .recieverMessageBox {
        @include flexbox(row, flex-end, center, nowrap);
      }

      .receiverMessage {
        @include flexbox(row, flex-end, center, nowrap);
        gap: 5px;
        max-width: 50%;

        .textMessage {
          background: $primaryColor;
          color: $white;
          border-radius: 10px 10px 0 10px;
          padding: 10px 15px;
          max-width: 97%;
          min-width: 200px;
          img.icon {
            filter: brightness(0) saturate(100%) invert(99%) sepia(54%) saturate(48%) hue-rotate(16deg) brightness(125%)
              contrast(100%);
          }
          .time {
            text-align: right;
            color: $white;
            font-size: 8px;
          }
        }
        @include respond-to(mlarge) {
          max-width: 100%;
        }
      }
      .time {
        color: $greyColor;
        width: 100%;
      }
    }
    .messagesActions {
      background: $greyLightestColor;
      padding: 5px;
      top: 4px;
      position: relative;
      footer {
        @include whiteShadowBg;
        @include flexbox(row, flex-start, center, nowrap);
        padding: 10px;
        gap: 5px;
        .textMessage {
          flex: 1;
          input,
          textarea {
            width: 100%;
            padding: 8px 5px;
            border: 0;
            height: 35px;
            margin: 7px 0 0;
          }
        }
        .emoji,
        .attachment {
          width: 25px;
        }
        .attachment {
          position: relative;
          width: 24px;
          height: 40px;
          display: flex;
          align-items: center;
          input[type="file"] {
            cursor: pointer;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0;
          }
        }
        .submit {
          width: 30px;
          button {
            background: $primaryColor;
            border: 0;
            height: 30px;
            width: 30px;
            border-radius: 5px;
            padding: 0;
            text-align: center;
            img {
              margin: 0 auto;
            }
          }
        }
      }
    }
  }
  .emptyChatRoom {
    min-height: calc(100vh - 200px);
    @include flexbox(column, center, center, nowrap);
    header {
      border: 0;
      width: 100%;
      display: none;
      @include respond-to(xlarge) {
        display: block;
      }
    }
    .chatBoxContainer {
      text-align: center;
      img {
        width: 200px;
        opacity: 0.5;
        margin: 0 auto 10px;
        filter: grayscale(1);
      }
      p {
        font-style: italic;
      }
    }
  }
  &.responsiveMessageContainer {
    .mobileNameSpace {
      @include nameSpace;
      width: 50%;
    }
    @include respond-to(smedium) {
      .mobileNameSpace {
        width: 88%;
      }
      .roomSpace {
        width: 10%;
        .messagesPanel,
        .messagesActions,
        .userImage,
        .userInfo {
          display: none;
        }
      }
    }
  }
}
.requestContentCards {
  @include flexbox(row, flex-start, flex-start, wrap);
  padding: 20px 0;
  gap: 1.5%;

  .cards {
    @include whiteShadowBg;
    flex: 0 0 32.2%;
    padding: 20px;
    margin-bottom: 20px;
    .cardsUserDetail {
      @include flexbox(row, space-between, center, nowrap);
      margin-bottom: 10px;
      .statusButton {
        margin-right: auto;
      }
      .editIcon {
        background-size: 25px;
        width: 25px;
        height: 25px;
      }
      .nameBlock {
        font-weight: bold;
      }
      .infoBlock {
        font-weight: normal;
      }
    }
    .commentBox {
      min-height: 70px;
      padding-top: 10px;

      @include respond-to(mlarge) {
        min-height: 70px;
      }
      @include respond-to(smedium) {
        min-height: auto;
      }
      p {
        font-size: 13px;
      }
    }
    p {
      margin: 0;
      small {
        display: block;
        margin-top: 10px;
      }
    }
    .delIcon {
      position: relative;
      right: -10px;
      cursor: pointer;
    }
    @include respond-to(medium) {
      flex: 0 0 49%;
    }
    @include respond-to(smedium) {
      flex: 0 0 100%;
    }
    // &:last-child {
    //   margin-right: auto;
    //   margin-left: 8px;
    // }
  }
  &.assignUsersContentCards {
    .cards {
      height: 225px;
      max-width: 32.33%;
      .commentBox {
        padding-top: 0;
        header.assignUser {
          @include flexbox(row, space-between, flex-start, nowrap);
          gap: 10px;
          margin-bottom: 10px;
          padding: 10px 0;
          border-top: 1px solid rgba($greyLightColor, 0.5);
          border-bottom: 1px solid rgba($greyLightColor, 0.5);
          max-width: 100%;
          overflow-x: auto;
        }
      }
    }
  }
  .accordion {
    width: 100%;
  }
  p.borderBottom {
    @include bottomBorder;
    padding: 20px 0;
    margin: 0;
    width: 100%;
  }
}
.addNewRequestSU {
  .mainContentHeader {
    .actionButtons {
      @include flexbox(row, space-between, center, wrap);
      gap: 10px;
    }
  }
}
.confirmationPopup {
  .modal-dialog {
    max-width: 400px;
  }
  &.deletionConfirmation {
    .modal-dialog {
      .modal-content {
        .modal-header {
          background: $redColor;
        }
        .modal-body {
          .editBlockContent {
            padding: 20px 0;

            p {
              margin: 10px 0;
            }
            img {
              width: 30px;
            }
          }
        }
        .modal-footer {
          justify-content: center;
        }
      }
    }
    &.restoreConfirmation {
      .modal-dialog {
        .modal-content {
          .modal-header {
            background: $green;
          }
        }
      }
    }
  }
}

.reviewsContentCards {
  @include flexbox(row, flex-start, flex-start, wrap);
  padding: 20px 0;
  gap: 0;
  position: relative;
  .cards {
    background: $white;
    flex: 0 0 25%;
    padding: 15px 20px 10px;
    border-right: 1px solid rgba($greyLightColor, 0.5);
    &:nth-child(4n) {
      border-right: 0;
    }
    a {
      font-size: 12px;
    }
    .cardsUserDetail {
      @include flexbox(row, space-between, flex-start, nowrap);
      margin-bottom: 0;
      .nameBlock {
        font-weight: bold;
      }
      .infoBlock {
        font-weight: normal;
      }
    }
    @include respond-to(mlarge) {
      flex: 0 0 33%;

      &:nth-child(4n) {
        border-right: 1px solid rgba($greyLightColor, 0.5);
      }
      &:nth-child(3n) {
        border-right: 0;
      }
    }
    @include respond-to(medium) {
      flex: 0 0 49%;
      &:nth-child(3n) {
        border-right: 1px solid rgba($greyLightColor, 0.5);
      }
      &:nth-child(2n) {
        border-right: 0;
      }
    }
    @include respond-to(smedium) {
      flex: 0 0 100%;
      border-right: 0 !important;
    }
  }
}
.reviewContent {
  .questionWrapper {
    padding: 20px 0;
  }
  .profileBox {
    justify-content: flex-start;
    align-items: flex-start;
    gap: 20px;
    margin-bottom: 20px;
    padding: 10px 20px;
    &:nth-child(odd) {
      background: $greyLightestColor;
    }
  }

  .contentBox {
    flex: 1;
    .nameBlock {
      font-weight: bold;
    }
    p {
      margin: 0;
    }
    .editableActionButton {
      bottom: 0;
    }
  }
  .requestContentCards {
    display: block;
  }
  @include respond-to(medium) {
    .profileBox {
      display: block;
    }
    .contentBox {
      .editableActionButton {
        position: relative;
        right: 0;
        bottom: 0;
      }
    }
  }
  .reviewFormWizard {
    padding: 20px;
    header {
      margin-bottom: 30px;
      padding: 0 0 20px 0;
    }
  }
  &.userDetailContainer {
    .wizardSteps {
      p {
        padding: 0 20px;
      }
      .supportAbleArea {
        @include flexbox(row, flex-start, center, nowrap);
        gap: 30px;
        margin-top: 20px;
        li {
          color: $greyColor;
        }
      }
      table.dynamicRows {
        width: calc(100% - 40px);
        min-width: 575px;
        margin: 10px auto;
      }
    }
  }
  header.mainContentHeader {
    width: calc(100% - 40px);
    margin: 0 0 !important;
    padding: 15px 20px 10px;
    border: 0;
  }
}
.reviewContent {
  header.mainContentHeader {
    padding: 15px 0 10px !important;
  }
  header.viewContentHeader {
    padding: 15px 20px 10px !important;
  }
}
.userDetailReview {
  @include flexbox(row, flex-start, flex-start, nowrap);
  gap: 20px;
  padding: 20px 0;
  .userImage {
    img {
      width: 150px;
      height: 150px;
      background: rgba($greyLightColor, 0.5);
      border-radius: 50%;
    }
    .avatarAction {
      @include flexbox(row, center, center);
      gap: 5px;
      position: relative;
      top: -20px;
      img {
        border: 1px solid $white;
        width: 30px;
        height: 30px;
      }
      span {
        cursor: pointer;
      }
    }
  }
  .detailTable {
    flex-grow: 1;
    .currentStatus {
      justify-content: flex-start;
    }
    header {
      @include flexbox(row, space-between, center, nowrap);
      padding-bottom: 10px;
      margin-bottom: 10px;
      @include bottomBorder;
    }
    h3 {
      font-size: 18px;
      font-weight: bold;
      margin: 0;
      small {
        font-size: 75%;
      }
    }
    .grid-container {
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      grid-gap: 10px;
      .grid-item {
        p {
          margin: 0;
        }
      }
      @include respond-to(mlarge) {
        grid-template-columns: repeat(3, 1fr);
      }
      @include respond-to(smedium) {
        grid-template-columns: repeat(2, 1fr);
      }
      @include respond-to(smallest) {
        grid-template-columns: repeat(1, 1fr);
      }
    }
  }
  @include respond-to(mlarge) {
    display: block;
    .userImage {
      img {
        margin-bottom: 20px;
      }
    }
  }
}
.upload-icon {
  margin-bottom: 20px;
  .avatarAction {
    position: relative;
    .uploadImage {
      position: absolute;
      right: 0;
      left: 0;
      background: rgba($primaryColor, 0.2);
      border-radius: 50%;
      height: 150px;
      width: 150px;
      cursor: pointer;
      opacity: 0;

      img {
        width: 30px !important;
        background: none !important;
        // margin-bottom: 0 !important;
      }
    }
    &:hover {
      .uploadImage {
        opacity: 1;
      }
    }
  }
  img {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: rgba(193, 195, 200, 0.5);
  }
}
.userTitle {
  font-size: 22px;
  margin-bottom: 20px;
  small {
    display: block;
  }
  span {
    font-size: 14px;
    margin-top: 10px;
  }
}
.userProfile {
  position: relative;
  min-height: 150px;
  // min-height: calc(100vh - 100px);
}
.currentServiceUser {
  @include whiteShadowBg;
  padding: 20px;
  margin-bottom: 25px;
  position: relative;
  header {
    @include bottomBorder;
    @include flexbox(row, space-between, center, nowrap);
    padding: 0 0 10px 0;
    margin-bottom: 5px;
    h3 {
      font-size: 18px;
      padding: 0 0 0;
      margin: 0;
    }
    a {
      font-size: 12px;
      color: $primaryColor;
    }
  }

  .reviewsContentCards {
    max-height: 280px;
    // min-height: 280px;
    overflow-y: auto;
    .cards {
      padding: 20px 20px 20px 10px;
    }
    .link {
      color: $primaryColor;
      font-size: 12px;
      cursor: pointer;
    }
  }
}
.reviewsContentCards {
  .link {
    color: $primaryColor;
    font-size: 12px;
    cursor: pointer;
  }
}
.newNpastServiceUser {
  .reviewsContentCards {
    .cards {
      flex: 0 0 50%;
      @include respond-to(smedium) {
        flex: 0 0 100%;
      }
      &:nth-child(2n) {
        border: 0;
      }
    }
  }
}
.statusButton {
  display: block;
  padding: 8px 20px;
  min-width: 120px;
  text-align: center;
  font-size: 12px;
}
.background-success {
  background: $secondaryLightestColor;
  color: $secondaryDarkColor;
  border-color: $secondaryLightestColor;
}
.background-warning {
  background: $QuaternaryLightColor;
  color: $QuaternaryColor;
  border-color: $QuaternaryLightColor;
}
.background-danger {
  background: $redLighterColor;
  color: $redColor !important;
  border-color: $redLighterColor;
}
.background-danger-inactive {
  background: $redLighterColor;
  color: $redColor !important;
  border-color: $redLighterColor;
}
.conditionalTextStatus {
  padding: 8px 20px;
  border-radius: 20px;
}
.addHolidayWrapper {
  padding: 20px 0 0;
}
.customSelectBox {
  font-size: 13px;
  font-weight: normal;
  div[aria-selected="true"] {
    color: $white !important;
  }
  .optionsRow {
    border-bottom: 1px solid rgba($greyDarkColor, 0.05);
    font-size: 13px;
    font-weight: normal;
    cursor: pointer;
    padding: 5px;
    &:hover {
      background: rgba($primaryLightColor, 0.2);
    }
    img {
      margin: 0 5px 0 0;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: rgba($greyLightColor, 0.5);
    }
  }
  &.validationError {
    .css-13cymwt-control {
      // border-color: $redColor;
    }
    input {
      border-color: transparent !important;
    }
  }
}
.chipBlockWrapper {
  @include flexbox(row, flex-start, center, wrap);
  margin-bottom: 30px;
  gap: 20px;
  .chipBlock {
    background: $greyImageBg;
    border-radius: 5px;
    font-size: 12px;
    padding: 5px 10px;
    .deleteChip {
      position: relative;
      top: -15px;
      right: -15px;
      cursor: pointer;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}
.rotaContentCell {
  @include whiteShadowBg;
  padding: 15px 15px;
  text-align: left;
  @include flexbox(column, space-between, flex-start);
  gap: 3px;
  position: relative;
  min-width: 175px;

  span {
    font-size: 12px;
    color: $greyColor;
    // margin-top: 20px;
    &.time {
      font-weight: bold;
      color: $black;
    }
    &.plusIcon {
      position: absolute;
      bottom: -10px;
      left: 50%;
      transform: translateX(-18px);
      cursor: pointer;
      img {
        width: 18px;
        height: 18px;
      }
    }
  }
  &:nth-child(1) {
    margin-top: 0;
  }
  &.updatedRotaContentCell {
    span {
      font-size: 11px;
    }
    .sleepOver-icon {
      left: 0;
      img {
        width: 8px;
      }
    }
  }
}
.updatedRotaContentCellWrapper {
  @include flexbox(row, space-between, center, nowrap);
  @include whiteShadowBg;
  border-radius: 5px;
  .rotaContentCell {
    background: transparent;
    box-shadow: none;
    border-radius: 0;
    margin: 0;
    gap: 0;
  }
  .updateCellIcon {
    padding: 0 3px;
    .icons {
      @include flexbox(column, center, center, nowrap);
      gap: 15px;
      img {
        width: 10px;
        height: 10px;
      }
    }
  }
}
.rotaPlusCell {
  .plusIcon {
    position: relative;
    cursor: pointer;
  }
}
.actionRow {
  @include flexbox(row, flex-end, center, nowrap);
  gap: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  span {
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
    }
    &.view {
      background: rgba($greyDarkColor, 0.1);
      border-radius: 50%;
    }
  }
}
.leaveHolidayCell {
  padding: 0 !important;
  border-left: 3px solid $redColor !important;
  background: $redLightColor !important;

  &.unAssignedShift {
    border-left: 0 !important;
  }
  .redcell {
    color: $redColor;
    font-weight: bold;
  }
}
.linkingCell {
  position: relative;
  .locationDetail {
    position: absolute;
    right: 3px;
    top: 3px;
    &.underShift {
    }
  }
}
.toolTipLeftAlign {
  * {
    text-align: left !important;
  }
}
.scheduleBlocksHead {
  background: $white;
  @include flexbox(row, center, center, nowrap);
  gap: 5px;
  padding: 15px 10px;
  color: $black;
  border-radius: 5px 5px 0 0;
}

.rotaTableContent {
  table {
    tr {
      td.unAssignedShift {
        height: 125px;
      }
    }
  }
}
.addShiftWrapper {
  padding: 20px 0;
}
.css-1p3m7a8-multiValue {
  background: rgba($primaryLightColor, 0.3) !important;
  border: 0;
  border-radius: 10px !important;
  color: $redColor;
  padding: 0 0 0 5px !important;
  font-size: 13px !important;
  .css-wsp0cs-MultiValueGeneric {
    color: $primaryColor;
  }
  .css-qbdosj-Input {
    font-size: 13px;
    color: $redColor !important;
  }
  .css-12a83d4-MultiValueRemove {
    svg {
      filter: invert(22%) sepia(36%) saturate(7135%) hue-rotate(351deg) brightness(100%) contrast(135%);
      width: 13px;
      height: 13px;
    }
  }
}
.css-1jqq78o-placeholder {
  font-size: 13px !important;
}
.css-1u9des2-indicatorSeparator {
  display: none;
}
.css-1xc3v61-indicatorContainer {
  padding: 0;
}
.css-tj5bde-Svg {
  width: 18px;
  height: 18px;
  filter: invert(17%) sepia(0%) saturate(1%) hue-rotate(347deg) brightness(89%) contrast(87%);
}

.multiSelectCustomLabel {
  color: $lightGrey;
  top: 10px;
  left: 5px !important;
  font-size: 12px;
  padding: 0 5px;
  position: relative;
  z-index: 9;
  background: $white;
}
.checkList {
  .checkboxContainer {
    font-size: 13px;

    .labelText {
      top: 0;
    }
    .checkmark {
      left: 5px;
    }
  }
}
.individualStaffSchedule {
  padding: 15px 0 0 !important;
  margin-bottom: 15px;
  h3 {
    padding-left: 15px;
  }
  .form-group {
    margin: 0;
  }
  table {
    width: 100%;
    margin: 0;
    tr {
      td,
      th {
        width: 14.28%;
        vertical-align: middle;
        text-align: center;
      }
      td {
        padding-top: 15px;
        padding-bottom: 15px;
        &.unAssignedShiftaDay {
          background: $redLightColor;
          .checkList {
            .labelText {
              display: none;
            }
            .checkmark {
              left: 50%;
              transform: translateX(-8px);
              border-color: $lightGrey;
            }
          }
        }
      }
    }
  }
  &.updateRotaSchedule {
    table {
      tr {
        td {
          padding: 5px;
          .usersNameInfo {
            align-items: center;
            .userImage {
              img {
                width: 36px;
                height: 36px;
                margin-bottom: 0;
              }
            }
            span {
              line-height: normal;
              &.nameBlock {
                margin-bottom: 5px;
                font-weight: 600;
              }
              &.actionRows {
                gap: 5px;
                span {
                  img {
                    margin: 0;
                    width: 18px;
                    height: 18px;
                    background: transparent;
                  }
                }
              }
            }
          }
          &.unAssignedShiftaDay {
            padding: 20px 20px;
          }
        }
      }
    }
  }
}
.hGcwUo {
  background: none !important;
}
.noRecordFound {
  background: $white;
  padding: 30px 0;
  text-align: center;
  font-size: 18px;
  color: $greyColor;
  width: 100%;
}
.commentColumn {
  @include whiteShadow;
  padding: 0;
  .messageContainer {
    box-shadow: none;
    .roomSpace {
      .messagesPanel {
        padding: 0 5px 0 0;
        min-height: calc(100vh - 270px);
        height: calc(100vh - 270px);
        // min-height: calc(100vh - 330px);
        .recieverMessageBox {
          .receiverMessage {
            width: 100%;
            max-width: 100%;
            justify-content: flex-start;
            .textMessage {
              flex-grow: 1;
              flex-basis: 0;
              max-width: 100%;
              background: rgba($greyLightColor, 0.1);
              padding: 5px;
              border-radius: 0;
              text-align: justify;
              color: $black;
            }
          }
        }
      }
      .messagesActions {
        background: $white;
      }
    }
  }
  h6.heading {
    padding: 10px;
  }
  &.commentBoxFormField {
    padding: 10px;
    height: 100%;
  }
}
h6.heading {
  font-weight: bold;
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding-bottom: 10px;
}
.addDocumentForm {
  .addDocumentFormFields {
    @include whiteShadow;
  }
  .customFileUpload {
    margin: 10px 0 20px;
    label {
      height: 250px;
      display: block;
      padding-top: 80px;
      text-align: center;
      div {
        display: block;
        > span {
          &:first-child {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 3px;
            font-weight: normal;
            font-size: 14px;
            span {
              text-decoration: none;
            }
          }
          &:last-child {
            display: block;
            margin: 0 auto;
          }
        }
      }
      svg {
        background: url("../images/icons/document.svg") no-repeat center center;
        background-size: contain;
        width: 40px;
        height: 40px;
        path {
          opacity: 0;
        }
      }
    }
  }
}
.toggleProfileDetail {
  text-align: right;
  span {
    color: $primaryDarkColor;
    font-size: 14px;
    font-weight: bold;
    cursor: pointer;
    img {
      width: 25px;
      height: 25px;
      filter: invert(28%) sepia(61%) saturate(7326%) hue-rotate(198deg) brightness(95%) contrast(101%);
    }
    &.hideDetail {
      img {
        transform: rotate(180deg);
      }
    }
  }
}
.modalCloseButton {
  text-align: right;
  img {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}
.addReviewForm {
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
  padding: 30px 0;
  .formActionButtons {
    justify-content: center;
  }
}
.patientOptions {
  margin-top: 20px;
  .careplanOptions {
    .cards {
      cursor: pointer;
    }
  }
}
.tabContainer {
  margin: 20px 0 0;
  padding: 0;
  border: 0;
  li.nav-item {
    border-radius: 0;
    .nav-link {
      font-size: 14px;
      color: $greyColor;
      &.active {
        // font-weight: bold;
        color: $black;
      }
    }
  }
}
.tab-content {
  position: relative;
  .requestContentCards {
    padding: 20px;
    background: $white;
    border: 1px solid rgba(193, 195, 200, 0.5) !important;
    border-top: 0;
  }
}
.titleandButton {
  @include flexbox(row, space-between, center, wrap);
  padding: 0 20px;
  h3 {
    padding-left: 0 !important;
  }
}
.forgmGrouMarginBottomZero {
  .form-group {
    margin-bottom: 0 !important;
  }
}
.doseSchedule {
  .row {
    margin-bottom: 20px;
    &:nth-last-child(2) {
      margin-bottom: 0 !important;
      .form-group {
        margin-bottom: 0;
      }
    }
  }
  .form-group {
    margin-bottom: 0;
  }
  button {
    font-size: 11px;
  }
  span.btn {
    font-size: 11px;
  }
  @include respond-to(medium) {
    .form-group {
      margin-bottom: 20px;
    }
  }
}
.medicineInventoryStock {
  .modal-dialog {
    .modal-content {
      .modal-body {
        max-height: unset;
        height: auto;
      }
    }
  }
}
.riskCalculatorFields {
  @include flexbox(row, space-between, center, nowrap);
  gap: 20px;
  .field {
    flex: 1;
  }
}
.selectBoxLevel {
  margin-top: -25px;

  .css-13cymwt-control {
    min-height: 40px;
  }
  &.shiftSchedule {
    font-size: 13px;
    .css-1hb7zxy-IndicatorsContainer {
      display: none;
    }
  }
}
.checkboxesList {
  @include flexbox(row, flex-start, flex-start, nowrap);
  gap: 20px;
  margin-bottom: 25px;
  .form-check-input[type="radio"] {
    width: 15px;
    height: 15px;
    border-width: 2px;
  }
  label {
    color: #777d80;
    font-size: 14px;
    margin-left: 5px;
  }
}
.addScheduleTiming {
  @include flexbox(row, flex-start, flex-start, wrap);
  gap: 10px;
  margin-bottom: 30px;

  div {
    width: 31%;
    .form-check {
      width: 155px;
      padding-left: 17px;
    }
    @include respond-to(medium) {
      width: 48%;
    }
  }
}
.hiddenDiv {
  display: none;
}
.css-13cymwt-control {
  min-height: 40px !important;
}
.wizardSteps {
  .supportArea {
    .checkboxList {
      gap: 20px;
      .checkboxContainer {
        width: auto;
      }
      label {
        margin-left: 10px;
        font-size: 14px;
      }
    }
  }

  table.dynamicRows {
    width: 100%;
    tr {
      &:last-child {
        td {
          background: #fff !important;
          box-shadow: none;
          &:hover {
            background: #fff !important;
            box-shadow: none;
          }
        }
      }
      td,
      th {
        padding: 10px;
        font-size: 13px;
        &:last-child {
          text-align: center;
        }
        &.leftAlignText {
          text-align: left;
        }
        .form-group {
          margin: 0;
        }
      }
    }
  }
}
.actionsPoint,
.keyPoints {
  table.dynamicRows {
    tr {
      td,
      th {
        padding: 10px;
        &:nth-child(1) {
          width: 70%;
        }
        &:nth-child(2) {
          width: 25%;
        }
        &:nth-child(3) {
          width: 5%;
        }
        .checkboxContainer {
          width: 20px;
          margin: 0 auto;
        }
      }
    }
  }
}
.keyPoints {
  table {
    tr {
      td {
        &:nth-child(2) {
          text-align: center;
        }
      }
    }
  }
}
.incidentsPoint {
  table.dynamicRows {
    tr {
      td,
      th {
        padding: 10px;
        &:nth-child(1) {
          width: 25%;
        }
        &:nth-child(2) {
          width: 70%;
        }
        &:nth-child(3) {
          width: 5%;
        }
      }
    }
  }
}
.riskAssesmentsPoints {
  table.dynamicRows {
    thead {
      tr {
        &:first-child {
          th {
            &:nth-child(1) {
              width: 50%;
            }
            &:nth-child(2) {
              width: 25%;
            }
            &:nth-child(3) {
              width: 25%;
            }
            // &:nth-child(4) {
            //   width: 5%;
            // }
          }
        }
      }
    }
    tbody {
      td {
        &:nth-child(1) {
          width: 4%;
        }
        select {
          width: 250px;
          margin: 0 auto;
        }
      }
    }
    tr {
      td,
      th {
        text-align: center;
        padding: 5px 10px;
        vertical-align: middle;
      }
    }
  }
}
.summaryOfPlansPoints {
  table.dynamicRows {
    tr {
      td {
        padding: 10px;
        vertical-align: middle;
        &:nth-child(1) {
          width: 30%;
        }
        &:nth-child(2) {
          width: 70%;
        }
      }
    }
  }
}
.individualReviewsActions {
  img {
    width: 24px;
    height: 24px;
    max-width: 100%;
  }
}
img.passwordViewIcon {
  background: $greyLightColor;
  border-radius: 50%;
  padding: 2px;
  width: 24px;
  height: 24px;
}
.enteredBy {
  margin-bottom: 50px;
  .usersNameInfo {
    img {
      width: 60px;
      height: 60px;
    }
    .userInfo {
      span {
        font-weight: normal;
      }
      strong {
        font-size: 16px;
        font-weight: bold;
      }
    }
  }
}
.feedBackIcons {
  width: 30px;
  height: 30px;
  background: $white;
  border-radius: 50%;
  margin: 0 auto;
  img {
    width: 100%;
  }
  &.Inadequate {
    background: #e81224;
    img {
    }
  }
  &.RequiresImprovement {
    background: #f7630c;
    img {
    }
  }
  &.Good {
    background: #fff100;
    img {
    }
  }
  &.Outstanding {
    background: #16c60c;
    img {
    }
  }
  &.notSubmited {
    opacity: 0.3;
    background: rgba($greyColor, 0.1);
  }
}
.carePlanDataTableWrapper {
  .jNIkof {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}

.lastModifiedList {
  @include whiteShadowBg;
  margin-top: 30px;
  padding: 20px;
  height: calc(100% - 30px);
  header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 0 0 10px;
    margin-bottom: 20px;
    h3 {
      font-size: 18px;
    }
  }
  .lastModifiedListContent {
    @include flexbox(row, flex-start, center, wrap);
    gap: 20px;

    .listContentItem {
      flex: 1 0 calc(50% - 20px); /* Adjust the percentage and gap as needed */
      max-width: calc(50% - 20px); /* Adjust the percentage and gap as needed */
      // min-width: 274px;
      background: #ffffff;
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
      border-radius: 5px;
      padding: 20px;
      margin-bottom: 0;

      @include whiteShadowBg;
      small {
        font-weight: normal;
      }
      // @include respond-to(xlarge) {
      //   flex: 1 0 calc(33.333% - 20px);
      //   max-width: calc(33.333% - 20px);
      // }
      @include respond-to(large) {
        flex: 1 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        margin: 10px auto;
      }
      @include respond-to(mlarge) {
        flex: 1 0 calc(100% - 20px);
        max-width: calc(100% - 20px);
        margin: 10px auto;
      }
      @include respond-to(medium) {
        flex: 1 0 calc(50% - 20px);
        max-width: calc(50% - 20px);
        margin: 10px auto;
      }
      @include respond-to(smedium) {
        flex: 1 0 calc(100% - 20px);
        max-width: calc(100% - 20px);
        margin: 10px auto;
      }
      @include respond-to(small) {
        min-width: 100%;
      }
    }
  }
}
.emojiInstructions {
  .instructionsContent {
    @include flexbox(row, center, center, nowrap);
    gap: 50px;
    padding: 30px 0 30px;
    .item {
      text-align: center;
      p {
        margin: 0;
      }
      img {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        &.Inadequate {
          background: #e81224;
        }
        &.RequiresImprovement {
          background: #f7630c;
        }
        &.Good {
          background: #fff100;
        }
        &.Outstanding {
          background: #16c60c;
        }
      }
    }
  }
}
.commonOptionsHeaders {
  @include flexbox(row, space-between, center, wrap);
  @include whiteShadowBg;
  padding: 15px;
  margin-bottom: 15px;
  h4 {
    margin: 0;
  }
}
.optionsBlocks {
  @include whiteShadowBg;
  padding: 10px;
  p {
    margin: 0;
    padding: 0;
  }
}
.isReviewed {
  label {
    margin-top: 3px;
    font-weight: normal;
  }
}
p.createdBy {
  font-size: 14px;
  font-weight: normal;
  margin: 10px 0;
  small {
    margin-top: 0 !important;
  }
}
.downloadLink {
  @include flexbox(row, space-between, center, wrap);
  .viewIcons {
    img {
      width: 30px;
      height: 30px;
    }
  }
}
.referenceDiv {
  height: 0;
  width: 0;
  font-size: 0;
}
.observationImg {
  max-width: 100%;
}
.sc-eDPEul {
  background: transparent !important;
}
.allCarePlansNotes {
  .sc-eDPEul {
    display: none;
  }
}
.searchFiltersFields {
  .validationError {
    margin-top: -15px;
  }
}
.initialNumbersOfInc {
  @include flexbox(row, flex-start, center, wrap);
  gap: 10px;
  margin-top: 10px;
  span {
    // width: 20%;
    text-align: center;
    background: rgba($greyColor, 0.1);
    padding: 5px 10px;
    display: block;
    cursor: default;
  }
}
.preLoaderWrapper {
  background: $white;
  padding: 30px;
  text-align: center;
  .preloader-container {
    position: relative;
    top: auto;
    left: 0;
  }
}

.reviewListHeader {
  .actionColumns {
    justify-content: flex-end;
    gap: 10px;
  }
}
.patientReviewCard {
  .card {
    .cardContent {
      .contentIcon {
        img {
          filter: brightness(0) saturate(100%) invert(47%) sepia(20%) saturate(4153%) hue-rotate(188deg) brightness(95%)
            contrast(94%) !important;
        }
      }
    }
  }
}
.capitalizeTransform {
  text-transform: capitalize;
}
.dateInputWrapper {
  position: relative;
  .inputTypeText {
    background: url("../images/icons/dateIcon.svg") no-repeat 94% center;
    background-size: 11px;
  }

  .inputTypeDate {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
  }
}

.holidaysAbsence {
  height: calc(100% - 20px);
}
.grid-item {
  display: block;
  width: 100%;
  height: 250px;
}
.applicationProcessWrapper {
  .tableTitle {
    @include whiteShadowBg;
    padding: 10px 15px;
    margin-bottom: 20px;
  }
  .rowRecruitment {
    @include flexbox(row, space-between, flex-start, nowrap);
    min-width: 1400px;
    height: calc(100vh - 185px);
    // overflow: auto;
    gap: 10px;
    .recColumn {
      width: 20%;

      header {
        font-weight: bold;
        width: 100%;
        background: $white;
        border-radius: 0;
        min-height: 60px;
        @include flexbox(column, center, center, nowrap);
        small {
          display: block;
          font-weight: normal;
          font-size: 65%;
        }
      }
    }
  }
  .container {
    display: flex;
    touch-action: none;
    width: 100%;
    margin: 1rem auto;
  }

  .dropzone {
    position: relative;
    flex: 1;
    min-height: calc(100vh - 250px);
    margin-top: 60px;
    border-radius: 0;
    padding: 10px;
    // overflow-y: auto;
    // overflow-x: hidden;
    background: rgba(0, 0, 0, 0.05);
    // height: 400px;
    border: 1px solid rgba(0, 0, 0, 0.01);
    // border-radius: 1rem;
    margin-bottom: 20px;
  }

  .dropzone.left {
    margin-right: 10px;
  }

  .grid-item {
    padding: 0 0 10px 0;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
    cursor: move;
  }
  .GridItem {
    margin-top: 10px;
    width: calc(100% - 20px) !important;
    left: auto !important;
    right: auto;
  }
  .grid-item-content {
    background: $white;
    padding: 20px;
    // display: flex;
    // justify-content: center;
    // align-items: center;
    box-shadow: 0 0 5px rgba($greyColor, 0.1);
    header.gridContent {
      @include flexbox(row, space-between, center, nowrap);
      gap: 10px;
      padding: 0 !important;
      margin-bottom: 0;
    }
    span {
      display: block;
      &.edit {
        text-align: right;
        img {
          width: 25px;
          height: 25px;
        }
      }
    }
    span.dpImage {
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        background: rgba($greyLightColor, 0.5);
      }
    }

    .userInfo {
      padding: 0;

      span {
        display: block;
        margin: 5px 0 0;
        font-size: 12px;
      }
      .nameBlock {
        font-weight: bold;
        font-size: 14px;
      }
    }
  }
}
.candidateDetailPopup {
  padding: 20px !important;
  header {
    @include flexbox(row, flex-start, center, nowrap);
    gap: 20px;
    padding: 10px 0;
    margin-bottom: 10px;
  }
  span.dpImage {
    img {
      width: 80px;
      height: 80px;
      border-radius: 50%;
      background: rgba($greyLightColor, 0.5);
    }
  }

  .userInfo {
    .nameBlock {
      font-weight: bold;
      font-size: 16px;
      small {
        font-weight: normal;
        margin-left: 10px;
        font-size: 70%;
        text-transform: capitalize;
      }
    }
    span {
      display: block;
      margin: 5px 0 0;
      font-size: 14px;
    }
  }
  main {
    p {
      strong {
        display: block;
      }
    }
  }
}
.candidateProfile {
  margin-bottom: 30px;
  display: block;
  img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
  }
}
p.attachedFile {
  background: rgba($greyColor, 0.08);
  padding: 10px;
  border-radius: 5px;
  a {
    @include flexbox(row, flex-start, flex-end, nowrap);
    gap: 10px;
    color: $black;
    img {
      width: 25px;
      height: 25px;
    }
  }
}
.checkedSUinvolved {
  margin-bottom: 20px;
}
.myContactsPoints {
  table.dynamicRows {
    tr {
      td,
      th {
        padding: 20px;
        &:nth-child(1) {
          width: 95%;
        }

        &:nth-child(2) {
          width: 5%;
          vertical-align: middle;
        }
        h3 {
          font-size: 16px;
          font-weight: bold;
          margin: 0 0 20px 0;
        }
        .col-md-12 {
          .form-group {
            margin: 0;
          }
        }
      }
    }
    tfoot {
      tr {
        td {
          margin: 0;
          padding: 0;
          &:nth-child(2) {
            text-align: center;
            img.addRow {
              margin: 10px 0;
            }
          }
        }
      }
    }
  }
}
.suInvolvement {
  display: block;
  line-height: normal;
}
.attachedDocumentWrapper {
  @include flexbox(row, flex-start, center, wrap);
  gap: 10px;
  .alert {
    width: 30%;
    text-align: center;
    padding: 10px;
    a {
      color: rgba($greyDarkColor, 1);
      font-size: 14px;
    }
  }
}
.archievedCommentBox {
  margin-top: 10px;
}
.notesWrapper {
  background: rgba($greyColor, 0.08);
  padding: 10px;
  border-radius: 10px;
  margin-bottom: 20px;
  .notesContent {
    p {
      strong,
      span {
        font-size: 13px;
      }
      strong,
      span,
      small {
        display: block;
      }
      small {
        text-transform: capitalize;
      }
    }
  }
}
.actionIconsButtons {
  @include flexbox(row, flex-end, center, nowrap);
  gap: 10px;
  span {
    display: block;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    &.disabledOnClickButton {
      background: $black;
      opacity: 0.2;
      img {
        filter: brightness(0) saturate(100%) invert(88%) sepia(100%) saturate(1%) hue-rotate(72deg) brightness(104%)
          contrast(101%);
        border-radius: 0;
        padding: 3px;
      }
    }
    &.completeOnBoard {
      background: $green;
      cursor: pointer;

      img {
        filter: brightness(0) saturate(100%) invert(88%) sepia(100%) saturate(1%) hue-rotate(72deg) brightness(104%)
          contrast(101%);
        border-radius: 0;
        padding: 4px;
      }
    }
    img {
      max-width: 100%;
      border-radius: 50%;
    }
  }
}
.mainContent {
  &.minHeight {
    min-height: 300px;
  }
}
span.contentcell {
  display: block;
}
.contentDataCell {
  @include flexbox(row, space-between, flex-start, nowrap);
  gap: 10px;
}
.shiftActionButtons {
  @include flexbox(row, center, center, nowrap);
  gap: 10px;
  margin-bottom: -10px;
  position: relative;
  img {
    width: 20px;
    height: 20px;
  }
}
.inActiveWeek {
  opacity: 0.6;
  filter: blur(1px);
}

.rosterDragnDrop {
  .container {
    display: flex;
    touch-action: none;
    width: 800px;
    margin: 1rem auto;
  }

  .dropzone {
    flex: 1;
    height: 800px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    background: #ff0000;
  }

  .dropzone.left {
    margin-right: 10px;
  }

  .grid-item {
    padding: 10px;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
  }

  .grid-item-content {
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background: #08e;
    display: flex;
    justify-content: center;
    color: white;
    font-family: Arial, Helvetica, sans-serif;
    align-items: center;
  }
}
.staffRolesList {
  margin: 20px 0 !important;
  padding-left: 20px !important;
}
.permissionDenied {
  text-align: center;
  img {
    width: 50px;
    height: 50px;
    margin-bottom: 30px;
  }
}
.attachmentChat {
  width: 20px !important;
  height: 20px !important;
}
.groupMemberName {
  font-weight: bold;
  display: block;
  font-size: 10px;
}

.myRosterDragNDrop {
  .container {
    padding: 20px;
  }

  table {
    width: 100%;
    border-collapse: collapse;
  }

  tr {
    height: 70px;
  }

  td {
    border: 1px solid #ddd;
    text-align: center;
    vertical-align: middle;
    width: 33.33%; /* For a 3-column layout */
  }

  .grid-item {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ccc;
    height: auto;
  }
  .dropzone {
    flex: 1;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1rem;
    background: #ff0000;
  }

  .grid-item-content {
    font-size: 18px;
    font-weight: bold;
  }
}
.border-dark {
  border: 1px solid gray;
}
.payrollSubHeader {
  background: $white;
  padding: 0;
  margin-bottom: 20px;
  .payrollDateFilter {
    @include flexbox(row, flex-start, center, nowrap);
    gap: 10px;
    .form-group {
      margin: 0 0 10px;
      min-width: 130px;
    }
  }
  .radioTabsHeader {
    .tabsBlocks {
      width: 100%;
    }
  }
}
.payrollConfirmation {
  img.payrollIcon {
    width: 80px;
    height: 80px;
    margin: 20px 0;
  }
  .modal-dialog {
    .modal-content {
      .modal-footer {
        justify-content: center;
      }
    }
  }
}
.payrollActionButtons {
  margin-left: auto;
  display: flex;
  gap: 10px;
}
.trainingQuizzesWrapper {
  .actionsPoint {
    table {
      tr {
        td,
        th {
          vertical-align: middle;
          img.addRow {
            margin: 0;
          }
        }
      }
    }
    .form-group {
      margin-bottom: 0;
    }
  }
}
.staffMemberWrapper {
  padding: 10px 0;
  @include flexbox(row, flex-start, center, wrap);
  gap: 15px;
  .claimedStatus {
    font-weight: normal;
    text-align: center;
    padding: 3px;
    border-radius: 3px;
    &.background-success,
    &.background-danger {
      cursor: no-drop;
    }
    &.background-warning {
      cursor: pointer;
    }
    span {
      font-size: 12px !important;
    }
  }
}
.staffWorkLoadHeader {
  @include flexbox(row, space-between, center, nowrap);
  margin: 0 0 10px;
  h3 {
    margin: 0;
  }
}
#uploadedFilePathData {
  img {
    max-width: 250px;
  }
}
.greyBackground {
  background: $greyLightestColor;
  border: 2px solid $greyImageBg;
  padding: 10px 10px 0 10px;
  margin-bottom: 20px;
  border-radius: 10px;
}
.assignedToolTipWrapper {
  .tooltip-inner {
  }
}
.alreadyAssigned {
  img {
    width: 20px;
    height: 20px;
    margin: -10px 0 0 10px;
    cursor: pointer;
  }
}
.assignedNoRecordFound {
  .preloader-container {
    left: 40%;
  }
}

.requestChanges {
  padding: 10px 0;
  line-height: 20px;
}
.staffRecordsGroupsTable {
  .rdt_TableHeader {
    > div {
      position: relative;
      width: 100%;
    }
  }
}
.buttonWrap {
  @include flexbox(row, flex-end, center, wrap);
  max-width: 450px;
  width: 100%;
  gap: 10px;
}
.medicationDetail {
  background: rgba($greyColor, 0.05);
  padding: 20px;
  border-radius: 10px;
  margin: 0 auto 20px;
  width: 98%;
  .row {
    .col-md-3,
    p {
      font-size: 12px !important;
      margin-bottom: 0;
      font-weight: normal;
      strong {
        font-weight: bold;
      }
    }
  }
}
.taskList {
  .usersNameInfo {
    border-bottom: 0 !important;
    img {
      width: 30px !important;
      height: 30px !important;
      margin-top: 0;
      margin-bottom: 0;
    }
  }
}
table.overViewTaskDetail {
  width: 100%;
  tr {
    td,
    th {
      width: 25%;
      vertical-align: middle;
    }
  }
}
.fontWeightNormal {
  font-weight: normal !important;
}
header.primaryHeader {
  background: $primaryColor;
  color: $white;
  h3 {
    color: $white;
  }
}
.textareaTooltip {
  position: absolute;
  top: -10px;
  right: 0;
  img {
    background: #fff;
    border-radius: 50%;
  }
}
.memberSelectBox {
  .select__menu-list {
    position: relative;
  }
}
