select,
input,
textarea,
button {
  font-size: 12px;
  color: $greyColor;
  &:focus,
  &:focus-visible {
    outline: 1px solid rgba($greyColor, 0.8);
  }
}
input[type="checkbox"],
input[type="radio"] {
  &:focus,
  &:focus-visible {
    outline: none;
  }
}
.btn {
  .btn-sm {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 13px !important;
  }
  &.btn-primary.btn-sm {
    background: $primaryColor;
    border-color: $primaryColor;
    &:hover {
      background: $primaryDarkColor;
      border-color: $primaryDarkColor;
    }
  }
  &.btn-danger.btn-sm {
    background: $redColor;
    border-color: $redColor;
    &:hover {
      background: $redPinkColor;
    }
  }
  &.btn-outline-primary.btn-sm {
    border-color: $primaryColor;
    color: $primaryColor;
    &:hover {
      background: $primaryDarkColor;
      border-color: $primaryDarkColor;
      color: $white;
    }
  }
}
.dropdown {
  .btn.btn-sm.btn-outline-primary {
    background: transparent;
    &:hover {
      background: $primaryDarkColor;
      border-color: $primaryDarkColor;
      color: $white;
    }
  }
}
.searchContainer {
  .searchTextBox {
    input,
    select {
      width: 100%;
      box-sizing: border-box;
      padding: 6px 10px 6px 30px;
      border: 1px solid rgba($greyColor, 0.3);
      border-radius: 5px;
      font-size: 12px;
      background: url("../images/icons/searchIcon.svg") no-repeat 5px center $white;
      background-size: 18px;
      min-width: 300px;
    }
    select {
      background-image: none;
      padding: 6px 10px;
    }
  }

  @include respond-to(medium) {
    .searchTextBox {
      input,
      select {
        min-width: 100%;
      }
    }
  }
}
.rosterTabsContent {
  .searchContainer {
    .searchTextBox {
      input {
        min-width: 100%;
      }
    }
  }
}
.currentStatus {
  @include flexbox(row, space-between, center);
  gap: 10px;
  margin: 0;
  span {
    display: block;
    background: $green;
    width: 10px;
    height: 10px;
    border-radius: 50%;
  }
}
.headerSelect {
  padding: 8px;
  border-radius: 5px;
  border: 1px solid rgba($greyColor, 0.3);
}

/* The container */
.radioButtonContainer {
  @include flexbox(row, flex-start, center);
  position: relative;
  padding-left: 25px;
  margin-bottom: 0;
  margin-right: 10px;
  cursor: pointer;
  font-size: 14px;
  user-select: none;
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }
  .checkmark {
    position: absolute;
    top: 2px;
    left: 0;
    height: 16px;
    width: 16px;
    background-color: $white;
    border-radius: 50%;
    border: 1px solid $primaryColor;
  }
  &:hover input ~ .checkmark {
    background-color: #ccc;
  }
  input:checked ~ .checkmark {
    background-color: #2196f3;
  }
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
  .checkmark:after {
    top: 3px;
    left: 3px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background: white;
  }
  .statusCircle {
    margin-left: 10px;
  }
}

.form-group {
  position: relative;
  margin-bottom: 20px;
  .text-danger {
    font-size: 12px;
    margin: 5px 0;
  }
  label.floatingLabel {
    display: block;
    color: #777d80;
    position: absolute;
    top: -9px;
    left: 5px;
    pointer-events: none;
    transition: 0.2s ease-out;
    background: #ffffff;
    padding: 0 5px;
    font-size: 12px;
  }
  input,
  textarea,
  select {
    width: 100%;
    padding: 10px 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    color: #777d80;
    font-size: 13px;
    backdrop-filter: saturate(0.5);
    &:active,
    &:focus {
      border-color: #007bff;
      outline: none;
      border: 1px solid #ccc;
    }
    &::placeholder {
      color: rgba($greyColor, 0.3);
    }
    &:disabled {
      background: hsl(0, 0%, 95%) !important;
    }
  }
  select {
    padding: 12px 10px !important;
  }
  select.form-select {
    padding: 10px !important;
  }
  input[type="date"] {
    // + label {
    //   // width: 80%;
    // }
    &:valid,
    &:focus {
      + label {
        width: auto !important;
      }
    }
  }
  input {
    &:valid,
    &:focus,
    &:read-only {
      + label {
        top: -9px;
        left: 5px;
        font-size: 12px;
        padding: 0 5px;
      }
    }
  }
  textarea {
    &:focus,
    &:valid {
      + label {
        top: -9px;
        left: 5px;
        font-size: 12px;
        padding: 0 5px;
      }
    }
  }

  select {
    &:valid,
    &:focus {
      + label {
        top: -9px;
        left: 5px !important;
        font-size: 12px;
        padding: 0 5px;
      }
    }
  }
  input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0 1000px white inset; /* Change 'white' to your desired background color */
    -webkit-text-fill-color: #3c4549; /* Set the text color to your desired color */
  }
  input[type="checkbox"] {
    width: auto;
    position: relative;
    top: 2px;
  }

  .validationError {
    input,
    textarea {
      // border-color: $redColor;
    }
  }
}
.css-1xc3v61-indicatorContainer {
  color: #333 !important;
}
.form-group input:disabled + label,
.form-group textarea:disabled + label,
.form-group select:disabled + label,
.disabled label,
label.disabled,
.customSelectBox.disabled label.floatingLabel,
.disabledLabel {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+50,777d80+50,777d80+100 */
  background: linear-gradient(
    to bottom,
    #ffffff 0%,
    #ffffff 50%,
    #f2f2f2 50%,
    #f2f2f2 100%
  ) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
}
.formBlockContent {
  padding: 20px 0;
  .form-group {
    margin-bottom: 30px;
  }
}

.multipleFieldsBox {
  @include flexbox(row, space-between, center);
  gap: 10px;
  .div70 {
    width: 70%;
  }
  .div30 {
    width: 30%;
  }
}
.formActionButtons {
  @include flexbox(row, flex-end, center);
  gap: 10px;
  margin-top: 20px;
}
.editableTextArea {
  width: 100%;
  &:read-only {
    opacity: 0.5;
    background: rgba($greyColor, 0.3);
  }
}
.trueFalseCont {
  @include flexbox(flex, flex-start, center, nowrap);
  gap: 10px;
}
.phoneField {
  @include flexbox(flex, flex-start, center, nowrap);
  gap: 5px;
  .form-group.selectBoxGroup {
    width: 35%;
  }
  .form-group {
    width: 65%;
  }
}

input[type="file"] {
  width: 100%;
  position: relative;
  padding: 7.5px 10px;
}

input::file-selector-button {
  position: relative;
  right: -25px;
  border-radius: 0;
  background: $primaryColor;
  float: right;
  color: #fff !important;
}

input[type="file"]:after {
  content: "Choose File";
  display: block;
  width: 130px;
  height: 100%;
  background: $primaryColor;
  font-size: 12px;
  position: absolute;
  top: 0;
  right: 0;
  text-align: center;
  padding: 12px 0 0 0;
  color: #fff;
}
input[type="file"].uploadProfileImage:after {
  content: "Upload Profile Image";
}

input[type="file"].uploadDocument:after {
  content: "Upload Document";
}
input[type="file"].replaceDocument:after {
  content: "Replace Document";
}
input[type="file"].fileUploading:after {
  content: "Please wait....";
}

.select-box {
  border-radius: 20px;

  select {
    background-color: transparent;
    border: 0;
    font-size: 12px;
  }
  &.claimedSelect {
    border-radius: 10px;
    .form-select:disabled {
      border-radius: 10px;
    }
    &.background-success,
    &.background-danger {
      opacity: 0.5;
      .form-select:disabled {
        background-color: inherit;
      }
    }
  }
}
.status-select-box {
  select {
    width: 120px !important;
    padding: 7px 0 7px 29px;
  }
}
.validationError {
  input,
  textarea {
    // border: 1px solid $redColor !important;
    // color: $redColor !important;
  }
  label.floatingLabel {
    // color: $redColor !important;
  }
  span.error {
    color: $redColor !important;
  }
  .text-danger {
    color: $redColor !important;
    font-size: 12px;
  }
}

.customFileUpload {
  label {
    width: 100%;
    max-width: 100%;
    background: rgba($greyLightColor, 0.1);
    border: 1px solid rgba($greyLightColor, 0.4);
  }
}
.errorClass {
  .form-group {
    margin-bottom: 0;
  }
  margin-bottom: 20px;
  font-size: 12px;
}
.assignBox {
  border-radius: 5px;
  min-width: 100px;
  .css-t3ipsp-control {
    box-shadow: none;
  }
  .css-1nmdiq5-menu {
    padding: 0;
  }
  .css-1xc3v61-indicatorContainer {
    padding-top: 0;
    padding-bottom: 0;
  }
  .css-13cymwt-control {
    background: transparent;
    border: 0;
    min-height: unset !important;
    .css-1fdsijx-ValueContainer {
      padding: 0 10px;
      .css-1jqq78o-placeholder {
        color: $white;
      }
      .css-1dimb5e-singleValue {
        color: $white;
      }
    }
    svg {
      color: $white;
      filter: invert(0%) sepia(0%) saturate(0%) hue-rotate(305deg) brightness(103%) contrast(103%);
    }
  }

  &.selectValueClass {
    background: $primaryColor;
  }
  &.selectedClass {
    background: $green;
  }
}
.css-1nmdiq5-menu {
  min-width: 120px !important;
  font-size: 12px;
  right: 0;
}
.modal-body {
  .css-1nmdiq5-menu {
    max-height: 180px;
    overflow-y: auto;
    * {
      height: 100%;
      overflow: unset;
    }
  }
}
.form-check-input[type="checkbox"],
.form-check-input[type="radio"] {
  width: 17px;
  height: 17px;
  border: 1px solid $primaryColor;
}
.form-check {
  label {
    margin-left: 5px;
    font-size: 14px;
    color: $greyColor;
  }
}
label.general {
  font-size: 14px;
  color: $greyColor;
}
.jodit-wysiwyg {
  min-height: 300px !important;
  height: 300px !important;
}
.jodit-placeholder {
  z-index: 0;
}
.emojiRadioButtons {
  @include flexbox(row, space-around, center, nowrap);
  gap: 5px;
  .col img {
    height: 20px;
    width: 20px;
    cursor: pointer;
    transition: transform 1s;
    object-fit: cover;
  }
  .col label {
    overflow: hidden;
    position: relative;
  }

  /* ANIMATION */
  .imgbgchk:checked + label > img {
    background: $redColor;
    border-radius: 50%;

    &.Inadequate,
    &.Angry,
    &.Worried {
      background: #e81224;
    }
    &.RequiresImprovement,
    &.Excited,
    &.Scared {
      background: #f7630c;
    }
    &.Good,
    &.Happy,
    &.Proud,
    &.Hopeful,
    &.Confident {
      background: #fff100;
    }
    &.Outstanding,
    &.Nervous {
      background: #16c60c;
    }

    &.Bored,
    &.Sad,
    &.Relaxed,
    &.Calm,
    &.Tired {
      background: #55bbd1;
    }
  }

  &.moodRatingButtons {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 0;
    .col {
      width: 14.28%;
      flex: none;
      margin: 20px 0;
      text-align: center;
      label {
        text-align: center;
        img {
          width: 50px;
          height: 50px;
        }
        span {
          display: block;
          font-size: 12px;
          font-weight: bold;
          margin-top: 10px;
        }
      }
    }
  }
}
.jodit-add-new-line.jodit-add-new-line_after {
  display: none;
}
.archiveTheUserButton {
  button.btn {
    font-size: 12px;
  }
}
.weekSelector {
  max-width: 150px;
}
.select__control--is-focused {
  box-shadow: none !important;
  // border-width: 1px !important;
  padding-top: 2px;
  padding-bottom: 2px;
  outline: 0 !important;
}
.permissionDenied {
  a.btn-outline-danger {
    color: $redColor !important;
    border-color: $redColor;
    &:hover,
    &:focus {
      background: $redColor !important;
      color: $white !important;
    }
  }
}
.form-group {
  .datePicker {
    background: url("../images/icons/caldendarDatePicker.svg") no-repeat calc(100% - 10px) center transparent;
    background-size: 12px;
    cursor: pointer;
    &:disabled {
      background: url("../images/icons/caldendarDatePicker.svg") no-repeat calc(100% - 10px) center hsl(0, 0%, 95%) !important;
      background-size: 12px !important;
      cursor: not-allowed;
      pointer-events: all !important;
      width: 130px;
    }
  }
}
.react-datepicker-popper {
  z-index: 9;
  line-height: 0;
}
.addRowSpan {
  img.addRow {
    margin: 0;
  }
}
.questionBox {
  @include flexbox(row, space-around, center, nowrap);
  gap: 5px;
  position: relative;
  margin-bottom: 20px;
  .form-group {
    width: 100%;
    input {
      padding-right: 40px;
    }
  }
  .removeQuestionButton {
    position: absolute;
    right: 10px;
  }
}
.choiceBox {
  @include flexbox(row, space-around, center, nowrap);
  gap: 5px;
  position: relative;
  margin-bottom: 20px;
  .isRightAnswer {
    width: 20px;
    height: 20px;
    display: block;
  }
  .form-group {
    width: 100%;
    input {
      padding: 5px;
    }
  }
  .removeButton {
    position: absolute;
    top: -10px;
    right: 0;
    img {
      width: 15px;
      height: 15px;
    }
  }
}
.isPrnAlert {
  font-size: 12px;
  margin: 5px auto 10px 0;
  padding: 0 25px;
  border-radius: 0;
}
.form-group {
  .react-datepicker-wrapper {
    width: 100%;
  }
}
.react-datepicker-popper {
  transform: translate(-29%, 16%) !important; /* Moves the calendar to the left */
}
