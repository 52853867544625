.currentHealthIssuesWrapper {
  .currentHealthIssues {
    // @include whiteShadowBg;
    min-height: calc(100vh - 100px);
    &.carePlanCurrentHealthIssues{
      min-height: unset;
    }
    .tabContainer {
      margin-top: 10px;
    }
    h3 {
      font-size: 18px;
      padding: 20px 20px 10px;
    }
    .titleandButton {
      background: $white;
      border-radius: 5px;
    }
    .tabContents {
      background: $white;
      background: url("../images/healthIssueBg.svg") no-repeat center bottom $white;
      background-size: contain;
      border: 1px solid rgba($greyLightColor, 0.5) !important;
      border-top: 0;
    }
    .partsOfBodyHeader {
      border-bottom: 1px solid rgba($greyLightColor, 0.5);
      // border-top: 1px solid rgba($greyLightColor, 0.5);

      position: relative;

      .rootSpanWrapper {
        position: relative;
        display: flex;
        /* Allow flex items to wrap */
        flex-wrap: nowrap; /* Ensure items don't wrap to the next line */
        gap: 10px; /* Adjust the gap between spans */
        overflow-x: auto; /* Enable horizontal scrolling */
        /* Set a minimum width to ensure the container has a size even when empty */
        min-width: 100%; /* Adjust this to suit your layout */
        padding: 10px 10px;
        .rootSpan {
          width: auto;
          white-space: nowrap;
        }
      }
      .preloader-container {
        .preloader-spinner {
          width: 20px;
          height: 20px;
        }
      }
      span.bodyPartsLink {
        font-size: 13px;
        cursor: pointer;
        padding: 6px 15px;
        border-radius: 5px;
        &.active {
          background: rgba($primaryLightColor, 0.3);
          color: $primaryColor;
        }
        &:hover {
          border: 0;
          color: $primaryColor;
        }
      }
    }
    .partsOfBodyContent {
      padding-bottom: 50px;
      .humanBody {
        width: 100%;
        max-width: 360px;
        margin: 20px auto 50px;
        position: relative;
        img {
          max-width: 100%;
        }
        .targetPoint {
          width: 2.5%;
          height: 2%;
          position: absolute;
          border: 1px solid $white;
          border-radius: 50%;
          .target {
            display: block;
            width: 100%;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            cursor: pointer;

            &:hover,
            &:focus,
            &:active {
              background: $primaryColor;
            }
          }
          img[aria-describedby],
          img.target.notActiveCirlce {
            filter: invert(52%) sepia(73%) saturate(1533%) hue-rotate(98deg) brightness(90%) contrast(98%);
          }
          &.head {
            display: none;
          }
          &.fronthead {
            top: 11%;
            left: 19.5%;
          }
          &.backhead {
            top: 11%;
            left: 77.5%;
          }

          &.eyes {
            display: none;
          }
          &.righteye {
            top: 14%;
            left: 17%;
          }
          &.lefteye {
            top: 14%;
            left: 22%;
          }

          &.ears {
            display: none;
          }
          &.rightear {
            top: 15%;
            left: 14%;
          }
          &.leftear {
            top: 15%;
            left: 25.5%;
          }

          &.nose {
            top: 16%;
            left: 19.5%;
          }

          &.mouth {
            top: 18%;
            left: 19.5%;
          }

          &.neck {
            top: 21%;
            left: 19.5%;
          }

          &.nape {
            top: 20%;
            left: 77.5%;
          }

          &.shoulders {
            display: none;
          }

          &.rightshoulder {
            top: 26%;
            left: 7.5%;
          }

          &.leftshoulder {
            top: 26%;
            left: 31.5%;
          }

          &.shoulderblade {
            display: none;
          }

          &.rightshoulderblade {
            top: 26%;
            left: 88.5%;
          }

          &.leftshoulderblade {
            top: 26%;
            left: 66.5%;
          }

          &.arms {
            display: none;
          }

          &.rightArm {
            top: 35%;
            left: 5.5%;
          }

          &.leftArm {
            top: 35%;
            left: 33.5%;
          }

          &.forearm {
            display: none;
          }

          &.rightforearm {
            top: 46%;
            left: 4.5%;
          }

          &.leftforearm {
            top: 46%;
            left: 34.5%;
          }

          &.elbows {
            display: none;
          }

          &.rightelbow {
            top: 41%;
            left: 93%;
          }

          &.leftelbow {
            top: 41%;
            left: 62%;
          }

          &.wrists {
            display: none;
          }

          &.rightwrist {
            top: 51%;
            left: 3%;
          }

          &.leftwrist {
            top: 51%;
            left: 36%;
          }

          &.hands {
            display: none;
          }

          &.righthand {
            top: 55%;
            left: 3%;
          }

          &.lefthand {
            top: 55%;
            left: 36%;
          }

          &.chest {
            top: 30%;
            left: 19.5%;
          }

          &.back {
            display: none;
          }

          &.topback {
            top: 30%;
            left: 77.5%;
          }

          &.middleback {
            top: 38%;
            left: 77.5%;
          }

          &.bottomback {
            top: 47%;
            left: 77.5%;
          }

          &.abdomen {
            top: 40%;
            left: 19.5%;
          }

          &.hips {
            display: none;
          }
          &.lefthip {
            top: 54%;
            left: 67%;
          }
          &.righthip {
            top: 54%;
            left: 88.3%;
          }

          &.buttocks {
            display: none;
          }
          &.rightbuttock {
            top: 52%;
            left: 83%;
          }
          &.leftbuttock {
            top: 52%;
            left: 72%;
          }

          &.groin {
            top: 54%;
            left: 19.5%;
          }

          &.thighs {
            display: none;
          }

          &.rightthigh {
            top: 61%;
            left: 13.5%;
          }

          &.leftthigh {
            top: 61%;
            left: 25.5%;
          }

          &.knees {
            display: none;
          }

          &.rightknee {
            top: 73%;
            left: 13.5%;
          }

          &.leftknee {
            top: 73%;
            left: 25.5%;
          }

          &.calves {
            display: none;
          }

          &.rightcalf {
            top: 81%;
            left: 84%;
          }

          &.leftcalf {
            top: 81%;
            left: 71.5%;
          }

          &.ankles {
            display: none;
          }

          &.leftankle {
            top: 93%;
            left: 25%;
          }

          &.rightankle {
            top: 93%;
            left: 14.5%;
          }

          &.feet {
            display: none;
          }

          &.leftfoot {
            top: 96%;
            left: 25%;
          }

          &.rightfoot {
            top: 96%;
            left: 14.5%;
          }

          &.healthIssueContent {
            display: none;
          }

          &.leftheel {
            top: 94%;
            left: 72.5%;
          }

          &.rightheel {
            top: 94%;
            left: 83%;
          }
        }
      }
    }
  }
}
.popover {
  border-radius: 0;
  border: 0;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  width: 100%;
  max-width: 300px;
  .popover-body {
    padding: 0;
  }
}

.issuePointDetail {
  padding: 0;
  z-index: 2;
  max-height: 300px;
  min-width: 300px;
  overflow-y: auto;
  p {
    // text-align: justify;
    font-size: 13px;
  }
  header {
    .headerRow {
      @include flexbox(flex, space-between, flex-start, nowrap);
      padding: 0;
      margin-bottom: 5px;
    }
    @include bottomBorder;
    padding: 15px 10px 5px 10px;

    h3 {
      font-size: 15px;
      padding: 0;
      margin-bottom: 0;
      small {
        display: block;
        font-weight: normal;
        font-size: 13px;
      }
    }
    p {
      margin-bottom: 0;
      strong {
        display: block;
      }
    }
    select.statusButton {
      padding: 1px 10px 3px;
      font-size: 12px;
    }
  }
  .healthIssueContent {
    padding: 10px 10px 10px;
    @include bottomBorder;
    p {
      margin-bottom: 10px;
      font-size: 13px;
    }
  }
  .dataRow {
    &:nth-child(even) {
      background: $greyLightestColor;
    }
  }
}

.rootSpan {
  span {
    display: block;
    text-align: center;
  }
}
.clickableTargetPoints {
  .clickPoint {
    cursor: pointer;
  }
}
