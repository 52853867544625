.staffOverview {
  .maxWidthTableRowWidth {
    div[role="rowgroup"] {
      div[role="row"] {
        div[role="cell"] {
          &:nth-child(2) {
            padding-left: 16px;
          }
          &:nth-child(3) {
            padding-left: 16px;
          }
          .tableText {
            padding: 16px 15px;
          }
        }
      }
    }
  }
}
// .staffOverview .maxWidthTable div[role="rowgroup"] div[role="row"] div[role="cell"]:nth-child(2) {
//   padding-left: 0;
// }
.d-flex {
  display: flex;
  justify-content: space-between;
}
.d-flex span[role="user-name-avatar"]:first-of-type {
  justify-content: flex-start;
  display: flex;
  gap: 5px;
}
.d-flex span[role="user-name-avatar"] img[role="user-avatar-image"] {
  width: 22px;
  height: auto;
  min-width: 22px;
  max-width: 22px;
}
.select-box Button[role="btn-rejected"] {
  background: #ffcccc !important;
  color: #da0b0b !important;
  border-color: #ffcccc !important;
}
.select-box Button[role="btn-approved"] {
  background: #c8ffd9 !important;
  color: #46a37a !important;
  border-color: #c8ffd9 !important;
}
.staffOverview .maxWidthTable div[role="rowgroup"] div[role="row"] div[role="cell"]:nth-child(3) {
  padding-left: 15px;
}
.phoneFieldContainer span[role="container"] {
  position: absolute;
  z-index: 1;
  margin: 6px 5px 2px 3px;
  width: 11%;
  height: 34px;
  background: transparent;
}
.phoneFieldContainer span[role="container"] select[role="countryCode"] {
  border-radius: 5px;
  object-fit: contain;
  border: none;
  width: 100%;
  height: 100%;
}
.cursor-pointer {
  cursor: pointer;
}
.object-fit {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.display-grid {
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: auto;
}
.pageTitleText img {
  width: 27px;
  height: auto;
}
.modal-closer-icon-container {
  display: block;
  margin-right: 3%;
  cursor: pointer;
}
.modal-closer-icon-container span {
  color: red;
  display: flex;
  font-size: larger;
  margin-left: 93%;
  position: absolute;
  cursor: pointer;
}
.avatarAction .image-upload-icon img {
  height: 36px;
  width: auto;
  max-height: 50px;
}
.padding-column {
  padding: 14px;
}

.greyColor {
  color: $greyColor;
}
.blackColor {
  color: $black;
}
.p-left-15 {
  padding-left: 15px;
}
.p-left-0 {
  padding-left: 0px;
}
.display-flex-end {
  display: flex;
  justify-content: end;
  gap: 8px;
}
.addPlusIcon {
  width: 24px;
  height: auto;
  max-width: 24px;
  cursor: pointer;
}
.addEditIcon {
  width: 23px;
  height: auto;
  max-width: 23px;
}
.addAnswerText {
  font-size: smaller;
  font-weight: 600;
  cursor: pointer;
}
.font-size-10 {
  font-size: 10px;
}
.display-flex-spaceBetween {
  display: flex;
  justify-content: space-between;
}
.display-flex-start {
  display: flex !important;
  justify-content: start !important;
}
.display-flex-item {
  position: absolute;
  display: contents;
}
.addReferenceDocumentsPlusIcon {
  width: 34px;
  height: auto;
  cursor: pointer;
}
.addRefCounterLabel {
  cursor: pointer;
  position: relative;
  top: 2px;
  left: -10px;
}
.cursorPointer {
  cursor: pointer !important;
}
.candidatesBlocks .mainContent .iconList {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 7px;
  min-width: 100px;
}
.candidatesBlocks .mainContent .iconList a img {
  width: 25px;
  height: auto;
}
.cursor-pointer {
  cursor: pointer;
}
.usersNameInfo {
  gap: 0px !important;
}
.display-grid-container {
  gap: 10px;
  display: grid;
  grid-auto-rows: auto;
  grid-template-columns: 1fr 1fr;
  place-items: center;
  margin: auto;
}
.redColor {
  color: $redColor;
}
.d-grid-row-item1 {
  display: "flex";
  align-items: "center";
  gap: "8px";
}

.d-grid-row-item2 {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
}
.shadow-container {
  box-shadow: "0 4px 8px rgba(0, 0, 0, 0.1)";
  border-radius: "8px";
  padding: "16px";
  margin-bottom: "16px";
}
.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 8px;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
}

.basic-hr {
  border: 1px solid #ccc;
  margin: 10px 0;
}

.styled-hr {
  border: 0;
  height: 1px;
  background: linear-gradient(to right, #ccc, #555, #ccc);
  margin: 10px 0;
}

.dotted-hr {
  border-top: 1px dotted #ccc;
  margin: 10px 0;
}

.double-hr {
  border-top: 3px double #555;
  margin: 10px 0;
}

.thick-hr {
  border-top: 5px solid #555;
  margin: 10px 0;
}

.custom-hr {
  border: 2px solid #007bff;
  height: 10px;
  margin: 10px 0;
}
.css-1dimb5e-singleValue {
  color: $modifiedBlack !important;
}

.customSelectBox {
  color: $modifiedBlack !important;
  font-size: 13px;
  * {
    cursor: pointer !important;
  }
  label.floatingLabel {
    top: -8px;
    color: $modifiedBlack !important;
    left: 5px;
    font-size: 12px;
    padding: 0 5px;
  }
}

.check-icons {
  display: flex;
  gap: 10px;
  position: absolute;
  margin-top: -80px;
  left: 91%;
}
.form-group textarea:focus + label,
.form-group textarea:valid + label {
  top: -8px;
  left: 5px;
  font-size: 12px;
  padding: 0 5px;
}
.editableActionButtons {
  display: flex;
  position: relative;
  left: 92%;
  top: -34%;
}
.paginationContainer {
  position: absolute;
  display: block;
  margin: auto;
}
.contentBox .editableActionButton {
  position: absolute;
  right: 10px;
  bottom: 12px;
}
.textMessage {
  .form-group {
    margin-bottom: 0;
  }
  textarea {
    border: 0;
    &:focus {
      border: 0;
      outline: 0;
      box-shadow: none;
    }
  }
}
.commentColumn {
  .messageContainer {
    .roomSpace {
      .messagesPanel {
        margin-top: 0 !important;
      }
    }
  }
}
.customTabsWrapper .tabsWrapper .tabsHeader .nav.nav-tabs {
  min-width: max-content;
  border-bottom: 0;
  padding: 11px;
}
.display-flex-gap-10 {
  display: flex;
  gap: 10px;
}
.font-size-16 {
  font-size: 16px;
}
.docUploadValidation {
  border: 1px solid #da0b0b !important;
  color: #da0b0b !important;
}
.docUploadValidation span {
  color: #da0b0b !important;
}
.profileDetailWrapper .profileDetailBlock .userAvatar img {
  height: 150px;
  max-height: 150px;
  border-radius: 50%;
  max-width: 150px;
  background: rgba(193, 195, 200, 0.5);
}
.validationError {
  select {
    border-color: $redColor;
    color: $redColor;
  }
}
.button-box {
  display: inline-block;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  text-align: center;
  cursor: pointer; /* Added cursor style */
}

.button-box span {
  display: block;
  font-weight: bold;
  margin-bottom: 8px;
}

.level {
  display: flex;
  justify-content: space-between;
}

.input-border {
  border: 1px solid #ccc;
  border-radius: 5px;
}
.passwordShowEye {
  width: 18px !important;
  height: 38px !important;
  position: absolute;
  z-index: 1;
  top: 4%;
  opacity: 0.8;
  left: 92%;
  background: none !important;
}

// .ftcModalPopup.editProfileModalPopup .modal-dialog .modal-content {
//   /* max-width: 600px; */
//   width: 100%;
//   transform: translateY(-50%) translateX(-50%);
//   left: 50%;
// }
.customValidationLabel {
  top: -47px !important;
  left: 4px !important;
  position: relative;
  background: white;
}
.customValidationMsg {
  position: relative;
  margin-top: -13px;
}
.height-300 {
  height: 300px !important;
}
.modal-body.YAHAN_APNI_CUSTOM_CLASS_LAGA_LAIN {
  .css-1nmdiq5-menu {
    max-height: 180px;
    overflow-y: auto;
    * {
      height: 100%;
      overflow: unset;
    }
  }
}
.d-flex-m-left {
  display: flex;
  left: 9px;
}
.trainingTitleHr {
  padding: 0 0 15px;
  border-bottom: 1px solid rgba($greyDarkColor, 0.1);
  margin-bottom: 10px;
}
.d-grid-2-fr {
  display: grid;
  grid-template-columns: 9fr 1fr;
}
.d-grid-2-fr img {
  position: relative;
  top: 10px;
  left: 10px;
  width: 23px;
  opacity: 0.8;
}

.d-grid-17-2-fr {
  display: grid;
  grid-template-columns: 20fr 1fr;
}

.d-grid-17-2-fr img {
  position: relative;
  top: 10px;
  left: 10px;
  width: 23px;
  opacity: 0.8;
}
.ui-ux-color {
  color: $lightGrey;
}
.font-size {
  font-size: 15px;
  position: relative;
  right: 0%;
  top: 8px;
  height: max-content;
}

.d-grid-gap {
  display: flex;
  gap: 11%;
}
.custom-d-grid {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 2fr 2fr 1fr;
  gap: 12px;
}
.pointerEvent-none {
  pointer-events: none;
}
.sleepOver-icon {
  position: relative;
  img {
    width: 15px !important;
    max-width: 15px;
    position: relative;
  }
}

.customOption {
  display: flex;
  gap: 6px;
  padding: 8px;
  position: relative;
  background-color: white;
  color: $modifiedBlack !important;
  transition:
    background-color 0.3s,
    color 0.3s;
  img {
    width: 40px !important;
    height: 40px !important;
    max-width: 40px;
    border-radius: 50% !important;
    overflow: hidden !important;
  }
  span.label {
    font-size: 14px;
    position: relative;
    margin-top: 5px;
  }
}
.optionHover {
  background-color: rgba(39, 141, 236, 0.25);
  cursor: pointer !important;
}
.optionSelected {
  color: white;
  background-color: #278dec;
  cursor: pointer !important;
}
.display-grid-spaceBetween {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

/* Your CSS file or styled-components styles */
.radioButtonContainer {
  position: relative;
}

.radiomark {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.textCenter {
  text-align: center;
}
.d-flex-space-between {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  column-gap: 11px;
  color: $modifiedBlack !important;
}
.d-flex-checkbox-container {
  display: flex;
}
.radioBoxSize {
  width: 20px;
  height: 15px;
}
.radioLabel {
  top: -3px;
  position: relative;
}
.rec-column {
  margin-bottom: 20px;
}
.rec-colum-child {
  background: $white;
  padding: 12px 0px 11px 1px;
  width: 100%;
}
.applicationProcessWrapper .applicantBlock {
  // background: #ffffff;
  // box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
  // border-radius: 5px;
  // width: 191px;
  // margin: 15px auto 0;
  // display: block;
  // padding: 13px;
}
.recrument-inner {
  // padding: 15px;
  // left: 7px;
  // position: relative;
}
.darkBlack {
  color: $textHeadingDarkBlackColor !important;
}
.d-flex-gap-43 {
  display: flex;
  gap: 41px;
  color: $modifiedBlack !important;
}

.messageContainer .roomSpace .messagesActions footer .textMessage input {
  width: 100%;
  padding: 5px;
  border: 0;
  &:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
}
.messageContainer .mobileNameSpace {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
  padding: 7px 2px;
  width: 100%;
}

.unAssignedShiftaDay {
  background: $redLightColor;
  .checkList {
    .labelText {
      display: none;
    }
    .checkmark {
      left: 50%;
      transform: translateX(-8px);
      border-color: $lightGrey;
    }
  }
}

// .textStart {
//   text-align: start !important;
// }

.publishRosterButtons {
  display: flex;
  justify-content: end;
  position: relative;
  top: 10px;
  gap: 10px;
}

.rosterTable {
  .maxWidthTable {
    div[role="rowgroup"] {
      div[role="row"] {
        div[role="cell"] {
          &:nth-child(5) {
            padding-left: 0;
          }
        }
      }
    }
  }
}

.d-flex-space-around {
  display: flex !important;
  justify-content: space-around;
  gap: 8px;
}

.fs-7 {
  font-size: 7px;
}
.fs-13 {
  font-size: 13px;
}
.fs-12 {
  font-size: 12px !important;
}
.d-flex-align-start {
  display: flex;
  align-items: start;
}
.bg-transparent {
  background: transparent;
}
.scheduleTableGap {
  gap: 2px !important;
  display: grid !important;
  position: relative !important;
  margin: 5px !important;
}
.disabled {
  background: rgba(0, 0, 0, 0.1);
}
.left-align {
  position: relative;
  text-align: left;
}
.padding-10 {
  padding: 7px 6px 10px 12px !important;
}
.padding-7 {
  padding: 4px 0px 6px 10px !important;
}
.scheduleTableTh1 {
  position: relative;
  left: rig;
  right: 22px;
}
.messageContainer .mobileNameSpace .userBox .usersNameContainer {
  img {
    position: relative;
    width: 37px;
    height: 37px;
    border-radius: 50%;
  }
}

.messageContainer .roomSpace .messagesPanel .recieverMessageBoxContainer {
  background-color: #ebeded;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  left: 58% !important;
  padding: 10px 10px;
  color: black;
  max-width: 40%;
  position: relative;
}
// .messageContainer .roomSpace .messagesPanel .senderMessage .textMessageContainer {
//   background: #278dec;
//   border-radius: 10px 10px 10px 0;
//   color: $white;
//   gap: 0 !important;
//   display: flex;
//   flex-direction: row;
//   justify-content: flex-end;
//   align-items: center;
//   flex-wrap: nowrap;
//   right: -1% !important;
//   padding: 10px 10px;
//   max-width: 60%;
//   position: relative;
// }
.msgFooterIcons {
  display: flex;
  background: #f2f2f2;
  gap: 9px;
  right: 8px;
  top: 3px;
  height: 40px;
  position: relative;
  border-radius: 0px 10px 10px 0px;
}
.messageContainer .roomSpace .messagesActions footer {
  padding: 6px !important;
}
.msgContainer-DisplayBlock {
  display: block;
  width: 100%;
}
// .msgContainer-DisplayBlock
//   .mobileNameSpaceContainer
//   .userBox
//   .usersNameInfo
//   .userImageContainer {
//   width: 12% !important;
// }

.adminTextMsgContainer {
  border-radius: 10px 10px 0px 10px !important;
}
.sendMessagingLoader {
  position: relative;
  left: 112px;
  top: 50px;
}

.emojisContainer {
  height: 352px !important;
  width: 266px !important;
  right: 178px !important;
  bottom: 365px !important;
}

.textFromChatBox {
  position: relative;
  right: 184px;
}
.sendTextMessageChatBox {
  position: relative;
  left: 24px !important;
}
.r-0 {
  right: 0px !important;
}
.messageTime {
  position: absolute;
  top: 4px !important;
  color: #777d80;
  display: flex;
  right: -8px !important;
  justify-content: end;
}
.sendMessageTextArea {
  width: 100%;
  padding: 0px !important;
  border: 0 !important;
  border-radius: 0 !important;
  font-size: 13px;
}
.messageContainer .roomSpace .messagesActions footer {
  padding: 6px !important;
  height: 46px;
}
.sendMsgContainer {
  display: block;
}
.objectFitAndBorder {
  object-fit: contain;
  width: 100%;
  height: 100%;
  padding: 10px 10px;
  // border: 1px solid #ccc;
  border-radius: 5px;
  color: $modifiedBlack;
  font-size: 13px;
  border: 0;
  &:focus {
    border: 0;
    outline: 0;
    box-shadow: none;
  }
}
.textAreaContainer {
  height: 100%;
  width: 100%;
  display: flex;
}
// .dataTableWrapper {
//   .maxWidthTable {
//     div[role="rowgroup"] {
//       div[role="row"] {
//         div[role="cell"] {
//           &:nth-child(6) {
//             padding-left: 0 !important;
//           }
//         }
//       }
//     }
//   }
// }
.p-13 {
  padding: 13px !important;
}
.individualStaffSchedule table tr td,
.individualStaffSchedule table tr th {
  width: 12%;
  vertical-align: middle;
  text-align: center;
}
.rosterTableContent.p-0 table thead tr th {
  background: $white;
  padding: 0px !important;
}
.bg-none {
  background: #278dec !important;
}
// .subTabsWrapper .radioTabsHeader .tabsBlocks {
//   width: 0% !important;
// }
// .rotaContentCell:nth-child(1) {
//   margin-top: 14px;
// }
.dropdown-toggle-custom,
.dropdown-toggle-custom::after,
.dropdown-toggle-custom:hover {
  background: none !important;
  border: none !important;
}

.dropdown-toggle-custom:active {
  background: transparent !important;
}

.icon-custom {
  width: 24px;
  height: 24px;
}

.dropdownPosition {
  display: flex;
  right: 250px;
  top: 42px;
  position: absolute;
}

.background-active {
  background: $blueLightColor !important;
  color: $lightBlueColor !important;
  border-color: $blueLightColor !important;
  width: max-content !important;
}

// .background-success {
//   background: $secondaryLightestColor;
//   color: $secondaryDarkColor;
//   width: max-content !important;
//   border-color: $secondaryLightestColor;
// }
// .background-warning {
//   background: $QuaternaryLightColor;
//   color: $QuaternaryColor;
//   width: max-content !important;
//   border-color: $QuaternaryLightColor;
// }
// .background-danger {
//   background: $redLightColor;
//   color: $redColor !important;
//   width: max-content !important;
//   border-color: $redLightColor;
// }
.dataTableWrapper .maxWidthTable div[role="rowgroup"] div[role="row"] div[role="cell"] {
  &:nth-child(5) {
    display: grid;
    // place-items: center;
  }
}

.d-flex-row {
  display: flex;
  justify-content: center;
  gap: 14px;
}
.p-relative-deleteIcon {
  position: relative !important;
  top: 6px !important;
}
.fs-patientName {
  position: relative;
  bottom: 0px;
  font-size: 9px !important;
  color: $modifiedBlack !important;
}
.publishScheduleText {
  color: $modifiedBlack;
  font-size: 16px;
}
.futureTempInput label {
  color: $modifiedBlack;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0.01em;
  text-align: left;
}

.rosterTableContent table tr .usersNameInfo {
  text-align: left;
  justify-content: flex-start;
  cursor: pointer;
}

.actionRows span img {
  width: 22px;
  height: 22px;
}
.actionRows {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: nowrap;
  gap: 0;
}

.d-flex-start {
  display: flex;
  justify-content: flex-start;
  color: $textHeadingDarkBlackColor !important;
}
.rotaContentCell {
  padding: 4px 10px !important;
  position: relative !important;
  min-width: 100px !important;
}

.d-flex-space-betweens {
  display: flex;
  justify-content: space-between;
}

.top- {
  top: 5px;
  position: relative;
}
// .messageContainer .roomSpace .messagesActions {
//   background: #f6f6f6;
//   bottom: 60px;
//   height: 0 !important;
//   position: relative;
// }

// .textMessageTextarea textarea {
//   padding: 7px 10px !important;
//   top: 6px;
//   height: 43px;
//   position: relative;
//   background: white;
// }
.disableButton {
  border-color: #b1b7ba !important;
  background: #b1b7ba !important;
}
.cursor-default {
  cursor: default !important;
}
.groupInfoImage {
  width: 158px;
  height: auto;
}
.d-flex-space-between- {
  display: flex;
  justify-content: space-between;
}
.mt-minus {
  margin-top: -15px !important;
}
.min-height {
  height: 600px !important;
}
// .textMessage textarea {
//   object-fit: contain;
//   width: 100%;
//   height: 40px;
//   border-radius: 10px 10px 10px 10px;
// }

// .messageContainer .roomSpace .messagesActions {
//   background: #e8e9e9;
//   display: flex;
//   justify-content: space-between;
//   padding: 0 !important;
// }
.object-fit-contain {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.sendMsgContainerSpan {
  width: 100%;
  bottom: 3px;
  left: 5px;
  position: relative;
}
.sendMessageEmojiChildIcon {
  top: 7px;
  height: max-content;
  position: relative;
}
.sendMessageEmojiChildSubmitIcon {
  top: 4px;
  right: 7px;
  height: max-content;
  position: relative;
}
.textMessageContainerClass {
  word-break: break-word;
  width: 50%;
}
.d-flex-wrap {
  display: flex !important;
  flex-wrap: wrap !important;
}
.msgFooterIconsChild {
  background: white !important;
}
.editGroupMembersContainer {
  .userInfo {
    font-size: 10px;
  }
  .userImage img {
    width: 36px !important;
    height: 34px !important;
  }
  .crossImageContainer {
    display: block;
    cursor: pointer;
    bottom: 10px;
    position: relative;
    left: 13px;
    .cross {
      color: red;
      font-size: 13px;
    }
  }
}
.fs-10 {
  font-size: 10px;
}
.forwardMessageModelContainer {
  position: relative;
  .childContainer {
    display: grid;
    grid-template-columns: 5fr 1fr;
    .avatarContainer {
      margin: 3px;
      .userImage img {
        width: 32px !important;
        height: 32px !important;
        margin-right: 4px;
      }
    }
    .sendButtonContainer {
      display: block;
      position: relative;
      width: max-content;
      .sendButton {
        display: block;
        position: relative;
        margin: 12px;
      }
    }
  }
}
.ml-6 {
  margin-left: 6px !important;
}
.groupMembersListingContainer {
  display: flex;
  flex-wrap: wrap;
  padding: 9px;
  .listingUserRecordsContainer .userInfo {
    gap: 7px;
  }
  .userImage img {
    width: 32px !important;
    height: 32px !important;
    margin-right: 4px !important;
  }
}
.allUsersListingContainer {
  left: 7px;
  position: relative;
  .childContainer {
    .avatarContainer {
      margin: 3px;
      display: flex;
      position: relative;
      place-items: center;
      gap: 2px;
      .userImage img {
        width: 32px !important;
        height: 32px !important;
        margin-right: 4px;
      }
      .userInfo .nameBlock {
        color: $modifiedBlack;
      }
    }
    .sendButtonContainer {
      display: block;
      position: relative;
      width: max-content;
      .sendButton {
        display: block;
        position: relative;
        margin: 12px;
      }
    }
  }
}
.activeMessageRow {
  cursor: pointer !important;
  background: rgba(99, 180, 255, 0.2) !important;
}
.nameBlockContainer {
  position: relative;
  top: 4px;
  font-size: small;
  color: $textHeadingDarkBlackColor !important;
  .paragraphBlock {
    display: flex;
    position: relative;
    bottom: 4px;
    font-size: 13 !important;
    color: $modifiedBlack !important;
  }
}
.chatHeaderNameBlock {
  position: relative;
  top: 0px;
  font-size: small;
  color: $textHeadingDarkBlackColor !important;
}
.chatMessageTexts {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  top: 0px;
  font-size: small;
  color: $textHeadingDarkBlackColor !important;
}
.uploadGroupImageIcon img {
  width: 140px !important;
  height: 140px !important;
}
.editMessageCrossContainer {
  cursor: pointer;
  width: 40px;
  position: absolute;
  top: 5px;
  left: 88%;
  display: grid;
}
.messagePanelContainer {
  font-size: 12px !important;
  padding: 0px 15px !important;
  max-height: calc(100vh - 330px) !important;
}

.msgSeenUnseenContainer {
  display: flex;
  gap: 10px;
  top: 4px;
  left: -10px !important;
  text-align: center;
  position: relative;
  justify-content: flex-end;
  .child {
    display: grid;
    place-items: center;
    img {
      width: 15px;
      max-width: 15px;
    }
  }
}
.color-black {
  color: black !important;
}
.groupMessageSeenByContainer {
}
.groupMessageSeenUsersProfilesElementsContainer {
  display: flex;
  flex-direction: column;
  background-color: transparent;
  position: relative;
  .imageChainBlock {
    display: flex;
    position: relative;
    width: max-content;
    left: 11px;
    padding: 3px;
    .chainImage.firstView img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #d8d9db;
      border: 2px solid #ffffff;
    }
    .chainImage img {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #d8d9db;
      border: 2px solid #ffffff;
    }
  }
}
.groupMessageSeenUsers {
  .imageChainBlock {
    display: flex;
    position: relative;
    width: max-content;
    left: 11px;
    padding: 3px;
    .chainImage img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: #d8d9db;
      border: 2px solid #ffffff;
    }
  }
}
.profileCounts {
  top: -1px;
  right: 6px;
  font-size: smaller;
  position: relative;
}
.border-bottom {
  border-bottom: 1px solid rgba(193, 195, 200, 0.5);
  padding-bottom: 5px;
}
.profileWidth {
  margin: 1px !important;
  width: max-content !important;
  left: 4px !important;
  position: relative !important;
}
.d-flex-space-betweenn {
  display: flex;
  /* width: max-content; */
  justify-content: space-between;
}
.textAlign {
  display: flex;
  place-items: center;
  color: $modifiedBlack;
}

.chainBlocks- {
  display: flex;
  /* width: max-content; */
  justify-content: flex-end;
}
.d-flex-container {
  display: flex;
  justify-content: flex-end;
  place-items: center;
  bottom: 6px;
  position: relative;
}
// .popupBody {
//   width: 220px;
//   height: auto;
// }
.f-s13 {
  font-size: 13px !important;
}
.color-black {
  color: $modifiedBlack;
}
.rotaContentCell span.plusIcon img {
  width: 14px;
  height: 14px;
}
.rotaContentCell span.plusIcon {
  left: 66px;
  gap: 2px;
  position: absolute;
  display: flex;
  justify-content: center;
}
.rotaImageWidth {
  width: 14px;
  height: 14px;
  max-width: 14px;
}
.dropDownRota p {
  font-size: 13px !important;
  padding: 0 !important;
}
.d-flex-end {
  display: flex !important;
  justify-content: flex-end !important;
}
.popOver {
  width: 22% !important;
  min-width: 22% !important;
}
.rotaTableStaffPatientViewContainer .thChildContainer {
  display: flex;
  place-items: center;
  img {
    width: 24px !important;
    height: 24px !important;
  }
}
.position-left {
  right: 9px !important;
  position: relative !important;
}
.bg-- {
  background-color: #f6f6f6 !important;
}
.c-red {
  color: red !important;
}
.fs-10 {
  font-size: 10px !important;
}
.fs-smaller {
  font-size: small !important;
}
.gap-1 {
  display: flex;
  justify-content: space-between;
}
.actionRow {
  // display: flex;
  // flex-direction: row;
  // justify-content: flex-end;
  // align-items: center;
  // flex-wrap: nowrap;
  // gap: 3px;
  // position: absolute;
  // top: 0px;
  // right: 9px;
}
.actionRow span img {
  width: 19px !important;
  height: 19px !important;
}
.mb-bottom-top {
  margin-bottom: 15px !important;
}
.scheduleTableGap .contentcell {
  border-radius: 5px !important;
  padding: 2px 1px !important;
  margin: 5px 0 !important;
  background: #ffffff;
  box-shadow: 0 0 3px rgba(193, 195, 200, 0.5);
}
.selectWeekWidth {
  min-width: 185px !important;
  div {
    font-size: 12px !important;
    color: black !important;
  }
}
.selectWeekWidthRota {
  min-width: 125px !important;
  div {
    font-size: 12px !important;
    color: black !important;
  }
}
.lightBlue {
  background-color: $lightBlueColor;
}
.lightYellow {
  background-color: #ffb000;
}
.time.darkBlack {
  display: flex;
}
.color-white {
  color: white;
}
.blur {
  filter: blur(1px);
}
.onOffSwitchContainer {
  display: flex;
  justify-content: space-between;
  gap: 9px;
  label {
    font-size: small;
    font-weight: 500;
  }
}
.onOffSwitchContainer1 {
  display: flex;
  justify-content: space-between;
  gap: 9px;
  label {
    font-size: small;
    font-weight: 500;
  }
}
.selectBox {
  select {
    font-size: small !important;
  }
}
.bg-white {
  background-color: white !important;
}
.roster-calendar-container {
  display: flex;
  align-items: center;
  .calenderImgContainer {
    position: relative;
    right: 25px;
  }
  input {
    font-size: 13px !important;
  }
}
.roster-calendar-day {
  font-size: 16px;
  color: #555;
}
.react-datepicker {
  left: 70px;
}
.gridTemplateColumns {
  display: flex;
  justify-content: space-between;
}
