.sideNavWrapper {
  .logoBox {
    background: $white;
    border-right: 1px solid $primaryColor;
    min-height: 64px;
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      display: block;
      margin: 0 auto;
      max-height: 55px;
    }
  }
  ul {
    padding: 20px;
    background: $primaryColor;
    color: $white;
    * {
      font-size: 14px;
    }
  }
}
.MuiDrawer-paper {
  background: $primaryColor !important;
  border: 0;
}
.myWrappers {
  .MuiPaper-root {
    background: $primaryColor;
    border: 0;
  }

  .sideNavUl {
    background: transparent;
    color: $white;

    .MuiListItemButton-root {
      border-radius: 5px;
      margin: 15px 0;
      padding: 5px 20px;
      &:hover {
        background: $primaryLightColor;
        // color: $primaryDarkColor;
      }
    }
    .Mui-selected {
      background: $primaryDarkColor;
      &:hover {
        background: $primaryDarkColor;
        color: $white;
      }
    }
    span {
      font-size: 14px;
    }
  }
}
