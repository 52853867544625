.table > :not(caption) > * > * {
  color: $greyDarkColor;
}
.dataTableWrapper {
  .rdt_TableHeader {
    width: 40%;
    float: right;
    min-height: auto;
    padding: 5px 10px 20px;
  }
  &.exportDataTable {
    .rdt_TableHeader {
      overflow: unset;
      .sc-eDPEul {
        display: none !important;
      }
      .hGcwUo {
        display: none;
      }
    }
  }
  header {
    float: left;
    width: 50%;
    padding: 10px 10px 20px;
    min-height: auto;
  }
  .minWidthTable {
    width: 100%;
    overflow-x: auto;
    div[role="table"] {
      min-width: 1000px;
    }
  }

  .maxWidthTable {
    min-height: 100px;
    .rdt_TableHeadRow {
      min-height: auto;
      .rdt_TableCol {
        border: 1px solid rgba($greyDarkColor, 0.1);
        border-bottom: 0;
        border-left: 0;
        font-size: 14px;
        height: auto;
        padding: 15px 10px;
        &:nth-child(1) {
          border-left: 1px solid rgba($greyDarkColor, 0.1) !important;
        }
      }
    }
    div[role="rowgroup"] {
      cursor: pointer;
      div[role="row"] {
        border: 0;
        &:nth-child(even) {
          background: $greyLightestColor;
        }
        div[role="cell"] {
          border: 1px solid rgba($greyDarkColor, 0.1);
          border-bottom: 0;
          border-left: 0;
          padding: 0;
          padding-left: 10px;
          padding-right: 10px;
        }
        .rdt_TableCell {
          &:nth-child(1) {
            border-left: 1px solid rgba($greyDarkColor, 0.1) !important;
          }
        }
      }
    }
  }
  @include respond-to(medium) {
    header,
    .rdt_TableHeader {
      width: 100%;
      float: none;
      display: block;
    }
    header {
      padding: 10px 0;
      .searchContainer {
        .searchTextBox {
          input {
            min-width: 100%;
          }
        }
      }
    }
    .rdt_TableHeader {
      padding: 10px 0 0;
      div {
        justify-content: flex-start;
        button {
          margin: 0 5px 0 0;
        }
      }
    }
  }
}
.limitedCarePlansNotes {
  .minWidthTable {
    div[role="table"] {
      min-width: 800px;
    }
  }
}
.sicknessScore {
  .dataTableWrapper {
    header {
      width: 100%;
      float: none;
      padding: 0;
      border-radius: 5px 5px 0 0;
      .searchContainer {
        padding: 10px;
      }
      .parentTableHeader {
        border-top: 10px solid #f6f6f6;
        width: 100%;
        padding: 0 10px;
        @include flexbox(row, space-between, center);

        .thresholdScore {
          border-left: 1px solid rgba($greyDarkColor, 0.1);
          padding: 10px;
          p {
            text-align: center;
            margin: 0;
            span {
              display: block;
              color: $primaryDarkColor;
            }
          }
        }
      }
    }
    .maxWidthTable {
      padding: 0 10px 10px;
      div[role="rowgroup"] {
        cursor: unset;
        .rdt_TableRow {
          .rdt_TableCell {
            border-top: 1px solid rgba($greyDarkColor, 0.1);
            border-right: 1px solid rgba($greyDarkColor, 0.1);
          }
        }
      }
    }
  }
  @include respond-to(smedium) {
    .dataTableWrapper {
      header {
        .parentTableHeader {
          display: block;
          padding: 10px;
          h3 {
            padding: 10px 10px 0;
          }
          .thresholdScore {
            border: 0;
            p {
              text-align: left;
            }
          }
        }
      }
    }
  }
}
.parentTableHeader {
  h3 {
    font-size: 18px;
    margin: 0;
  }
}
.documentdDetail {
  .maxWidthTable {
    min-height: 100px;
    .rdt_TableHeadRow {
      min-height: auto;
      .rdt_TableCol {
        border: 0 !important;
        &:nth-child(1) {
          border-left: 0 !important;
        }
      }
    }
  }
}

.rowClickUnset {
  .dataTableWrapper {
    .maxWidthTable {
      div[role="rowgroup"] {
        cursor: unset;
      }
    }
  }
}
.columnHeading {
  small {
    font-size: 11px;
  }
}
.candidatesBlocks {
  .mainContent {
    padding: 15px !important;
    .rdt_TableHead {
      display: none;
    }
    h4 {
      font-size: 16px;
    }
    .searchContainer {
      padding: 5px 0 15px;
      border-bottom: 1px solid rgba($greyDarkColor, 0.1);
      margin-bottom: 0;
    }
    .dataTableWrapper {
      .maxWidthTable {
        div[role="rowgroup"] {
          div[role="row"] {
            cursor: default;
            padding: 10px 0 0;
            &:nth-child(even) {
              background: inherit;
            }
            .rdt_TableCell {
              border: 0;
              padding: 0;
              &:nth-child(1) {
                border-left: 0 !important;
              }
            }
          }
        }
      }
    }

    .usersNameInfo {
      img {
        width: 35px;
        height: 35px;
      }
      .userInfo {
        .nameBlock {
          font-weight: bold;
          font-size: 15px;
          margin-bottom: 5px;
        }
        .infoBlock {
          font-weight: normal;
        }
      }
    }
    .iconList {
      @include flexbox(row, flex-end, center);
      gap: 5px;
      min-width: 130px;
      a {
        img {
          width: 30px;
          height: 30px;
        }
      }
    }
  }
  @include respond-to(medium) {
    margin-bottom: 15px;
  }
}

.tableTitle {
  @include flexbox(row, space-between, center);
  h3 {
    font-size: 18px;
    margin-top: 10px;
  }
}

.mainContent.messagingSystem {
  .rdt_TableHead {
    display: none;
  }
}

.staffOverview {
  .usersNameInfo {
    .userInfo {
      .nameBlock {
        font-weight: bold;
        font-size: 14px;
      }
      .infoBlock {
        font-weight: normal;
      }
    }
  }
  .messagingSystem {
    h4 {
      border-bottom: 1px solid rgba($greyDarkColor, 0.1);
    }
    .dataTableWrapper {
      .maxWidthTable {
        div[role="rowgroup"] {
          div[role="row"] {
            cursor: default;
            padding: 10px 0 0;
            &:nth-child(even) {
              background: inherit;
            }
            .rdt_TableCell {
              border: 0;
              padding: 0;
              &:nth-child(1) {
                border-left: 0 !important;
              }
            }
          }
        }
      }
    }
    .usersNameInfo {
      align-items: flex-start;
      .userImage {
        flex: 0 0 12%;
        min-width: 34px;
        img {
          width: 35px;
          height: 35px;
        }
      }
      .userInfo {
        flex: 0 0 88%;
        padding-left: 10px;
        .infoBlock {
          word-wrap: break-word;
          font-size: 13px;
        }
      }
    }
  }
  .Roster {
    ::-webkit-scrollbar {
      width: 3px;
    }
    ::-webkit-scrollbar:horizontal {
      height: 3px;
    }
    .contentcell {
      max-width: 120px;
      margin-right: 5px;
    }
    .shiftCell {
      @include flexbox(row, space-between, center, nowrap);
      gap: 5px;
      &.markLeave {
        cursor: pointer;
      }
    }
    .rdt_TableHead {
      .rdt_TableHeadRow {
        .rdt_TableCol * {
          margin: 0 auto;
        }
      }
    }
    div[role="rowgroup"] {
      div[role="row"] {
        div[role="cell"] {
          padding: 5px 5px;
          .shiftTimingDetail {
            height: 85px;
            overflow-y: auto;
          }
          > div {
            width: 100%;
          }
        }
      }
    }
    @include respond-to(xllarge) {
      .contentcell {
      }
    }
  }
}
.eventAppointments {
  .fc {
    .fc-scroller-harness {
      overflow: visible;
      .fc-scroller-liquid-absolute {
        overflow: visible !important;
      }
    }
  }
}

.riskAssessments {
  .dataTableWrapper {
    .maxWidthTable {
      .rdt_TableHeadRow {
        .rdt_TableCol {
          &:nth-child(4),
          &:nth-child(6),
          &:nth-child(7) {
            padding: 0;
          }
        }
      }
      div[role="rowgroup"] {
        div[role="row"] {
          div[role="cell"] {
            &:nth-child(4),
            &:nth-child(6),
            &:nth-child(7) {
              padding: 0;
            }
          }
        }
      }
    }

    .multipleColumnRowsTable {
      width: 100%;
      tr {
        td {
          width: 33.3%;
          text-align: center;
          padding: 3px;
        }
        &:nth-child(1) {
          border-bottom: 1px solid rgba($greyDarkColor, 0.1);
        }
        &:nth-child(2) {
          td {
            border-right: 1px solid rgba($greyDarkColor, 0.1);
            &:last-child {
              border: 0;
            }
          }
        }
      }
    }
    .cellColumnRowsTable {
      width: 100%;
      height: 100%;
      tr {
        td {
          width: 33.3%;
          text-align: center;
          padding: 3px;
          border-right: 1px solid rgba($greyDarkColor, 0.1);
          &:last-child {
            border: 0;
          }
          &.low {
            border-left: 4px solid $green;
          }
          &.medium {
            border-left: 4px solid $QuaternaryColor;
          }
          &.high {
            border-left: 4px solid $redColor;
          }
        }
      }
    }
  }
}
.questions {
  table {
    min-width: 500px;
    tr {
      th,
      td {
        &:nth-child(1) {
          width: 90%;
        }
        &:nth-child(2) {
          width: 10%;
          text-align: center;
        }
      }
    }
  }
}
.forOfficeUseOnly {
  table {
    tr {
      td,
      th {
        width: 33.3%;
        text-align: left;
        .form-group {
          margin: 20px 0 10px;
        }
      }
    }
  }
}
.qualitySurveyUpdate {
  .questions {
    table {
      min-width: 600px;
      tr {
        th,
        td {
          &:nth-child(1) {
            width: 85%;
          }
          &:nth-child(2) {
            width: 15%;
            text-align: center;
          }
          .feedbackTable {
            table {
              min-width: unset;
              width: 100%;
              tr {
                td {
                  width: 25%;
                  .feedBackIcons {
                    width: 20px;
                    height: 20px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
.exportDataList {
  span {
    display: block;
    padding: 5px 10px;
    @include bottomBorder;
    &:hover,
    &:focus,
    &:active {
      background: rgba($greyDarkColor, 0.05);
      color: $greyDarkColor;
    }
    span {
      padding: 0;
      color: $greyDarkColor;
      cursor: pointer;
      border-bottom: 0;
      &:hover,
      &:focus,
      &:active {
        background: transparent;
      }
    }
    a {
      padding: 0 !important;
      color: $greyDarkColor;
      background: transparent;
      &:hover,
      &:focus,
      &:active {
        background: transparent !important;
        color: $greyDarkColor;
      }
    }
  }
}
.rdt_TableHeader {
  div {
    background: transparent !important;
  }
}
.dailyDiaryContent {
  table {
    width: 100%;
  }
  .dailyDiaryContentTable {
    tr {
      th,
      td {
        width: 25%;
        vertical-align: center;
        .form-group {
          margin: 10px 0 5px;
          input {
            padding: 5px 10px;
          }
        }
        .notesContent {
          // max-height: 300px;
          // overflow-y: auto;
        }
      }
    }
    .moodRatingTable {
      width: 100%;
      margin: -1px;
      tr {
        th,
        td {
          width: auto;
          text-align: center;
          border: 1px solid rgba($black, 0.1);
          padding: 5px;
        }
      }
      &.nightChecks {
        tr {
          th {
            &:nth-child(1) {
              text-align: left;
            }
          }
        }
      }
    }
  }
  .feedBackIcons {
    width: 30px;
    height: 30px;
    background: $white;
    border-radius: 50%;
    margin: 10px auto;
    padding: 0;
    img {
      width: 100%;
    }
    &.Inadequate,
    &.Angry,
    &.Worried {
      background: #e81224;
    }
    &.RequiresImprovement,
    &.Excited,
    &.Scared {
      background: #f7630c;
    }
    &.Good,
    &.Happy,
    &.Proud,
    &.Hopeful,
    &.Confident {
      background: #fff100;
    }
    &.Outstanding,
    &.Nervous {
      background: #16c60c;
    }
    &.Bored,
    &.Sad,
    &.Relaxed,
    &.Calm,
    &.Tired {
      background: #55bbd1;
    }
  }
}
.theading {
  td,
  th {
    background: rgba($black, 0.3);
  }
}
.myContactsViewCarePlan {
  margin: 30px 0 0;
  tr {
    td,
    th {
      vertical-align: middle;
      &:nth-child(1) {
        text-align: center;
      }
      p {
        margin: 10px 0;
        strong {
          display: block;
        }
      }
    }
  }
}
.rosterTableContent {
  .rotaRosterDragDrop {
    tr {
      td {
        .droppableArea {
          min-height: 25px;
        }
        div {
          &:focus,
          &:active {
            .actionItem {
              opacity: 0;
            }
          }
        }
        .contentcell {
          margin-bottom: 0;
          padding-bottom: 15px;
        }
        .shiftActionButtons {
          margin-bottom: 0;
          position: relative;
          top: -10px;
          .viewLogs {
            cursor: pointer;
          }
        }
      }
    }
  }
}
.previousDate {
  margin-bottom: 15px !important;
}
.payrolDataTableWrapper {
  .rdt_TableHeader {
    .hcAczL {
      font-size: 12px !important;
    }
  }
  div[role="rowgroup"] {
    div[role="row"] {
      div[role="cell"]:nth-child(2) {
        > div {
          width: 100%;
        }
      }
    }
  }
  .infoBlock {
    margin-left: auto;
    img {
      width: 18px;
      height: 18px;
      background: $white;
    }
  }
  .maxWidthTable {
    .rdt_TableHeadRow {
      .rdt_TableCol:nth-child(1) {
        border-top: 1px solid rgba(51, 51, 51, 0.1) !important;
        padding: 10px;
      }
    }
    div[role="rowgroup"] {
      div[role="row"] {
        .rdt_TableCell:nth-child(1) {
          border-top: 1px solid rgba(51, 51, 51, 0.1) !important;
          border-right: 1px solid rgba(51, 51, 51, 0.1) !important;
          padding: 10px;
        }
      }
    }
  }
}

.finalPayrolDataTableWrapper {
  div[role="rowgroup"] {
    div[role="row"] {
      div[role="cell"]:nth-child(1) {
        > div {
          width: 100%;
        }
      }
    }
  }
  .infoBlock {
    margin-left: auto;
    img {
      width: 18px;
      height: 18px;
      background: $white;
    }
  }
}
.exportPdfPayroll {
  .infoBlock,
  .userImage {
    display: none;
  }
}
span.contractedHour {
  display: block;
  text-align: center;
}
.exportPdfContainer {
  height: 0;
  overflow: hidden;
}
.borderBottomTable > div,
.borderBottomTable > .gmlwXk {
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
